import React, { Component } from 'react'
import styled from 'styled-components'
import QueueAnim from 'rc-queue-anim'
import BenchmarkTopBanner from './BenchmarkTopBanner'
import BenchmarkContainer from './BenchmarkContainer'
import FilterBar from '../Filters/FilterBar'
import { scrollTo } from '../../utils'
import { Block } from '../Results/ResultsDefinition'
import { BionicToggleProps } from '../Bionic/BionicToggleRow'
import BenchmarksBionicWheel from './BenchmarksBionicWheel'
import { ResultsWithFilters } from '../../pages/ResultsPage'

const StyledContainer = styled.div`
    -webkit-overflow-scrolling: touch;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    width: 100%;
    overflow-x: hidden;
    padding: 15px;

    .result-rows {
        max-width: 520px;
        margin: 0 auto;
        padding: 15px;
    }

    @media only screen and (min-width: 1024px) {
        .result-rows {
            max-width: 800px;
        }
    }
`

type Props = {
    results: ResultsWithFilters,
    applyFilters: (filters: any) => void,
    resetFilters: (filters: any) => void,
    currentIndex: number,
} & BionicToggleProps

type State = {
    currentBlock: Partial<Block>,
    fade: string,
    fadeTicker: boolean,
    show: boolean,
    showCount: number,
    results?: ResultsWithFilters,
}

class BenchmarksContainer extends Component<Props, State> {
    state: State = {
        show: true,
        showCount: 0,
        currentBlock: {},
        fade: 'start',
        fadeTicker: false,
    }

    componentWillReceiveProps(newProps: Props) {
        // we use showCount to trigger animations when the user first sees container
        const { showCount } = this.state
        scrollTo(1, 0)
        const { currentIndex } = newProps
        this.setState({ currentBlock: {}, fade: 'none' })
        if (showCount < 1 && currentIndex == 1) {
            this.setState({ show: false }, () => {
                this.setState({ show: true, showCount: 1, results: newProps.results })
            })
        }
    }

    toggleOpen = (block: Block) => {
        // fadeTicker helps to keep track if single  block is being toggle repeatedly
        const { currentBlock, fadeTicker } = this.state
        if (block.blockId === currentBlock.blockId) {
            if (fadeTicker) {
                this.setState({ currentBlock: block, fade: 'all', fadeTicker: false })
            } else {
                this.setState({ currentBlock: block, fade: 'none', fadeTicker: true })
            }
        } else {
            this.setState({ currentBlock: block, fade: 'all', fadeTicker: false })
        }
    }

    render() {
        const { results, applyFilters, resetFilters, bionic, setBionic } = this.props
        const { currentBlock, fade, show } = this.state
        return (
            <StyledContainer>
                {show && (
                    <>
                        <div>
                            <div style={{ margin: '50px auto 0' }}>
                                <BenchmarkTopBanner results={results} bionic={bionic} />
                            </div>
                        </div>
                        <FilterBar
                            results={results}
                            applyFilters={applyFilters}
                            resetFilters={resetFilters}
                            bionic={bionic}
                            setBionic={setBionic}
                        />
                        {bionic && <BenchmarksBionicWheel results={results} />}
                        {!bionic && (
                            <div className="result-rows">
                                <QueueAnim type="scale" key="anim" interval={50}>
                                    {results.blocks.map((b: Block) => (
                                        <BenchmarkContainer
                                            key={b.blockId}
                                            block={b}
                                            currentBlock={currentBlock}
                                            toggleOpen={this.toggleOpen}
                                            fade={fade}
                                        />
                                    ))}
                                </QueueAnim>
                            </div>
                        )}
                    </>
                )}
            </StyledContainer>
        )
    }
}

export default BenchmarksContainer
