import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import BBLOCK from '../../assets/onboarding/benchmark_block.png'
import YouTube from './YouTube'
import { CopyConsumer } from '../../contexts/Copy'

const StyledContainer = styled.div`
    .intro-text {
        font-size: 33px;
        color: ${props => props.theme.white};
        min-height: 400px;
        margin: 0 auto;
    }

    .card {
        // background: ${props => props.theme.grayToGray};
        color: ${props => props.theme.white};
        min-height: 350px;

        .inner-content {
            margin-top: 15px;
            padding: 15px;
            border-radius: 3px;
            background: ${props => props.theme.grayToGray};
        }
        .number {
            font-size: 65px;
            font-weight: bold;
            opacity: 1;
        }

        .text {
            opacity: 1;
            font-size: 20px;
        }

        @media only screen and (max-width: 321px) {
            .text {
                font-size: 14px;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .intro-text {
            font-size: 40px;
            color: ${props => props.theme.white};
            min-height: 400px;
            text-align: center;
            max-width: 600px;
        }

        .card {
            min-height: 400px;
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .intro-text {
            margin-top: 100px;
            min-height: 300px;
        }
        .card {
            margin-top: 75px;
        }
     }
`

const IntroCard = props => {
    const { descriptions, index, playvideo, innerWidth } = props
    let content = <div />
    switch (index) {
        case 0:
            content = (
                <Grid container className="intro-text" alignItems="center">
                    <Grid item xs={12}>
                        <CopyConsumer>
                            {({ copy }) => {
                                return (
                                    <div style={{ lineHeight: '50px' }}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `${copy.home.intro}`,
                                            }}
                                        />
                                    </div>
                                )
                            }}
                        </CopyConsumer>
                    </Grid>
                </Grid>
            )
            break
        case 1:
            content = (
                <Grid container className="card">
                    <Grid container className="inner-content" alignItems="center">
                        {descriptions.map(d => (
                            <Grid container alignItems="center" key={d.number}>
                                <Grid item xs={2}>
                                    <div className="number">{d.number}</div>
                                </Grid>
                                <Grid item xs={10}>
                                    <div className="text" style={{ fontSize: '24px' }}>
                                        {d.text}
                                    </div>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )
            break
        case 2:
            content = (
                <Grid container alignItems="center" className="card">
                    <Grid container className="inner-content" alignItems="center">
                        <Grid item xs={12}>
                            <CopyConsumer>
                                {({ copy }) => {
                                    return (
                                        <div style={{ lineHeight: '30px' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: `${copy.home.intro2}`,
                                                }}
                                            />
                                        </div>
                                    )
                                }}
                            </CopyConsumer>
                            <br />
                            <img src={BBLOCK} width="100%" alt="benchmark-block" />
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case 3:
            content = (
                <Grid container alignItems="center" className="card">
                    <Grid container className="inner-content" alignItems="center">
                        <Grid item xs={6}>
                            <CopyConsumer>
                                {({ copy }) => {
                                    return (
                                        <div style={{ lineHeight: '40px', fontSize: '30px' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: `${copy.home.intro2}`,
                                                }}
                                            />
                                        </div>
                                    )
                                }}
                            </CopyConsumer>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={BBLOCK} width="100%" alt="benchmark-block" />
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case 4:
            content = (
                <Grid container className="card" alignItems="center">
                    <Grid container className="inner-content" alignItems="center">
                        <Grid item xs={6}>
                            {descriptions.map(d => (
                                <Grid container alignItems="center" key={d.number}>
                                    <Grid item xs={2}>
                                        <div className="number">{d.number}</div>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div className="text" style={{ fontSize: '24px' }}>
                                            {d.text}
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <YouTube
                                        style={{ margin: '0 auto' }}
                                        videoId="sjjOmS1nYhI"
                                        width={300}
                                        height={300 / 1.77}
                                        play={() => playvideo()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case 5:
            content = (
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', marginTop: '15px' }}>
                            <YouTube
                                style={{ margin: '0 auto' }}
                                videoId="qQfCx7mXbhA"
                                width={innerWidth}
                                height={innerWidth / 1.77}
                                play={() => playvideo()}
                            />
                        </div>
                    </Grid>
                </Grid>
            )
            break
        default:
            content = <React.Fragment />
    }

    return <StyledContainer>{content}</StyledContainer>
}

IntroCard.defaultProps = {
    index: 0,
    playvideo: () => {},
    innerWidth: 0,
    descriptions: [
        {
            number: '1',
            text: 'Measure your digital maturity',
        },
        {
            number: '2',
            text: 'See the competition',
        },
        {
            number: '3',
            text: 'Define your digital goals',
        },
    ],
}

IntroCard.propTypes = {
    playvideo: PropTypes.func,
    index: PropTypes.number,
    descriptions: PropTypes.array,
    innerWidth: PropTypes.number,
}

export default IntroCard
