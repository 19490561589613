import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import YouTube from 'react-youtube'

const YouTubePane = styled.div`
    position: relative;

    .invisible {
        position: absolute;
        background-color: transparent;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`

class YouTubeView extends Component {
    state = {}

    componentDidMount() {
        window.YTConfig = {
            host: 'https://www.youtube.com',
        }
        const { width, height } = this.props
        this.setState({
            width,
            height,
        })
    }

    play = () => {
        const { ready } = this.state
        ready.target.playVideo()
        this.setState({
            playing: true,
        })
    }

    onReady = event => {
        this.setState({
            ready: event,
        })
    }

    pause = () => {
        this.setState({
            playing: false,
        })
        const { play } = this.props
        play()
    }

    render() {
        const { videoId, play } = this.props

        const { width, height, playing } = this.state

        const opts = {
            width,
            height,
            playerVars: {
                controls: 0, // hide controls during playback
                modestbranding: 1, // reduce YouTube branding
                rel: 0, // show related videos afer playback only from same channel
            },
        }

        return (
            <React.Fragment>
                {width && (
                    <YouTubePane style={{ width: `${opts.width}px`, margin: '0 auto' }}>
                        <YouTube
                            opts={opts}
                            videoId={videoId}
                            onPlay={play}
                            onPause={this.pause}
                            onReady={this.onReady}
                        />
                        <div
                            className="invisible"
                            onClick={this.play}
                            role="button"
                            tabIndex={0}
                            onKeyPress={() => {}}
                            style={{ display: playing ? 'none' : 'inherit' }}
                        />
                    </YouTubePane>
                )}
            </React.Fragment>
        )
    }
}

YouTubeView.defaultProps = {
    width: 0,
    height: 0,
    videoId: '',
    play: () => {},
}

YouTubeView.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    videoId: PropTypes.string,
    play: PropTypes.func,
}

export default YouTubeView
