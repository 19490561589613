import React, { Component } from 'react'
import styled from 'styled-components'
import QueueAnim from 'rc-queue-anim'
import ResultsTopBanner from './ResultsTopBanner'
import ResultContainer from './ResultContainer'
import ResultsBionicWheel from './ResultsBionicWheel'
import { ResultsResponse } from './ResultsDefinition'
import BionicToggleRow, { BionicToggleProps } from '../Bionic/BionicToggleRow'

const StyledContainer = styled.div`
    position: relative;
    -webkit-overflow-scrolling: touch;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    backface-visibility: hidden;
    padding: 15px;
    width: 100%;
`

type Props = {
    results: ResultsResponse,
} & BionicToggleProps

type State = {
    currentBlock: any,
    show: boolean,
    showCount: number,
    fade: string,
    fadeTicker: boolean,
}

class ResultsContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            show: true,
            showCount: 0,
            currentBlock: {},
            fade: 'start',
            fadeTicker: false,
        }
    }

    componentWillReceiveProps() {
        this.setState({ currentBlock: {}, fade: 'none' })
    }

    toggleOpen = (block: any) => {
        // fadeTicker helps to keep track if single  block is being toggle repeatedly
        const { currentBlock, fadeTicker } = this.state
        if (block.blockId === currentBlock.blockId) {
            if (fadeTicker) {
                this.setState({ currentBlock: block, fade: 'all', fadeTicker: false })
            } else {
                this.setState({ currentBlock: block, fade: 'none', fadeTicker: true })
            }
        } else {
            this.setState({ currentBlock: block, fade: 'all', fadeTicker: false })
        }
    }

    render() {
        const { results, bionic, setBionic } = this.props
        const { currentBlock, fade, show } = this.state
        return (
            <StyledContainer>
                {show && (
                    <>
                        <div style={{ margin: '50px auto 10px auto' }}>
                            <ResultsTopBanner results={results} bionic={bionic} />
                        </div>
                        <div style={{ maxWidth: 800, margin: '0 auto', padding: 5 }}>
                            <BionicToggleRow bionic={bionic} setBionic={setBionic} />
                        </div>
                        {bionic && <ResultsBionicWheel results={results} />}
                        {!bionic && (
                            <QueueAnim type="left" key="anim" interval={50}>
                                {results.blocks.map(b => (
                                    <ResultContainer
                                        key={b.blockId}
                                        block={b}
                                        toggleOpen={this.toggleOpen}
                                        currentBlock={currentBlock}
                                        fade={fade}
                                    />
                                ))}
                            </QueueAnim>
                        )}
                    </>
                )}
            </StyledContainer>
        )
    }
}

export default ResultsContainer
