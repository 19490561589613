import React from 'react'
import { Route, Redirect } from 'react-router'
import { routePaths } from '../../utils'
import { UserConsumer, UserProvider } from '../../contexts/User'
import { PageLoad } from '../Page'

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <UserProvider>
            <UserConsumer>
                {userContext => {
                    if (userContext.isLoading) {
                        return <PageLoad />
                    }
                    return (
                        <Route
                            {...rest}
                            render={props =>
                                userContext.isAuthenticated ||
                                props.location.pathname === '/about' ? (
                                    <Component {...props} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: routePaths.signin,
                                            state: { from: props.location },
                                        }}
                                    />
                                )
                            }
                        />
                    )
                }}
            </UserConsumer>
        </UserProvider>
    )
}

export default PrivateRoute
