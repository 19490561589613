import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dialog, MuiThemeProvider, createMuiTheme, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
})

const StyledDialog = styled.div`
    background: ${props => props.theme.white};
    color: #10223a;
    text-align: center;
    padding-bottom: 40px;

    p {
        padding: 15px;
    }

    .close-icon {
        width: 100%;
        height: 40px;
        color: ${props => props.theme.grey};

        button {
            width: 30px !important;
            height: 30px;
            min-height: 0px;
            min-width: 0px;
            margin-right: 5px;
            margin-top: 5px;
            border-radius: 100%;
            float: right;
        }
    }

    .dialog-icon-success {
        margin: 0 auto;
        width: 50px;
        height: 50px;
        line-height: 50px;
        color: ${props => props.theme.darkgreen};
        text-align: center;
        border-radius: 100%;
        font-weight: bold;
        font-size: 30px;
        border: 2px solid ${props => props.theme.darkgreen};
    }

    .dialog-icon-caution {
        margin: 0 auto;
        width: 50px;
        height: 50px;
        line-height: 50px;
        color: ${props => props.theme.red};
        text-align: center;
        border-radius: 100%;
        font-weight: bold;
        font-size: 30px;
        border: 2px solid ${props => props.theme.red};
    }

    .feedback {
        max-width: 300px;
        margin: 10px auto;
    }
`

const SimpleDialog = props => {
    const { dialogOpen, closeDialog, children, noClose } = props

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullWidth
                style={{ width: '100%', margin: '0 auto' }}
                PaperProps={{
                    style: { backgroundColor: 'transparent', margin: '10px' },
                    elevation: 1,
                }}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                }}
                onClose={closeDialog}
                open={dialogOpen}
            >
                <StyledDialog>
                    <div className="close-icon">
                        {noClose ? (
                            <React.Fragment />
                        ) : (
                            <Button onClick={closeDialog}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        )}
                    </div>
                    {children}
                </StyledDialog>
            </Dialog>
        </MuiThemeProvider>
    )
}

SimpleDialog.defaultProps = {
    closeDialog: () => {},
    dialogOpen: false,
    children: {},
    noClose: false,
}

SimpleDialog.propTypes = {
    closeDialog: PropTypes.func,
    dialogOpen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    noClose: PropTypes.bool,
}

export default SimpleDialog
