import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InformationCarousel from './InformationCarousel'

const Dot = styled.div`
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #979797;
    border-radius: 100%;
    margin: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none;
    }
`

class CarouselDots extends Component {
    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown)
    }

    onKeyDown = event => {
        const { keyInputEnabled, dots: dotsCount, index, onChangeIndex } = this.props

        if (!keyInputEnabled) {
            return
        }

        if (event.code === 'ArrowLeft') {
            const previousIndex = index - 1
            const updatedIndex = previousIndex >= 0 ? previousIndex : dotsCount - 1
            onChangeIndex(updatedIndex)
            return
        }

        if (event.code === 'ArrowRight') {
            const updatedIndex = Math.floor((index + 1) % dotsCount)
            onChangeIndex(updatedIndex)
        }
    }

    changeIndex = number => {
        const { onChangeIndex } = this.props
        onChangeIndex(number)
    }

    render() {
        const { index, dots } = this.props
        const dotsArray = Array(dots)
            .fill()
            .map((_, i) => i * i)
        return (
            <div style={{ textAlign: 'center' }}>
                {dotsArray.map((number, ii) => (
                    <Dot
                        tabIndex={number}
                        style={{ backgroundColor: ii === index ? '#10223A' : 'transparent' }}
                        onClick={() => this.changeIndex(ii)}
                        role="button"
                        key={`dot-${number}`}
                    />
                ))}
            </div>
        )
    }
}

CarouselDots.defaultProps = {
    index: 0,
    dots: 0,
    onChangeIndex: () => {},
    keyInputEnabled: true,
}

CarouselDots.propTypes = {
    index: PropTypes.number,
    dots: PropTypes.number,
    onChangeIndex: PropTypes.func,
    keyInputEnabled: PropTypes.bool,
}

export default CarouselDots
