import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { SingleSelect } from '../OptionSelectElements'

const StyledContainer = styled.div`
    margin-bottom: 30px;

    .question-title {
        font-size: 20px;
        padding: 0px 0px 10px 0px;
        color: ${props => props.theme.darkblue};
        border-bottom: 2px solid ${props => props.theme.darkblue};
        margin-bottom: 15px;
        text-align: center;
        float: left;

        .bionic & {
            color: #fff;
            font-size: 22px;
            font-family: 'Proxima Nova';
            border-color: #fff;
            border-width: 1px;
            padding-bottom: 7px;
            margin-bottom: 20px;
        }
    }
`

class FilterQuestion extends Component {
    selected = option => {
        const { question, selected } = this.props
        const o = Object.assign({}, option[0], { type: question.type })
        selected(o)
    }

    render() {
        const { question } = this.props
        return (
            <StyledContainer>
                <p className="question-title">{question.placeholder}</p>
                <SingleSelect
                    question={Object.assign({}, question, { set_options: question.options })}
                    selected={this.selected}
                    noDecoration
                />
            </StyledContainer>
        )
    }
}

FilterQuestion.defaultProps = {
    question: {},
    selected: () => {},
}

FilterQuestion.propTypes = {
    question: PropTypes.object,
    selected: PropTypes.func,
}

export default FilterQuestion
