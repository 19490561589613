import React, { Component } from 'react'
import { Grid } from '@material-ui/core'

import OnboardingSlide from './OnboardingCard'
import OnboardingPWACard from './OnboardingPWACard'

class OnboardingList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayPWA: null,
        }
    }

    componentDidMount() {
        this.displayPWA()
    }

    displayPWA = () => {
        if (this.isIos() && this.isInStandaloneMode) {
            this.setState({
                displayPWA: true,
            })
        } else {
            this.setState({
                displayPWA: false,
            })
        }
    }

    isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test(userAgent)
    }

    isInStandaloneMode = () => {
        return 'standalone' in window.navigator && window.navigator.standalone
    }

    render() {
        const { displayPWA } = this.state
        return (
            <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <OnboardingSlide index={0} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <OnboardingSlide index={1} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <OnboardingSlide index={2} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <OnboardingSlide index={3} />
                    </Grid>
                    {displayPWA ? (
                        <Grid item xs={12} sm={6}>
                            <OnboardingPWACard />
                        </Grid>
                    ) : (
                        <React.Fragment />
                    )}
                </Grid>
            </div>
        )
    }
}

export default OnboardingList
