import highlightNorth from '../../assets/bionic/whole_shape/1600px/part1_hover.png'
import highlightNorthEast from '../../assets/bionic/whole_shape/1600px/part2_hover.png'
import highlightEast from '../../assets/bionic/whole_shape/1600px/part3_hover.png'
import highlightSouthEast from '../../assets/bionic/whole_shape/1600px/part4_hover.png'
import highlightSouthWest from '../../assets/bionic/whole_shape/1600px/part5_hover.png'
import highlightWest from '../../assets/bionic/whole_shape/1600px/part6_hover.png'
import highlightNorthWest from '../../assets/bionic/whole_shape/1600px/part7_hover.png'
import highlightCenter from '../../assets/bionic/whole_shape/1600px/part8_hover.png'

/**
 * Configuration for the bionic wheel, according to specific image files.
 * Each entry describes a specific section of the wheel.
 *
 *      clipPath: the shape of the clickable area for this section. The value is anything that can be passed to the
 *      "clip-path" css property. By keeping all values as percentages we make sure that the position will be the same
 *      even if the image is displayed in a different size.
 *
 *      debugBackground: a color to use as background for this section during debugging. Normally the opacity is set to
 *      zero so this will not be visible.
 *
 *      highlightImage: the path of the image that shows the wheel with this section highlighted.
 *
 *      textTop, textLeft: the coordinates (in percentages) of the approximate center of this section, used to
 *      display the block name. If the text of the block name is showing outside of the correct section, you can try
 *      tweaking these values.
 */

export default {
    NW: {
        clipPath: `polygon(
                        20.57% 9.54%,
                        31.11% 15.90%,
                        37.99% 24.09%,
                        39.62% 27.80%,
                        41.16% 30.18%,
                        28.53% 47.60%,
                        25.82% 47.20%,
                        21.51% 44.09%,
                        14.45% 42.89%,
                        12.57% 42.86%,
                        1.71% 39.98%,
                        0.71% 40.01%,
                        3.86% 29.78%,
                        8.43% 21.44%,
                        13.42% 15.23%
                    )`,
        debugBackground: '#33ff99',
        highlightImage: highlightNorthWest,
        textTop: '28%',
        textLeft: '20%',
    },
    N: {
        clipPath: `polygon(
                        23.19% 7.76%,
                        26.08% 5.98%,
                        29.25% 4.40%,
                        34.76% 2.27%,
                        43.16% 0.32%,
                        50.04% 0.03%,
                        53.47% 0.06%,
                        59.55% 0.83%,
                        64.98% 2.10%,
                        70.29% 4.14%,
                        74.66% 6.44%,
                        76.72% 7.70%,
                        73.44% 13.08%,
                        68.78% 15.84%,
                        68.41% 16.79%,
                        60.98% 26.07%,
                        60.27% 27.74%,
                        58.54% 30.09%,
                        41.22% 30.18%,
                        39.65% 27.74%,
                        37.99% 24.03%,
                        31.11% 15.87%,
                        26.71% 13.25%
                    )`,
        debugBackground: '#9933ff',
        highlightImage: highlightNorth,
        textTop: '13%',
        textLeft: '50%',
    },
    NE: {
        clipPath: `polygon(
                        79.38% 9.51%,
                        86.46% 15.52%,
                        92.29% 22.48%,
                        96.34% 30.53%,
                        99.03% 40.13%,
                        88.80% 42.80%,
                        86.15% 42.83%,
                        79.58% 44.18%,
                        74.24% 48.03%,
                        71.44% 48.43%,
                        58.55% 30.12%,
                        59.95% 28.51%,
                        60.95% 26.07%,
                        68.41% 16.79%,
                        68.75% 15.90%
                    )`,
        debugBackground: '#33ffff',
        highlightImage: highlightNorthEast,
        textTop: '28%',
        textLeft: '80%',
    },
    E: {
        clipPath: `polygon(
                        99.46% 42.86%,
                        99.97% 47.25%,
                        99.97% 51.80%,
                        98.80% 61.31%,
                        95.32% 71.63%,
                        91.23% 78.38%,
                        88.35% 81.98%,
                        88.20% 82.12%,
                        80.09% 75.25%,
                        78.58% 73.15%,
                        78.29% 73.01%,
                        71.81% 67.55%,
                        68.24% 66.37%,
                        66.01% 64.42%,
                        71.47% 48.40%,
                        74.26% 48.03%,
                        79.58% 44.21%,
                        86.18% 42.83%
                    )`,
        debugBackground: '#ff9999',
        highlightImage: highlightEast,
        textTop: '60%',
        textLeft: '86%',
    },
    SE: {
        clipPath: `polygon(
                        86.40% 84.16%,
                        80.29% 89.97%,
                        70.78% 95.66%,
                        62.15% 98.56%,
                        52.41% 99.83%,
                        50.93% 98.33%,
                        51.79% 94.74%,
                        50.90% 91.46%,
                        51.79% 87.70%,
                        50.96% 84.51%,
                        51.79% 80.80%,
                        50.93% 77.58%,
                        50.59% 71.51%,
                        66.01% 64.44%,
                        68.18% 66.31%,
                        71.81% 67.55%,
                        78.63% 73.12%
                    )`,
        debugBackground: '#99ffff',
        highlightImage: highlightSouthEast,
        textTop: '83%',
        textLeft: '67%',
    },
    SW: {
        clipPath: `polygon(
                        47.99% 99.89%,
                        37.19% 98.51%,
                        28.05% 95.03%,
                        19.05% 89.39%,
                        14.00% 84.62%,
                        22.14% 73.15%,
                        22.54% 73.01%,
                        28.33% 67.72%,
                        32.05% 66.48%,
                        34.10% 64.56%,
                        49.59% 71.54%,
                        49.79% 74.45%,
                        49.16% 77.87%,
                        50.04% 81.06%,
                        49.19% 84.82%,
                        50.04% 88.07%,
                        49.13% 91.78%,
                        50.01% 95.00%
                    )`,
        debugBackground: '#ff3333',
        highlightImage: highlightSouthWest,
        textTop: '83%',
        textLeft: '33%',
    },
    W: {
        clipPath: `polygon(
                        12.08% 82.47%,
                        4.97% 72.35%,
                        0.54% 58.41%,
                        0.03% 51.28%,
                        0.37% 42.89%,
                        14.42% 42.89%,
                        21.48% 44.09%,
                        25.76% 47.20%,
                        28.56% 47.66%,
                        34.10% 64.59%,
                        32.05% 66.54%,
                        28.28% 67.75%,
                        22.51% 73.01%,
                        22.14% 73.10%,
                        21.42% 74.22%
                    )`,
        debugBackground: '#ffff99',
        highlightImage: highlightWest,
        textTop: '60%',
        textLeft: '14%',
    },
    C: {
        clipPath: `circle(21.56% at 49.97% 49.91%)`,
        debugBackground: '#ff3399',
        highlightImage: highlightCenter,
        textTop: '50%',
        textLeft: '50%',
    },
}
