import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const feedbackBarStyles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
})

class FeedbackBar extends Component {
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        const { close } = this.props
        close()
    }

    render() {
        const { open, children } = this.props

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                >
                    {children}
                </Snackbar>
            </div>
        )
    }
}

FeedbackBar.defaultProps = {
    close: () => {},
    children: {},
}

FeedbackBar.propTypes = {
    close: PropTypes.func,
    open: PropTypes.bool.isRequired,
    children: PropTypes.object,
}

export default withStyles(feedbackBarStyles)(FeedbackBar)
