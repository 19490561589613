import React from 'react'
import { Block, ResultsResponse } from '../Results/ResultsDefinition'
import BionicWheel, { BLOCK_THEME_TITLES } from '../Bionic/BionicWheel'
import Stars from '../ImportanceStars'
import GoalsBionicModal from './GoalsBionicModal'
import { WithStyles, withStyles } from '@material-ui/core'

type Props = {
    results: ResultsResponse
}

const COLOR_MAPPING = {
    [BLOCK_THEME_TITLES.outcomes]: '#5eb3d8',
    [BLOCK_THEME_TITLES.human]: '#89e7e7',
    [BLOCK_THEME_TITLES.tech]: '#b8e49f',
    [BLOCK_THEME_TITLES.strategy]: '#abbbbe',
}

function GoalsBionicWheel({ results, classes }: React.PropsWithChildren<Props & WithStyles>) {
    return (
        <BionicWheel
            results={results}
            renderScore={(block: Block) => (
                <>
                    <span className={classes.xsOnly}>
                        <Stars
                            readOnly
                            starSize={10}
                            fill={block.importance}
                            starMargin={1}
                        />
                    </span>
                    <span className={classes.smOnly}>
                        <Stars
                            readOnly
                            starSize={18}
                            fill={block.importance}
                        />
                    </span>
                </>
            )}
            renderModal={(block, onClose) => <GoalsBionicModal block={block} onClose={onClose} />}
        />
    )
}

const styles = {
    xsOnly: {
        '@media only screen and (min-width: 768px)': {
            display: 'none',
        },
    },
    smOnly: {
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
}

export default withStyles(styles)(GoalsBionicWheel)
