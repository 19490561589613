import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import BENCHMARK_SCREEN from '../../assets/onboarding/BENCHMARK_SCREEN.png'
import COMPANY_SCREEN from '../../assets/onboarding/COMPANY-PROFILE_SCREEN.png'
import DIMENSION_SCREEN from '../../assets/onboarding/DIMENSION_SCREEN.png'
import PRERESULTS_SCREEN from '../../assets/onboarding/PRERESULTS_SCREEN.png'

const slideDescriptions = {
    1: 'Setup your company profile and start the survey',
    2: 'Answer questions within the areas of digitization',
    3: 'Receive feedback during your assessment process',
    4: 'Benchmark against companies within your industry',
    tip: 'Install this webapp on your mobile device.',
    tip_1: 'In the toolbar',
    tip_2: 'Tap the action icon  in the toolbar',
    tip_3: "Then select 'Add to homescreen'",
}

const StyledContainer = styled.div`
    background-color: ${props => props.theme.white};
    border-radius: 3px;
    // box-shadow: ${props => props.theme.shadowGrey};
    padding: 10px;
`

const SlideDescription = styled.div`
    width: 90%;
    margin-left: auto;
    .number {
        border-radius: 100%;
        border: 3px solid #7a9f92;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 45px;
        margin: 0 auto;
        font-weight: bold;
        font-size: 24px;
        color: #7a9f92;
    }

    .divider {
        width: 50%;
        margin: 20px auto;
        border-bottom: 2px solid #7a9f92;
    }

    .description {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        width: 80%;
        margin: 0 auto;
    }

    .description2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        width: 80%;
        margin: 0 auto;
        padding: 5px;
    }

    @media only screen and (max-width: 375px) {
        .description {
            font-size: 16px;
        }
    }
`

const OnboardingSlide = props => {
    const { index } = props
    let screen
    switch (index) {
        case 0:
            screen = COMPANY_SCREEN
            break
        case 1:
            screen = DIMENSION_SCREEN
            break
        case 2:
            screen = PRERESULTS_SCREEN
            break
        case 3:
            screen = BENCHMARK_SCREEN
            break
        default:
            screen = COMPANY_SCREEN
    }

    const sliderType = window.innerWidth > 700 ? 'slider-big' : 'slider-small'
    const tallScreen = '400px'
    const shortScreen = '325px'
    return (
        <StyledContainer>
            <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="space-around"
                style={{ maxWidth: '480px', margin: '0 auto' }}
            >
                <Grid item xs={5}>
                    <SlideDescription>
                        <div className="number">{index + 1}</div>
                        <div className="divider" />
                        <div className="description">{slideDescriptions[`${index + 1}`]}</div>
                    </SlideDescription>
                </Grid>
                <Grid item xs={7}>
                    <img
                        src={screen}
                        alt="company_screen"
                        style={{ float: 'left' }}
                        height={sliderType === 'slider-big' ? tallScreen : shortScreen}
                    />
                </Grid>
            </Grid>
        </StyledContainer>
    )
}

OnboardingSlide.defaultProps = {
    index: 0,
}

OnboardingSlide.propTypes = {
    index: PropTypes.number,
}

export default OnboardingSlide
