import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonBase } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEye } from '@fortawesome/pro-light-svg-icons'

const StyledContainer = styled.div`
    position: relative;

    .form-inputs {
        .icon {
            display: inline-block;
            height: 34px;
            width: 12%;
            vertical-align: top;
            background: white;
            text-align: center;
            line-height: 34px;
            color: #ccc;

            button {
                min-width: 0px;
                line-height: 30px;
                width: 30px !important;
                height: 30px;
                color: #ccc;
                padding: 0 !important;
                -webkit-appearance: none;
                width: 100%;
                border-radius: 100%;
            }
        }

        input {
            display: inline-block;
            height: 34px;
            width: 76%;
            padding-left: 3px;
            margin-bottom: 10px;
            -webkit-appearance: none;
            border: none;
            font-size: 16px;
            border-radius: 0;

            &:focus {
                outline: none;
            }
        }
    }
`

class PasswordInput extends Component {
    state = {
        passwordInputType: 'password',
    }

    showPassword = () => {
        const { passwordInputType } = this.state
        const inputType = passwordInputType === 'password' ? 'text' : 'password'
        this.setState({
            passwordInputType: inputType,
        })
    }

    render() {
        const { passwordInputType } = this.state
        const { value, handleInputChange, name } = this.props
        return (
            <StyledContainer>
                <div className="form-inputs">
                    <input
                        type="text"
                        name="email"
                        autoComplete="off"
                        value="..."
                        style={{ display: 'none' }}
                        readOnly
                    />
                    <div>
                        <div className="icon">
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                        <input
                            autoComplete="current-password"
                            type={passwordInputType}
                            name={name}
                            placeholder="Enter password"
                            onChange={handleInputChange}
                            value={value}
                        />
                        <div className="icon">
                            <ButtonBase onClick={this.showPassword}>
                                <FontAwesomeIcon icon={faEye} />
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            </StyledContainer>
        )
    }
}

PasswordInput.defaultProps = {
    name: 'password',
    value: '',
    handleInputChange: () => {},
}

PasswordInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    handleInputChange: PropTypes.func,
}

export default PasswordInput
