import React, { useState } from 'react'
import styled from 'styled-components'
import { TERMS_URI } from '../../utils'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const StyledContainer = styled.div`
    .contents {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 200px;
        background-color: ${props => props.theme.lightgrey};
        color: ${props => props.theme.darkblue};
        background-color: ${props => props.theme.darkblue};
        color: white;
        text-align: center;
        box-shadow: 0 -2px 3px ${props => props.theme.darkblue};
        padding: 10px;
        z-index: 999999;
        font-size: 16px;
        p {
            margin-top: 50px;
        }
        a {
            display: inline-block;
            text-align: center;
            color: ${props => props.theme.darkblue};
            color: ${props => props.theme.lightgrey};
        }

        .icon {
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            background: white;
            color: ${props => props.theme.darkblue};
            border-radius: 100%;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-weight: bold;
            &:hover {
                color: ${props => props.theme.grey};
                box-shadow: 0px 2px 2px ${props => props.theme.lightgrey};
            }
        }
    }
`

function CookiesConsent() {
    const [show, setShow] = useState(window.localStorage.getItem('cookies_consent') ? false : true)
    const hide = () => {
        window.localStorage.setItem('cookies_consent', true)
        setShow(false)
    }
    return (
        <StyledContainer>
            {show ? (
                <div className="contents">
                    <Grid container spacing={8} alignItems="center">
                        <Grid item xs={12}>
                            <div className="icon" onClick={hide}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                            <br />
                            <p>
                                BCG may use cookies on this Site and in our communications with you
                                to keep track of your visit to our Site and our communications with
                                you.
                            </p>
                            <br />
                            <a href={TERMS_URI} target="_blank">
                                Learn more
                            </a>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <React.Fragment />
            )}
        </StyledContainer>
    )
}

export default CookiesConsent
