//NOTE: see README TODO
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { routePaths } from '../utils'
import { fetchPresurvey, updatePresurveyQuestion, createSurvey, searchCompanies } from '../api'
import UserContext from '../contexts/User'
import { Page, PageLoad } from '../components/Page'
import { PreSurveyContainer } from '../components/PreSurvey'
import { FeedbackBar, FeedbackBarContentWrapper } from '../components/Feedback'
import { debounce } from 'lodash'

const StyledPage = styled.div`
    background: ${props => props.theme.lightgrey};
`
const currencySymbols = [
    { label: '$', value: 'usd' },
    { label: '€', value: 'eur' },
    { label: '¥', value: 'jpy' },
    { label: '£', value: 'gbp' },
    { label: '¥', value: 'cny' },
]

const debounceTime = 500

class PreSurveyPage extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            questions: null,
            progress: 0,
            currentIndex: 0,
        }
        this.currency = null
        this.industry = null
        this.year = null
    }

    async componentDidMount() {
        const { history } = this.props
        if (!history.location.state || !history.location.state.presurveyId) {
            history.push(routePaths.home)
            return
        }
        const { presurveyId } = history.location.state
        const res = await fetchPresurvey(presurveyId)
        let questions
        if (res.error) {
            history.push(routePaths.home)
            return
        }

        questions = this.setQuestions(res.data.questions)
        questions = this.indexQuestions(questions)

        // set the initial progrress
        const optionalQuestions = questions.filter(q => !q.optional)
        const answers = questions.filter(q => q.answer && q.answer.length > 0 && !q.optional)
        const progress = Math.floor((answers.length / optionalQuestions.length) * 100)

        this.setState({
            questions,
            progress,
            presurveyId,
        })
    }

    componentWillUnmount() {
        this.reset()
    }

    setDependenciesOnLoad = questions => {
        const $this = this
        questions.forEach(q => {
            $this.setDependencies(q)
        })
    }

    setDependencies = question => {
        if (!question.answer || question.answer.length < 1) {
            return true
        }
        if (question.dependencyTag === 'industry') {
            this.industry = question.answer[0].value
        }
        if (question.dependencyTag === 'currency') {
            const currency = currencySymbols.filter(c => question.answer[0].value === c.value)
            this.currency = currency[0].label
        }
        if (question.dependencyTag === 'year') {
            this.year = question.answer[0].value
        }

        return question
    }

    checkRequirements = question => {
        const updatedQuestion = Object.assign({}, question)
        const currencyRequired = question.dependencies
            ? question.dependencies.filter(d => d === 'currency')
            : []
        const industryRequired = question.dependencies
            ? question.dependencies.filter(d => d === 'industry')
            : []
        updatedQuestion.set_options = question.options
        if (currencyRequired.length > 0 && question.currencyValue) {
            updatedQuestion.set_options = this.rebuildOptions(
                { type: 'currency', value: question.currencyValue },
                question
            )
        }
        if (industryRequired.length > 0 && question.industryValue) {
            updatedQuestion.set_options = this.rebuildOptions(
                { type: 'industry', value: question.industryValue },
                question
            )
        }
        if (industryRequired.length > 0 && !question.industryValue) {
            updatedQuestion.set_options = this.rebuildOptions(
                { type: 'industry', value: question.options[0].value },
                question
            )
        }
        return updatedQuestion
    }

    rebuildOptions = (update, question) => {
        let data = null
        if (update && update.type === 'currency') {
            data = question.options.map(d => {
                return { value: d.value.toString(), label: `${d.label}  ${update.value}` }
            })
            return data
        }

        if (question.options[0].options) {
            data = question.options.filter(d => {
                return d.value === update.value
            })
            if (data[0]) {
                data = data[0].options.map(d => {
                    return { value: d.value.toString(), label: d.label }
                })
                return data
            }
        }

        data = question.options.map(d => {
            return { value: d.value.toString(), label: d.label }
        })
        return data
    }

    reset = () => {
        this.setState({
            questions: null,
            progress: 0,
            currentIndex: 0,
        })
    }

    setQuestions = questions => {
        const set = questions.map(q => {
            this.setDependencies(q)
            q.industryValue = this.industry
            q.currencyValue = this.currency
            q.yearValue = this.year

            const question = this.checkRequirements(q)
            return Object.assign({}, question)
        })
        return set
    }

    indexQuestions = questions => {
        const indexedQuestions = questions.map((q, ii) => {
            q.index = ii + 1
            return q
        })
        return indexedQuestions
    }

    updateQuestion = async (updatedQuestion, index) => {
        // push the answer to db
        const { questions, presurveyId } = this.state
        const presurvey = { questions: [] }
        const question = {}
        question.id = updatedQuestion.id
        question.answer = updatedQuestion.answer
        presurvey.questions.push(question)
        const res = await updatePresurveyQuestion(presurveyId, presurvey)
        if (res.status !== 200) {
            // display error message
            this.setState({
                currentIndex: index === 0 ? 0 : index - 1,
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error submitting the response, please try again.',
                },
            })
            return
        }
        let feedbackMessage = null
        if (question.answer.length < 1) {
            index = index === 0 ? 0 : index - 1
            feedbackMessage = question.required
                ? { type: 'warning', text: 'Please complete this question' }
                : null
        }

        // update progress
        const filteredQuestions = questions.filter(q => !q.optional)
        const answers = filteredQuestions.filter(
            q => q.answer && q.answer.length > 0 && !q.optional
        )
        const progress = Math.floor((answers.length / filteredQuestions.length) * 100)

        // update question list
        this.setDependencies(updatedQuestion)
        const updatedQuestions = questions.map(q => {
            if (q.id === updatedQuestion.id) {
                q.answer = updatedQuestion.answer
            }
            q.industryValue = this.industry
            q.currencyValue = this.currency
            q.yearValue = this.year

            const renderQuestion = this.checkRequirements(q)
            return renderQuestion
        })

        this.setState({
            currentIndex: index,
            progress,
            questions: updatedQuestions,
            feedbackMessage,
        })
    }

    finish = async () => {
        // create a survey for completed company profile
        const { presurveyId } = this.state
        const { user } = this.context
        const res = await createSurvey(presurveyId, user.userId)
        const { history } = this.props
        if (res.error) {
            return
        }
        // navigate to the survey
        history.push({
            pathname: routePaths.survey,
            state: {
                surveyId: res.data.surveyId,
            },
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    searchCompany = debounce((term, callback) => {
        searchCompanies(term).then(companies =>
            callback(
                companies.data.map(company => {
                    return { label: company.name, value: company.companyId }
                })
            )
        )
    }, debounceTime)

    render() {
        const { questions, progress, currentIndex, browser, feedbackMessage } = this.state
        return (
            <Page {...this.props} appbar>
                <StyledPage>
                    {questions ? (
                        <PreSurveyContainer
                            questions={questions}
                            updateQuestion={this.updateQuestion}
                            progress={progress}
                            currentIndex={currentIndex}
                            finish={this.finish}
                            updateIndexFromFeedback={this.updateIndexFromFeedback}
                            browser={browser}
                            searchCompany={this.searchCompany}
                        />
                    ) : (
                        <PageLoad />
                    )}
                </StyledPage>
                {feedbackMessage ? (
                    <FeedbackBar open={!!feedbackMessage}>
                        <FeedbackBarContentWrapper
                            message={feedbackMessage.text}
                            variant={feedbackMessage.type}
                            onClose={this.closeFeedbackMessage}
                        />
                    </FeedbackBar>
                ) : (
                    <React.Fragment />
                )}
            </Page>
        )
    }
}

PreSurveyPage.defaultProps = {
    history: {},
}

PreSurveyPage.propTypes = {
    history: PropTypes.object,
}

export default PreSurveyPage
