import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

class Star extends Component {
    constructor(props) {
        super(props)
    }

    select = () => {
        const { readOnly, selected, index } = this.props
        if (readOnly) {
            return
        }
        selected(index)
    }

    render() {
        const { color, noPointers, size, margin } = this.props
        return (
            <FontAwesomeIcon
                onClick={this.select}
                icon={faStar}
                style={{
                    cursor: 'pointer',
                    color,
                    display: 'inline-block',
                    width: `${size}px`,
                    height: `${size}px`,
                    margin: `${margin || 5}px`,
                    pointerEvents: noPointers ? 'none' : 'auto',
                }}
            />
        )
    }
}

Star.defaultProps = {
    selected: () => {},
    readOnly: false,
    size: 30,
}

Star.propTypes = {
    selected: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    size: PropTypes.number,
}

export default Star
