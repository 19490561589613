import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Select, SingleSelect, TextInput } from '../OptionSelectElements'
import { CardHead } from '../Cards'
import { StyledButton } from '../Styles'

const StyledSection = styled.div`
    max-width: 700px;
    margin: 20px auto;
    padding: 15px;

    .section {
        background-color: ${props => props.theme.lightgrey};
        height: 100%;
        padding-bottom: 2rem;

        .section-inner {
            max-width: 800px;
            margin: 0 auto;
            padding: 1rem;

            .tween-one {
                width: 100%;
                opacity: 0;

                .section-title {
                    color: ${props => props.theme.darkgrey};
                    font-size: 1rem;
                    padding-top: 50px;
                    padding-bottom: 1rem;

                    .optional {
                        color: ${props => props.theme.grey};
                        font-weight: bold;
                    }
                }
            }

            button {
                -webkit-appearance: none;
                width: 100%;
            }

            .next-button {
                padding: 1rem !important;
                margin: 2rem 1rem 1rem 0;
                width: 120px;
            }

            .unhighlight {
                background-color: ${props => props.theme.grey} !important;
                &:hover {
                    background-color: ${props => props.theme.darkgrey} !important;
                }
            }
        }
    }
`

class PreSurveySection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showNextButton: false,
        }
        this.nextButton = React.createRef()
        this.selectRef = React.createRef()
    }

    inFocus = () => {
        const { updateIndex, question, progressState } = this.props
        updateIndex(question.index)
        progressState(false)
    }

    focusSection = () => {
        const { updateIndex, question } = this.props
        updateIndex(question.index)
    }

    isBlur = () => {
        const { progressState } = this.props
        progressState(true)
    }

    handleChange = answer => {
        const { question, updateQuestion, index, next } = this.props
        // pass the change up to container to update question with answer
        question.answer = answer
        updateQuestion(question, index)
        let showNextButton = false
        if (question.type === 'multi_select') {
            showNextButton = true
        }
        this.setState({ showNextButton, highlightNext: true }, () => {
            if (question.type !== 'multi_select' && answer.length > 0) {
                const timer = setTimeout(() => {
                    next(question)
                    clearTimeout(timer)
                }, 200)
            }
        })
    }

    next = e => {
        const { next, question } = this.props
        e.preventDefault()
        this.nextButton.current.elements[0].blur()
        next(question)
        this.setState({ highlightNext: false })
    }

    render() {
        const { showNextButton, highlightNext } = this.state
        const { question, id, currentIndex, index, searchCompany } = this.props
        let btn = <React.Fragment />
        if (showNextButton) {
            btn = (
                <QueueAnim type="left" key="anim">
                    <form onSubmit={this.next} ref={this.nextButton}>
                        <StyledButton
                            type="button"
                            className={`next-button ${
                                highlightNext ? ' highlight' : ' unhighlight'
                            }`}
                            onClick={this.next}
                        >
                            next
                        </StyledButton>
                    </form>
                </QueueAnim>
            )
        }

        // questions come in different types
        // the selection mechanism depends on the type of question
        let questionType = <React.Fragment />

        if (question.type === 'single_select' && question.options.length <= 10) {
            questionType = (
                <SingleSelect
                    short
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                />
            )
        } else if (question.type === 'single_select' && question.options.length > 10) {
            questionType = (
                <Select
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                />
            )
        }
        if (question.type === 'single_select_createable' && question.options.length > 8) {
            questionType = (
                <Select
                    loadOptions={searchCompany}
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                    creatable
                />
            )
        }
        if (question.type === 'long_single_select') {
            questionType = (
                <SingleSelect
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                />
            )
        }
        if (question.type === 'multi_select') {
            questionType = (
                <Select
                    ref={this.selectRef}
                    question={question}
                    multipleSelect
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                />
            )
        }

        if (question.type === 'text_input') {
            questionType = (
                <TextInput
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    name={question.dependency_tag}
                    value={question.answer && question.answer[0] ? question.answer[0].value : ''}
                />
            )
        }

        // certain questions depend on a year
        // these questions need to be updated dynamically based on the user's response
        let { title } = question
        if (question.dependencies && question.dependencies.indexOf('year') !== -1) {
            if (question.yearValue) {
                title = `${question.title.split('?')[0]} in ${question.yearValue}?`
            }
        }

        return (
            <StyledSection ref={id} className="tween-one">
                <TweenOne
                    className="tween-one"
                    key="0"
                    animation={{
                        opacity:
                            currentIndex === question.index ||
                            (currentIndex === 0 && question.index === 1)
                                ? 1
                                : 0.3,
                        duration: 150,
                    }}
                >
                    <div className="section-inner">
                        <TweenOne
                            onClick={this.focusSection}
                            className="tween-one"
                            key="0"
                            animation={{ opacity: 1 }}
                        >
                            <CardHead
                                title={title}
                                indicator={`${index}.`}
                                optional={question.optional}
                            />
                        </TweenOne>
                        <TweenOne key="mechanism" className="tween-one" animation={{ opacity: 1 }}>
                            <div>{questionType}</div>
                        </TweenOne>
                        {btn}
                    </div>
                </TweenOne>
            </StyledSection>
        )
    }
}

PreSurveySection.defaultProps = {
    updateQuestion: () => {},
    question: {},
    updateIndex: () => {},
    progressState: () => {},
    index: 0,
    next: () => {},
    id: 0,
    currentIndex: 0,
}

PreSurveySection.propTypes = {
    updateQuestion: PropTypes.func,
    question: PropTypes.object,
    updateIndex: PropTypes.func,
    progressState: PropTypes.func,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    next: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PreSurveySection
