import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { getTintedColor } from '../../utils'

const StyledContainer = styled.div`
    border-radius: 3px;
    box-shadow: 0px 0px 4px ${props => props.theme.black};
    padding: 10px;
`

const ResultDetails = props => {
    const { block } = props
    return (
        <StyledContainer style={{ backgroundColor: block.properties.color }}>
            <Grid container spacing={0}>
                {block.questions.map(q => (
                    <Grid item xs={12} key={q.questionId}>
                        <ResultDetailsRow question={q} block={block} />
                    </Grid>
                ))}
            </Grid>
        </StyledContainer>
    )
}

ResultDetails.defaultProps = {
    block: {},
}

ResultDetails.propTypes = {
    block: PropTypes.object,
}

export default ResultDetails

const StyledRow = styled.div`
    padding: 5px;
    color: ${props => props.theme.white};
    font-weight: bold;

    .dimension-title {
        font-size: 16px;
    }
    .bar-wrapper-ambition {
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;

        .bar-ambition {
            width: 100%;
            height: 16px;
            background: ${props => props.theme.white};

            .ambition-box {
                display: inline-block;
                vertical-align: top;
                width: 25%;
                height: 100%;
                border: 1px solid white;
                height: 16px;
                background: -webkit-linear-gradient(50deg,rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139), rgb(255, 255, 255),rgb(139, 139, 139));
                background: linear-gradient(50deg,rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139));
            }
        }
    }
    .bar-wrapper {
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;

        .bar {
            width: 100%;
            height: 16px;
            /* background: ${props => props.theme.white}; */

            .bar-box {
                display: inline-block;
                vertical-align: top;
                width: 25%;
                height: 100%;
                border: 1px solid white;
            }
            .skipped {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                text-align: center;
                font-size: 0.9rem;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }
`
const ambitionTargetEntries = [
    // ... starting with highest ...
    { threshold: 100, entries: 4 },
    { threshold: 75, entries: 3 },
    { threshold: 50, entries: 2 },
    { threshold: 25, entries: 1 },
    { threshold: Number.MIN_SAFE_INTEGER, entries: 0 },
]

const weightTargetEntries = [
    // ... starting with highest ...
    { threshold: 100, entries: 4 },
    { threshold: 66, entries: 3 },
    { threshold: 33, entries: 2 },
    { threshold: 0, entries: 1 },
    { threshold: Number.MIN_SAFE_INTEGER, entries: 0 },
]

const findTargetEntries = (targetValue, referenceEntries) =>
    referenceEntries.find(item => item.threshold <= targetValue).entries

const ResultDetailsRow = props => {
    const { question, block } = props
    const darkerShadeOfColor = getTintedColor(block.properties.color, -20)
    const { answer, ambition } = question
    const ambitionArray = [0, 1, 2, 3].slice(0, findTargetEntries(ambition, ambitionTargetEntries))
    const weightArray =
        answer.weight != null && answer.weight != 1
            ? [0, 1, 2, 3].slice(0, findTargetEntries(answer.weight, weightTargetEntries))
            : []

    return (
        <StyledRow>
            <Grid container spacing={0}>
                <Grid item xs={8} className="dimension-title">
                    {question.title}
                </Grid>
                <Grid item xs={1}>
                    <div />
                </Grid>
                <Grid item xs={3} style={{ position: 'relative' }}>
                    <div className="bar-wrapper-ambition">
                        <div className="bar-ambition" style={{ backgroundColor: 'white' }}>
                            {ambitionArray.length > 0 && weightArray.length > 0 ? (
                                ambitionArray.map(a => <div className="ambition-box" key={a} />)
                            ) : (
                                <React.Fragment />
                            )}
                        </div>
                    </div>
                    <div className="bar-wrapper">
                        <div
                            className="bar"
                            style={{
                                backgroundColor: weightArray.length > 0 ? 'transparent' : '#ccc',
                            }}
                        >
                            {weightArray.length > 0 ? (
                                weightArray.map(a => (
                                    <div
                                        className="bar-box"
                                        key={a}
                                        style={{ backgroundColor: darkerShadeOfColor }}
                                    />
                                ))
                            ) : (
                                <div className="skipped">NA</div>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </StyledRow>
    )
}

ResultDetailsRow.defaultProps = {
    question: {},
    block: {},
}

ResultDetailsRow.propTypes = {
    question: PropTypes.object,
    block: PropTypes.object,
}
