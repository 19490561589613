import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
    p,
    a {
        display: inline;
        font-size: 12px;
    }

    a {
        color: ${props => props.theme.darkblue};
    }
`

const Attribution = () => (
    <StyledContainer>
        <p>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                Terms of Service
            </a>{' '}
            apply.
        </p>
    </StyledContainer>
)

export default Attribution
