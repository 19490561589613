import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CardBody } from '../Cards'

const StyledOption = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin: 5px;

    .option-label {
        margin-bottom: 0.5rem;
    }

    .uppercase-label {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
    }

    .bionic & {
        margin: 6px 0;
        .option-label {
            font-family: 'Proxima Nova';
            font-size: 22px;
            font-weight: normal;
        }
    }
`

class ShortSingleSelectOption extends Component {
    select = () => {
        const { select, option } = this.props
        select(option)
    }

    render() {
        const { question, option, short, index, noDecoration } = this.props
        const isSelected =
            question.answer &&
            question.answer.length > 0 &&
            question.answer[0].value === option.value
        return (
            <StyledOption onClick={this.select}>
                <CardBody
                    key={option.label}
                    selected={isSelected}
                    short={short}
                    index={index}
                    hoverable
                    noDecoration={noDecoration}
                >
                    <div className={`option-label ${option.description ? 'uppercase-label' : ''}`}>
                        {option.label}
                    </div>
                    <div className="option-description">{option.description}</div>
                </CardBody>
            </StyledOption>
        )
    }
}

ShortSingleSelectOption.defaultProps = {
    select: () => {},
    question: {},
    short: false,
    index: 0,
    option: {},
    noDecoration: false,
}

ShortSingleSelectOption.propTypes = {
    select: PropTypes.func,
    question: PropTypes.object,
    short: PropTypes.bool,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    option: PropTypes.object,
    noDecoration: PropTypes.bool,
}

export default ShortSingleSelectOption
