import React from 'react'
import { Block, Question } from '../Results/ResultsDefinition'
import BionicScoreCircle, { COLOR_BENCHMARK, COLOR_SCORE } from '../Bionic/BionicScoreCircle'
import BionicModal from '../Bionic/BionicModal'
import iconBenchmark from '../../assets/bionic/icons/icon_benchmark.png'
import iconYourScore from '../../assets/bionic/icons/icon_yourscore.png'
import { Grid, WithStyles, withStyles } from '@material-ui/core'
import { NABar } from '../Results/ResultsBionicModal'

type Props = {
    block: Block,
    onClose: () => void,
}

const chartStyles = {
    chartNumbers: {
        position: 'absolute',
        left: '50%',
        fontSize: 15,
        lineHeight: 1,
        transform: 'translateX(-50%)',
        fontFamily: 'Kelson',
        letterSpacing: -1,
        '@media only screen and (max-width: 768px)': {
            fontSize: 13,
            marginTop: 4,
        },
    },
}

// @ts-ignore
const BenchmarkChart = withStyles(chartStyles)(({ score, benchmark, classes }: any) => (
    <div style={{ padding: '20px 0' }}>
        <div style={{ position: 'relative', height: 26 }}>
            {/*Background*/}
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    background: '#31475b',
                }}
            />
            {/*Score bar*/}
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: `${score}%`,
                    height: 11,
                    background: COLOR_SCORE,
                }}
            >
                {/*Score icon*/}
                <img
                    src={iconYourScore}
                    alt="Your Score"
                    style={{
                        position: 'absolute',
                        left: '100%',
                        top: '0',
                        height: 11,
                    }}
                />
                {/*Score number*/}
                <div
                    className={classes.chartNumbers}
                    style={{
                        color: '#006def',
                        bottom: '100%',
                        marginBottom: 4,
                    }}
                >
                    {score}
                </div>
            </div>
            {/*Benchmark bar*/}
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: `${benchmark}%`,
                    height: 11,
                    background: `repeating-linear-gradient(90deg, ${COLOR_BENCHMARK}, ${COLOR_BENCHMARK} 2px, transparent 2px, transparent 3px)`,
                }}
            >
                {/*Benchmark icon*/}
                <img
                    src={iconBenchmark}
                    alt="Benchmark"
                    style={{
                        position: 'absolute',
                        left: '100%',
                        bottom: 1,
                        height: 12,
                        marginLeft: 2,
                    }}
                />
                {/*Benchmark number*/}
                <div
                    className={classes.chartNumbers}
                    style={{
                        color: '#e4b600',
                        top: '100%',
                        marginTop: 8,
                    }}
                >
                    {benchmark}
                </div>
            </div>
        </div>
    </div>
))

const WEIGHT_SIZE_MAPPING = {
    0: '25%',
    33: '50%',
    66: '75%',
    100: '100%',
}

const detailRowStyles = {
    root: {
        marginBottom: 8,
        '@media only screen and (min-width: 768px)': {
            marginBottom: 12,
        },
    },
    rowTitle: {
        fontSize: 16,
        lineHeight: 1.1,
        fontFamily: 'Proxima Nova',
        '@media only screen and (max-width: 768px)': {
            fontSize: 14,
        },
    },
}

const DetailRow = withStyles(detailRowStyles)(
    ({ question, classes }: { question: Question } & WithStyles) => {
        const { weight } = question.answer
        return (
            <Grid container spacing={8} className={classes.root} alignItems="center">
                <Grid item xs={12} sm={6} className={classes.rowTitle}>
                    {question.title}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/*Score bar*/}
                    {weight === 1 ? (
                        <div style={{ marginBottom: 5 }}>
                            <NABar />
                        </div>
                    ) : (
                        <div
                            style={{
                                borderRadius: 5,
                                height: 8,
                                background: '#d8d8d8',
                                position: 'relative',
                                marginBottom: 5,
                                boxSizing: 'content-box',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    width: WEIGHT_SIZE_MAPPING[weight],
                                    background: COLOR_SCORE,
                                    borderRight: 'solid 1px #10263a',
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                }}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            borderRadius: 5,
                            height: 8,
                            background: '#d8d8d8',
                            position: 'relative',
                            boxSizing: 'content-box',
                        }}
                    >
                        {/*Benchmark bar*/}
                        {!!question.benchmark && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    width: `${question.benchmark}%`,
                                    background: COLOR_BENCHMARK,
                                    borderRight: 'solid 1px #10263a',
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                }}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        )
    }
)

const styles = {
    separator: {
        height: 1,
        background: '#425566',
        border: 0,
        marginBottom: 32,
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
}

function BenchmarksBionicModal({ block, onClose, classes }: Props & WithStyles) {
    const score = Math.round(block.preresults.userScore)
    const benchmark = Math.round(block.benchmark.benchmarkScore)
    return (
        <BionicModal block={block} onClose={onClose}>
            <div
                style={{
                    display: 'flex',
                    padding: '0 32px',
                    justifyContent: 'space-evenly',
                    marginBottom: 32,
                }}
            >
                <BionicScoreCircle
                    score={score}
                    size={50}
                    fontSize={23}
                    label="YOUR SCORE"
                    borderColor={COLOR_SCORE}
                    screen="xs"
                />
                <BionicScoreCircle
                    score={score}
                    size={75}
                    fontSize={40}
                    label="YOUR SCORE"
                    borderColor={COLOR_SCORE}
                    screen="sm"
                />
                <BionicScoreCircle
                    score={benchmark}
                    size={50}
                    fontSize={23}
                    label="BENCHMARK"
                    borderColor={COLOR_BENCHMARK}
                    screen="xs"
                />
                <BionicScoreCircle
                    score={benchmark}
                    size={75}
                    fontSize={40}
                    label="BENCHMARK"
                    borderColor={COLOR_BENCHMARK}
                    screen="sm"
                />
            </div>
            <div style={{ marginBottom: 32 }}>
                <BenchmarkChart score={score} benchmark={benchmark} />
            </div>
            <hr className={classes.separator} />
            {block.questions.map(q => (
                <DetailRow question={q} key={q.questionId} />
            ))}
        </BionicModal>
    )
}

export default withStyles(styles)(BenchmarksBionicModal)
