import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Collapse, Button, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import PreResultsCircles from './PreResultsCircles'
import PreResultsSlides from './PreResultsSlides'
import WelcomeMsg from '../Elements/WelcomeMsg'
import { StyledButton } from '../../Styles'

const StyledContainer = styled.div`
    border-radius: 3px;
    box-shadow: ${props => props.theme.shadowGrey};
    background-color: ${props => props.theme.lightgrey};
    margin: 1rem auto;
    width: 100%;
    position: relative;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    color: ${props => props.theme.darkblueText};

    .slides-wrapper-top {
        position: relative;
    }

    .slides-wrapper-bottom {
        position: relative;
        height: 190px;
        z-index: 0;
        display: inline-block;
    }

    .title-description {
        padding: 10px 15px 15px 15px;
        color: ${props => props.theme.darkblueText};
        font-size: 1.3rem;
        .title {
            font-weight: bold;
        }

        .description {
            font-size: 1rem;
        }
    }

    .best-practice-action {
        width: 100%;
        padding: 15px;
        text-align: center;
        text-transform: none !important;
        font-size: 0.8rem !important;
        color: ${props => props.theme.darkblueText};

        .text {
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
        }
        .icon {
            margin-left: 10px;
        }
    }

    .divider {
        width: 90%;
        margin: 0 auto;
        height: 1px;
        background-color: ${props => props.theme.grey};
    }

    .collapse {
        font-family: 'bcg', 'Helvetica Neue', sans-serif;
        display: inline-block;
        padding: 0;
        width: 100% !important;
    }

    .best-practice {
        height: 100%;
        padding: 15px;
        // max-height: 210px;

        .title {
            font-weight: bold;
            font-size: 1.3rem;
        }
        .text {
            font-size: 1.2rem;
        }
    }

    .best-practice-container {
        position: relative;
        margin-top: 20px;
    }

    .final-btn-wrapper {
        padding: 15px 15px 0px 15px;
    }

    .tablet-only {
        display: none;
    }

    @media only screen and (min-width: 660px) {
        .slides-wrapper-bottom {
            height: 250px;
        }
        .slides-container {
            position: relative;
        }
        .best-practice-container {
            min-height: 230px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .tablet-only {
            display: inherit;
        }
        .mobile-only {
            display: none !important;
        }
        .slides-container-border {
            border-right: 1px solid #ccc;
            position: absolute;
            right: 0;
            width: 2px;
            top: 0;
            bottom: 0px;
            background: #ccc;
            z-index: 9;
        }
    }

    @media only screen and (max-width: 320px) {
        .slides-wrapper-bottom {
            height: 170px;
        }
    }
`

class PreResultsContainer extends Component {
    state = {
        storyOpen: false,
        preResultsReady: 0,
    }

    componentDidMount() {
        this.currentBlockAndSlideIndex()
        const storyOpen = window.innerWidth >= 660
        this.setState({
            preResultsReady: 1,
            storyOpen,
        })
    }

    componentWillReceiveProps(newProps) {
        const { currentBlock } = this.state
        if (
            !currentBlock ||
            (newProps.currentBlock && newProps.currentBlock.blockId !== currentBlock.blockId)
        ) {
            this.currentBlockAndSlideIndex()
        }
    }

    currentBlockAndSlideIndex = () => {
        const { blocks, currentBlock } = this.props
        // const activeBlock = (currentBlock && currentBlock.preresults)
        // ? currentBlock : blocks[0];

        let slideIndex = 0
        if (currentBlock) {
            for (let i = 0; i < blocks.length; i += 1) {
                if (currentBlock.blockId === blocks[i].blockId) {
                    slideIndex = i
                }
            }
        }
        this.setState({ currentBlock, slideIndex })
    }

    toggleBestPractice = () => {
        const { storyOpen } = this.state
        this.setState({
            storyOpen: !storyOpen,
        })
    }

    updateCurrentBlock = (currentBlock, slideIndex) => {
        this.setState({ currentBlock, slideIndex })
    }

    updateCurrentBlockAndSlideIndex = (currentBlock, slideIndex) => {
        this.setState({ slideIndex, currentBlock })
    }

    select = block => {
        const { select } = this.props
        select(block)
    }

    render() {
        const { blocks, goToResults, profile } = this.props
        const { storyOpen, currentBlock, slideIndex, preResultsReady } = this.state
        // check to see if any blocks have preresults
        const completedBlocks = blocks.filter(b => b.preresults)
        const anyCompleted = completedBlocks.length > 0
        const allCompleted = completedBlocks.length >= blocks.length
        return (
            <React.Fragment>
                {anyCompleted ? (
                    <StyledContainer style={{ opacity: preResultsReady }}>
                        <Grid container>
                            <Grid item xs={12}>
                                {allCompleted && (
                                    <div className="final-btn-wrapper">
                                        <StyledButton onClick={goToResults} customClass="narrow">
                                            FINAL RESULTS
                                        </StyledButton>
                                    </div>
                                )}
                                <div className="title-description" style={{ paddingBottom: '0px' }}>
                                    <div className="title">Pre Results</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className="slides-container">
                                    <div className="title-description">
                                        <div className="description">
                                            Compared to your industry worldwide
                                        </div>
                                    </div>
                                    <PreResultsCircles
                                        blocks={blocks}
                                        currentBlock={currentBlock}
                                        updateCurrentBlock={this.updateCurrentBlockAndSlideIndex}
                                    />
                                    <div className="slides-wrapper-top">
                                        <PreResultsSlides
                                            profile={profile}
                                            blocks={blocks}
                                            updateCurrentBlock={this.updateCurrentBlock}
                                            slideIndex={slideIndex}
                                            select={this.select}
                                        />
                                    </div>
                                    <div className="slides-wrapper-bottom" />
                                    <div className="tablet-only slides-container-border" />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div className="best-practice-container">
                                    <div className="tablet-only">
                                        <div className="title-description">
                                            <div className="description">Best Practice Story</div>
                                        </div>
                                    </div>
                                    <div
                                        className="divider mobile-only"
                                        style={{ display: storyOpen ? 'inherit' : 'none' }}
                                    />
                                    <Collapse in={storyOpen} className="collapse">
                                        {currentBlock && currentBlock.preresults ? (
                                            <div className="best-practice">
                                                <div className="title">
                                                    {currentBlock.bestPractices[0].title}
                                                </div>
                                                <br />
                                                <div className="text">
                                                    {currentBlock.bestPractices[0].text}
                                                </div>
                                                <br />
                                            </div>
                                        ) : (
                                            <div className="best-practice">
                                                <div className="text">
                                                    Complete all the questions in this block to see
                                                    how you compare to other companies in your
                                                    industry and to see a best practice story.
                                                </div>
                                            </div>
                                        )}
                                    </Collapse>
                                    <div className="mobile-only">
                                        <div className="divider" />
                                        <Button
                                            className="best-practice-action"
                                            onClick={this.toggleBestPractice}
                                        >
                                            <div className="text">
                                                {storyOpen ? 'Hide ' : 'Show '}
                                                Best Practice Story
                                            </div>
                                            {storyOpen ? (
                                                <FontAwesomeIcon
                                                    className="icon"
                                                    icon={faAngleUp}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    className="icon"
                                                    icon={faAngleDown}
                                                />
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </StyledContainer>
                ) : (
                    <WelcomeMsg
                        title="Welcome!"
                        text="Answer questions in each block to receive your digital maturity score."
                    />
                )}
            </React.Fragment>
        )
    }
}

PreResultsContainer.defaultProps = {
    profile: {},
    blocks: [],
    currentBlock: {},
    select: () => {},
    goToResults: () => {},
}

PreResultsContainer.propTypes = {
    profile: PropTypes.object,
    blocks: PropTypes.array,
    currentBlock: PropTypes.object,
    select: PropTypes.func,
    goToResults: () => {},
}

export default PreResultsContainer
