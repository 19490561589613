import React from 'react'
import { Block, Question } from '../Results/ResultsDefinition'
import BionicScoreCircle, {
    COLOR_BENCHMARK,
    COLOR_SCORE,
    COLOR_TARGET,
} from '../Bionic/BionicScoreCircle'
import BionicModal from '../Bionic/BionicModal'
import { Grid, WithStyles, withStyles } from '@material-ui/core'
import Stars from '../ImportanceStars/Stars'
import GoalFeedback from './GoalFeedback'

type Props = {
    block: Block,
    onClose: () => void,
}

const detailRowStyles = {
    root: {
        '@media only screen and (min-width: 768px)': {
            marginBottom: 4,
        },
    },
    rowTitle: {
        fontSize: 16,
        lineHeight: 1.1,
        fontFamily: 'Proxima Nova',
        '@media only screen and (max-width: 768px)': {
            fontSize: 14,
        },
    },
}

const DetailRow = withStyles(detailRowStyles)(
    ({ question, classes }: { question: Question } & WithStyles) => (
        <Grid container alignItems="center" spacing={8} className={classes.root}>
            <Grid item xs={6} className={classes.rowTitle}>
                {question.title}
            </Grid>
            <Grid item xs={6} container justify="flex-end">
                <Stars
                    fill={question.importance}
                    readOnly
                    starSize={18}
                />
            </Grid>
        </Grid>
    )
)

const styles = {
    separator: {
        height: 1,
        background: '#425566',
        border: 0,
        marginBottom: 32,
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
}

function GoalsBionicModal({ block, onClose, classes }: Props & WithStyles) {
    const score = block.preresults.userScore
    const ambition = block.ambition.ambitionScore
    const ambitionDiff = score - ambition
    const benchmark = block.benchmark.benchmarkScore
    const benchmarkDiff = score - benchmark
    return (
        <BionicModal block={block} onClose={onClose}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginBottom: 32,
                }}
            >
                <BionicScoreCircle
                    score={score}
                    size={50}
                    fontSize={23}
                    label="YOUR SCORE"
                    borderColor={COLOR_SCORE}
                    screen="xs"
                />
                <BionicScoreCircle
                    score={score}
                    size={75}
                    fontSize={40}
                    label="YOUR SCORE"
                    borderColor={COLOR_SCORE}
                    screen="sm"
                />
                <BionicScoreCircle
                    score={Math.abs(ambitionDiff)}
                    size={50}
                    fontSize={23}
                    label={ambitionDiff < 0 ? 'BELOW TARGET' : 'ABOVE TARGET'}
                    borderColor={COLOR_TARGET}
                    screen="xs"
                />
                <BionicScoreCircle
                    score={Math.abs(ambitionDiff)}
                    size={75}
                    fontSize={40}
                    label={ambitionDiff < 0 ? 'BELOW TARGET' : 'ABOVE TARGET'}
                    borderColor={COLOR_TARGET}
                    screen="sm"
                />
                <BionicScoreCircle
                    score={Math.abs(benchmarkDiff)}
                    size={50}
                    fontSize={23}
                    label={benchmarkDiff < 0 ? 'BELOW BENCHMARK' : 'ABOVE BENCHMARK'}
                    borderColor={COLOR_BENCHMARK}
                    screen="xs"
                />
                <BionicScoreCircle
                    score={Math.abs(benchmarkDiff)}
                    size={75}
                    fontSize={40}
                    label={benchmarkDiff < 0 ? 'BELOW BENCHMARK' : 'ABOVE BENCHMARK'}
                    borderColor={COLOR_BENCHMARK}
                    screen="sm"
                />
            </div>
            <hr className={classes.separator} />
            {block.questions.map(q => (
                <DetailRow question={q} key={q.questionId} />
            ))}
            <GoalFeedback block={block} />
        </BionicModal>
    )
}

export default withStyles(styles)(GoalsBionicModal)
