import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import SingleSelectOption from './SingleSelectOption'

const StyledSelect = styled.div``

class ShortSingleSelect extends Component {
    selected = option => {
        const { selected } = this.props
        selected([option])
    }

    render() {
        const { question, short, noDecoration } = this.props
        return (
            <StyledSelect>
                <Grid container spacing={0}>
                    {question.set_options.map((d, ii) => (
                        <Grid
                            key={d.label}
                            item
                            xs={12}
                            sm={short ? 6 : 12}
                            md={short ? 6 : 12}
                            lg={short ? 6 : 12}
                            className="single-slect-item"
                        >
                            <SingleSelectOption
                                index={ii}
                                option={d}
                                question={question}
                                select={this.selected}
                                short={short}
                                noDecoration={noDecoration}
                            />
                        </Grid>
                    ))}
                </Grid>
            </StyledSelect>
        )
    }
}

ShortSingleSelect.defaultProps = {
    selected: () => {},
    question: {},
    short: false,
    noDecoration: false,
}

ShortSingleSelect.propTypes = {
    selected: PropTypes.func,
    question: PropTypes.object,
    short: PropTypes.bool,
    noDecoration: PropTypes.bool,
}

export default ShortSingleSelect
