import React, { Component } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { getScoreColor } from '../../utils'
import { ResultsResponse } from './ResultsDefinition'
import BionicScoreCircle from '../Bionic/BionicScoreCircle'

const StyledBlock = styled.div`
    margin: 0 auto;
    max-width: 800px;
    box-shadow: 0px 0px 4px ${props => props.theme.black};
    border-radius: 3px;
    color: ${props => props.theme.white};
    padding: 5px 5px 15px 5px;
    position: relative;
    z-index: 2;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    background: ${props => props.theme.darkblueGradient};

    .results-title {
        margin-top: 5px;
        font-size: 1.2rem;
        padding: 5px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .results-description {
        margin-top: 15px;
        font-size: 16px;
        padding: 5px;
        max-width: 450px;
    }

    .results-number {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        font-size: 1.5rem;
        text-align: center;
        line-height: 45px;
        border: 2px solid ${props => props.theme.white};
        color: ${props => props.theme.white};
        float: right;
        margin: 5px 5px;
        cursor: pointer;
    }

    @keyframes animateOpacity {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @media only screen and (min-width: 768px) {
        .results-title {
            margin-top: 15px;
        }

        .results-number {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            font-size: 55px;
            text-align: center;
            line-height: 85px;
            border-width: 5px !important;
            color: ${props => props.theme.white};
            float: right;
            margin: 15px 15px;
            cursor: pointer;
        }
    }

    .bionic & {
        border-radius: 4px;
        box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.17);
        border: solid 1px #1d5aaa;
        background-image: radial-gradient(circle at 50% 0, #173458, #142331 123%);
        padding: 24px;

        .results-title {
            margin: 0;
            padding: 0;
            font-size: 17px;
            font-weight: bold;
        }
        .results-description {
            padding: 0;
            line-height: 1.2;
            font-size: 17px;
            font-weight: 300;
        }

        @media only screen and (max-width: 768px) {
            .results-title {
                font-size: 13px;
            }
            .results-description {
                font-size: 13px;
                margin-top: 4px;
            }
        }
    }
`

type Props = {
    results: ResultsResponse,
    bionic: boolean,
}

type State = {
    scrollPosition: number,
}

class ResultsTopBanner extends Component<Props, State> {
    state = {
        scrollPosition: 0,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll)
    }

    scroll = () => {
        const doc = document.documentElement
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        const { scrollPosition } = this.state
        if (scrollPosition > 200 && top > 200) {
            // don't change state
        } else if (scrollPosition < 200 && top > 200) {
            this.setState({ scrollPosition: 201 })
        } else if (scrollPosition > 200 && top < 201) {
            this.setState({ scrollPosition: 0 })
        }
    }

    render() {
        const { results, bionic } = this.props
        const { scrollPosition } = this.state
        const { results: { userScore = -1, descriptor = null, description = null } = {} } = results

        const color = userScore >= 0 ? getScoreColor(userScore / 100) : 'white'
        let score: (null | number | string) = userScore >= 0 ? userScore : null
        if (score !== null && score < 0) {
            score = 'N/A'
        }
        // const target = results.
        return (
            <StyledBlock style={{ opacity: scrollPosition > 0 ? 0 : 1 }}>
                <Grid container spacing={bionic ? 8 : 0}>
                    <Grid item xs={bionic ? 12 : 8} sm={8}>
                        <div className="results-title">{descriptor}</div>
                        <div className="results-description">{description}</div>
                    </Grid>
                    <Grid item xs={bionic ? 12 : 4} sm={4}>
                        {bionic ? (
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <BionicScoreCircle
                                    score={Number(score)}
                                    fontSize={17}
                                    borderColor="#146fe7"
                                    label="YOUR SCORE"
                                    size={38}
                                    screen="xs"
                                />
                                <BionicScoreCircle
                                    score={Number(score)}
                                    fontSize={49}
                                    borderColor="#146fe7"
                                    label="YOUR SCORE"
                                    size={88}
                                    screen="sm"
                                />
                                <BionicScoreCircle
                                    score={results.profile.results.userAmbition}
                                    fontSize={17}
                                    borderColor="#4ebd6c"
                                    label="YOUR TARGET"
                                    size={38}
                                    screen="xs"
                                />
                                <BionicScoreCircle
                                    score={results.profile.results.userAmbition}
                                    fontSize={49}
                                    borderColor="#4ebd6c"
                                    label="YOUR TARGET"
                                    size={88}
                                    screen="sm"
                                />
                            </div>
                        ) : (
                            <div
                                className="results-number"
                                style={{ border: `2px solid ${color}`, color }}
                            >
                                {score}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </StyledBlock>
        )
    }
}

export default ResultsTopBanner
