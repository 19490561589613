import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import SwipeableViews from 'react-swipeable-views'
import PreResultSlide from './PreResultSlide'

const StyledContainer = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 15px auto;
    top: 0;

    .icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 40%;
        color: ${props => props.theme.darkblue};
        line-height: 50px;
        font-size: 50px;
        text-align: center;
    }

    .icon:hover {
        color: #ccc;
    }

    .arrow-bar {
        position: absolute;
        width: 55px;
        top: 0;
        bottom: 0;
        background: #f6f6f6;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .left {
        left: 0;
    }

    .right {
        right: -2px;
    }

    .transparent {
        background-image: none;
    }

    .desktop-only {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        .desktop-only {
            display: inherit;
        }
    }
`

class PreResultsSlides extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0,
            swipeDelay: '0s',
            swipeDuration: '0.2s',
            sliderStyles: {
                root: {
                    padding: '0 60px',
                    overflowX: window.innerWidth < 400 ? 'none' : 'hidden',
                },
                slideContainer: {
                    padding: '5px 10px',
                    overflow: 'none',
                    width: '200px',
                },
                slide: {
                    padding: 15,
                    minHeight: 100,
                    overflow: 'none',
                },
            },
        }
        this.swipeableRef = React.createRef()
    }

    componentDidMount() {
        this.setSliderStyles()
        // window.addEventListener('resize', this.setSliderStyles);
    }

    componentWillReceiveProps(newProps) {
        const { index } = this.state
        if (newProps.slideIndex !== index) {
            this.setState({ index: newProps.slideIndex })
        }
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.setSliderStyles);
    }

    handleChangeIndex = index => {
        const { updateCurrentBlock, blocks } = this.props
        const block = blocks[index]
        updateCurrentBlock(block, index)
        this.setState({ index })
    }

    setSliderStyles = () => {
        const { current } = this.swipeableRef
        const { containerNode } = current
        const { clientWidth } = containerNode
        this.setState({
            sliderStyles: {
                root: {
                    padding: clientWidth < 250 ? '0 30px' : '0 60px',
                    overflowX: window.innerWidth < 400 ? 'none' : 'hidden',
                },
                slideContainer: {
                    padding: '5px 10px',
                    overflow: 'none',
                    width: clientWidth < 250 ? '100%' : `${clientWidth}px`,
                },
                slide: {
                    padding: 15,
                    minHeight: 100,
                    overflow: 'none',
                },
            },
        })
    }

    prev = () => {
        const { index } = this.state
        if (index > 0) {
            this.handleChangeIndex(index - 1)
        }
    }

    next = () => {
        const { index } = this.state
        const { blocks } = this.props
        if (index < blocks.length - 1) {
            this.handleChangeIndex(index + 1)
        }
    }

    render() {
        const { blocks, select, profile } = this.props
        const { index, swipeDuration, swipeDelay, sliderStyles } = this.state
        return (
            <StyledContainer>
                <SwipeableViews
                    ref={this.swipeableRef}
                    index={index}
                    enableMouseEvents
                    axis="x"
                    ignoreNativeScroll
                    onChangeIndex={this.handleChangeIndex}
                    style={sliderStyles.root}
                    slideStyle={sliderStyles.slideContainer}
                    springConfig={{
                        duration: swipeDuration,
                        easeFunction: 'cubic-bezier(0.1, 0.35, 0.2, 1)',
                        delay: swipeDelay,
                    }}
                >
                    {blocks.map(b => (
                        <PreResultSlide
                            key={b.blockId}
                            block={b}
                            select={select}
                            profile={profile}
                        />
                    ))}
                </SwipeableViews>
                {index > 0 ? (
                    <div
                        className="arrow-bar left desktop-only"
                        onClick={this.prev}
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex={0}
                    >
                        <div className="icon left transparent">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </div>
                    </div>
                ) : (
                    <React.Fragment />
                )}
                {index === blocks.length - 1 ? (
                    <React.Fragment />
                ) : (
                    <div
                        className="arrow-bar right desktop-only"
                        onClick={this.next}
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex={-1}
                    >
                        <div className="icon right transparent">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                    </div>
                )}
            </StyledContainer>
        )
    }
}

PreResultsSlides.defaultProps = {
    profile: {},
    blocks: [],
    updateCurrentBlock: {},
    select: () => {},
}

PreResultsSlides.propTypes = {
    profile: PropTypes.object,
    blocks: PropTypes.array,
    updateCurrentBlock: PropTypes.func,
    select: PropTypes.func,
}

export default PreResultsSlides
