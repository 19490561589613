import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledContainer = styled.div`
    .form-input {
        .icon {
            display: inline-block;
            height: 34px;
            width: 12%;
            vertical-align: top;
            background: white;
            text-align: center;
            line-height: 36px;
            color: #ccc;
        }

        input {
            display: inline-block;
            height: 34px;
            width: 88%;
            padding-left: 3px;
            margin-bottom: 15px;
            -webkit-appearance: none;
            border: none;
            font-size: 16px;
            border-radius: 0;

            &:focus {
                outline: none;
            }
        }
    }

    .locked {
        .icon {
            background: ${props => props.theme.lightgrey};
        }
        input {
            background: ${props => props.theme.lightgrey};
        }
    }
`

// this component accepts a child
// the child should be an icon
const SimpleInput = props => {
    const { children, handleInputChange, value, name, placeholder, locked, type } = props

    return (
        <StyledContainer>
            {locked ? (
                <div className="form-input locked">
                    <div className="icon">{children}</div>
                    <input
                        type={type || 'text'}
                        name={name}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        defaultValue={value}
                        readOnly
                        autoComplete="off"
                    />
                </div>
            ) : (
                <div className="form-input">
                    <div className="icon">{children}</div>
                    <input
                        type={type || 'text'}
                        name={name}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        value={value}
                        autoComplete="off"
                        required
                    />
                </div>
            )}
        </StyledContainer>
    )
}

SimpleInput.defaultProps = {
    type: 'text',
    name: '',
    placeholder: '',
    children: {},
    value: '',
    locked: false,
    handleInputChange: () => {},
}

SimpleInput.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.object,
    value: PropTypes.string,
    handleInputChange: PropTypes.func,
    locked: PropTypes.bool,
}

export default SimpleInput
