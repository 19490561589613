/* eslint-disable */
const getTintedColor = (color, v) => {
    if (color.length > 6) {
        color = color.substring(1, color.length)
    }
    var rgb = parseInt(color, 16)
    var r = Math.abs(((rgb >> 16) & 0xff) + v)
    if (r > 255) r = r - (r - 255)
    var g = Math.abs(((rgb >> 8) & 0xff) + v)
    if (g > 255) g = g - (g - 255)
    var b = Math.abs((rgb & 0xff) + v)
    if (b > 255) b = b - (b - 255)
    r = Number(r < 0 || isNaN(r)) ? 0 : (r > 255 ? 255 : r).toString(16)
    if (r.length == 1) r = '0' + r
    g = Number(g < 0 || isNaN(g)) ? 0 : (g > 255 ? 255 : g).toString(16)
    if (g.length == 1) g = '0' + g
    b = Number(b < 0 || isNaN(b)) ? 0 : (b > 255 ? 255 : b).toString(16)
    if (b.length == 1) b = '0' + b
    return '#' + r + g + b
}
// linear-gradient(to right,rgb(167, 40, 2),rgb(232, 182, 30),rgb(138, 210, 67),rgb(50,183,134))
const percentColors = [
    { pct: 0.0, color: { r: 0xa7, g: 0x28, b: 0x02 } },
    { pct: 0.25, color: { r: 0xe8, g: 0xb6, b: 0x1e } },
    { pct: 0.5, color: { r: 0x8a, g: 0xd2, b: 0x43 } },
    { pct: 1.0, color: { r: 0x32, g: 0xb7, b: 0x86 } },

    // { pct: 0.25, color: { r: 232, g: 182, b: 30 } },
    // { pct: 0.5, color: { r: 138, g: 210, b: 67 } },
    // { pct: 1.0, color: { r: 50, g: 183, b: 134 } }
]

const getScoreColor = pct => {
    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break
        }
    }
    var lower = percentColors[i - 1]
    var upper = percentColors[i]
    var range = upper.pct - lower.pct
    var rangePct = (pct - lower.pct) / range
    var pctLower = 1 - rangePct
    var pctUpper = rangePct
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    }
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')'
}

export { getTintedColor, getScoreColor }
