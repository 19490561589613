import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { routePaths, getLocalStorage } from '../../utils'
import { login, getUserProfile } from '../../api'

const DEFAULT_CONTEXT = {
    isAuthenticated: false,
    isLoading: true,
    error: null,
    login: () => {},
    logout: () => {},
    getProfile: () => {},
    user: null,
    test: () => {},
}

const UserContext = React.createContext(DEFAULT_CONTEXT)

class Provider extends Component {
    constructor() {
        super()
        this.state = {
            ...DEFAULT_CONTEXT,
            logout: this.logout,
            login: this.login,
            getProfile: this.getProfile,
            test: this.test,
        }
    }

    async componentDidMount() {
        const authToken = getLocalStorage('accessToken')
        if (authToken) {
            await this.getProfile()
        } else {
            this.setState({
                isLoading: false,
                isAuthenticated: false,
            })
        }
    }

    getProfile = async () => {
        try {
            const res = await getUserProfile()
            if (res.status === 200) {
                this.setState({
                    isLoading: false,
                    isAuthenticated: true,
                    user: res.data,
                    error: null,
                })
            } else {
                throw new Error({ message: res.data.message })
            }
        } catch (error) {
            this.setState(() => ({
                isLoading: false,
                isAuthenticated: false,
                error: error.message,
            }))
        }
    }

    login = async (email, password) => {
        this.setState({ isLoading: true })
        const res = await login({ email, password })
        if (res.status === 200) {
            this.setState({
                isLoading: false,
                isAuthenticated: true,
                error: null,
            })
        } else {
            this.setState({
                isLoading: false,
                isAuthenticated: false,
                error: res.data.error,
            })
        }
        return res
    }

    logout = () => {
        window.localStorage.clear()
        this.setState({ user: null, isAuthenticated: false })
        const { history } = this.props
        history.push(routePaths.signin)
    }

    render() {
        const { children } = this.props
        return <UserContext.Provider value={this.state}>{children}</UserContext.Provider>
    }
}

Provider.defaultProps = {
    children: {},
    history: {},
}

Provider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    history: PropTypes.object,
}

export default UserContext
export const UserConsumer = UserContext.Consumer
export const UserProvider = withRouter(Provider)
