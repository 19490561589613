import React, { Component } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { getScoreColor } from '../../utils'
import { ResultsResponse } from '../Results/ResultsDefinition'
import BionicScoreCircle, { COLOR_BENCHMARK } from '../Bionic/BionicScoreCircle'

const StyledBlock = styled.div`
    min-height: 150px;
    margin: 0 auto;
    max-width: 800px;
    box-shadow: 0px 0px 4px ${props => props.theme.black};
    border-radius: 3px;
    color: ${props => props.theme.white};
    position: relative;
    z-index: 2;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    background: ${props => props.theme.darkblueGradient};
    text-align: center;
    font-size: 1.3rem;
    overflow: hidden;

    .benchmark-header {
        width: 100%;
        padding: 15px;
        color: ${props => props.theme.white};
        padding-bottom: 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .inner {
        padding: 0 15px 15px 15px;
        max-width: 600px;
        margin: 0 auto;
        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .score {
            font-size: 2.5rem;
            border-radius: 100%;
            height: 100px;
            width: 100px;
            margin: 10px auto;
            line-height: 90px;
            border: 5px solid ${props => props.theme.white};
            font-weight: light;
        }

        .descriptor {
            margin: auto;
        }
    }

    @keyframes animateOpacity {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    .tablet-only {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        .mobile-only {
            display: none;
        }
        .tablet-only {
            display: inherit;
        }
        .benchmark-header {
            text-transform: uppercase;
            font-size: 24px;
            text-align: center;
        }
    }

    .bionic & {
        border-radius: 4px;
        box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.17);
        border: solid 1px #1d5aaa;
        background-image: radial-gradient(circle at 50% 0, #173458, #142331 123%);
        padding: 24px;

        .bionic-industry-title {
            font-size: 17px;
            font-weight: bold;
            text-align: left;
        }

        @media only screen and (max-width: 768px) {
            .bionic-industry-title {
                font-size: 13px;
            }
        }
    }
`

type Props = {
    results: ResultsResponse,
    bionic: boolean,
}

type State = {
    scrollPosition: number,
}

class BenchmarkTopBanner extends Component<Props, State> {
    state = {
        scrollPosition: 0,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll)
    }

    scroll = () => {
        const doc = document.documentElement
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        const { scrollPosition } = this.state
        if (scrollPosition > 200 && top > 200) {
            // don't change state
        } else if (scrollPosition < 200 && top > 200) {
            this.setState({ scrollPosition: 201 })
        } else if (scrollPosition > 200 && top < 201) {
            this.setState({ scrollPosition: 0 })
        }
    }

    render() {
        const { results, bionic } = this.props
        const { scrollPosition } = this.state
        const { industry } = results.profile
        let { results: { userScore = -1 } = {}, benchmark = null } = results

        const benchmarkScore = benchmark
            ? benchmark.benchmarkScore
            : 0
        const benchmarkScoreColor = benchmark
            ? getScoreColor(benchmark.benchmarkScore / 100)
            : 'white'
        const userScoreColor = userScore > 0 ? getScoreColor(userScore / 100) : 'white'
        return (
            <StyledBlock style={{ opacity: scrollPosition > 0 ? 0 : 1 }}>
                {bionic && (
                    <Grid container spacing={8} alignItems="center">
                        <Grid item xs={12} sm={8} className="bionic-industry-title">
                            BENCHMARK INDUSTRY: {industry.toUpperCase()}
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <BionicScoreCircle
                                score={userScore}
                                fontSize={17}
                                borderColor="#146fe7"
                                label="YOUR SCORE"
                                size={38}
                                screen="xs"
                            />
                            <BionicScoreCircle
                                score={userScore}
                                fontSize={49}
                                borderColor="#146fe7"
                                label="YOUR SCORE"
                                size={88}
                                screen="sm"
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <BionicScoreCircle
                                score={benchmarkScore}
                                fontSize={17}
                                borderColor={COLOR_BENCHMARK}
                                label="BENCHMARK"
                                size={38}
                                screen="xs"
                            />
                            <BionicScoreCircle
                                score={benchmarkScore}
                                fontSize={49}
                                borderColor={COLOR_BENCHMARK}
                                label="BENCHMARK"
                                size={88}
                                screen="sm"
                            />
                        </Grid>
                    </Grid>
                )}
                {!bionic && (
                    <>
                        <Grid container spacing={0} className="mobile-only">
                            <Grid item xs={12}>
                                <div className="benchmark-header">Industry: {industry}</div>
                            </Grid>
                        </Grid>
                        <div className="inner">
                            <Grid container spacing={8}>
                                <Grid item xs={6} sm={4}>
                                    <div
                                        className="score"
                                        style={{
                                            border: `6px solid ${userScoreColor}`,
                                            color: userScoreColor,
                                        }}
                                    >
                                        {userScore < 0 ? 'N/A' : userScore}
                                    </div>
                                    <div className="title">You</div>
                                    <div className="descriptor" style={{ color: 'white' }}>
                                        {results.results.descriptor.split(' ')[1] || 'Unavailable'}
                                    </div>
                                </Grid>
                                <Grid item sm={4} className="tablet-only">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            <div className="benchmark-header">
                                                Industry: {industry}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <div
                                        className="score"
                                        style={{
                                            border: `6px solid ${benchmarkScoreColor}`,
                                            color: benchmarkScoreColor,
                                        }}
                                    >
                                        {benchmark ? benchmark.benchmarkScore : 'N/A'}
                                    </div>
                                    <div className="title">Benchmark</div>
                                    <div className="descriptor">
                                        {benchmark
                                            ? benchmark.descriptor.split(' ')[1]
                                            : 'Unavailable'}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                )}
            </StyledBlock>
        )
    }
}

export default BenchmarkTopBanner
