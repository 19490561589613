import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faEnvelope } from '@fortawesome/pro-light-svg-icons'

import { getUserFromToken, confirmAccount } from '../../api'
import { StyledButton } from '../Styles'
import { Attribution } from '../GoogleRecaptcha'
import { FeedbackBarContentWrapper } from '../Feedback'
import { routePaths, TERMS_URI, RECAPTCHA_THRESHOLD } from '../../utils'
import { SimpleInput, CreatePassword } from '../FormElements'
import { MIN_PASSWORD_LENGTH, MIN_PASSWORD_STRENGTH } from '../../utils/config'

const StyledContainer = styled.div`
    position: relative;

    .form-container {
        max-width: 300px;
        margin: 0 auto;
        padding: 10px;

        p {
            padding-bottom: 10px;
        }
    }

    .terms-text {
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        color: #0a223c;
        margin: 10px;
        text-align: center;
    }

    .terms-link {
        color: #0a223c;
    }
    .feedback {
        margin: 0 auto;
        max-width: 350px;
    }

    .recaptcha-attribution {
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
    }

    @media only screen and (min-width: 768px) {
        .form-container {
            max-width: 350px;
        }
    }
`

class ConfirmForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            company: '',
            email: '',
            dialogOpen: false,
            feedbackMessage: null,
            passwordStrength: null,
        }
    }

    async componentDidMount() {
        const { token } = this.props
        const res = await getUserFromToken(token)
        const { givenName, familyName, email, company } = res.data
        this.setState({
            name: givenName ? `${givenName} ${familyName}` : 'unknown name',
            company: company ? company.name : 'company unknown',
            email: email || 'unknown email',
            token,
        })
    }

    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
            feedbackMessage: null,
        })
    }

    confirmAccount = async e => {
        e.preventDefault()
        const { password, confirmPassword, email, token, passwordStrength } = this.state
        const { captchaScore } = this.props
        if (captchaScore < RECAPTCHA_THRESHOLD) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text:
                        'We have detected suspicious activity from this web address. Please refresh and try again or check your network setup.',
                },
            })
            return
        }

        if (passwordStrength < MIN_PASSWORD_STRENGTH) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text:
                        'Please choose a password containing lower case and upper case letters, numbers and special characters.',
                },
            })
            return
        }

        if (!password || password.length < MIN_PASSWORD_LENGTH) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: `Please choose a password at least ${MIN_PASSWORD_LENGTH} characters.`,
                },
            })
            return
        }
        if (password !== confirmPassword) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'The passwords you enter must match.' },
            })
            return
        }

        const res = await confirmAccount({ resetToken: token, email, password })
        if (res.status === 200) {
            const { history } = this.props
            history.push({
                pathname: routePaths.home,
                state: 'first_login',
            })
        } else {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error processing your request. Please try again.',
                },
            })
        }
    }

    signIn = () => {
        this.setState({ dialogOpen: false })
        const { userConfirmed } = this.props
        userConfirmed()
    }

    passwordCreated = passwordObject => {
        const { newPassword, confirmPassword, passwordStrength } = passwordObject
        this.setState({
            confirmPassword,
            password: newPassword,
            passwordStrength,
            feedbackMessage: null,
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { name, company, email, feedbackMessage } = this.state
        const { captchaScore } = this.props
        return (
            <StyledContainer>
                {/* <HeaderLogo /> */}
                <form className="form-container" onSubmit={this.signin}>
                    <p>Profile data</p>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={name}
                        name="name"
                        placeholder="Name"
                        locked
                    >
                        <FontAwesomeIcon icon={faUser} />
                    </SimpleInput>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={company}
                        name="company"
                        placeholder="Company Name"
                        locked
                    >
                        <FontAwesomeIcon icon={faBuilding} />
                    </SimpleInput>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={email}
                        name="email"
                        placeholder="Email"
                        locked
                    >
                        <FontAwesomeIcon icon={faEnvelope} />
                    </SimpleInput>
                    <p>Choose a password</p>
                    <CreatePassword
                        returnPassword={this.passwordCreated}
                        requiredLength={MIN_PASSWORD_LENGTH}
                    />
                    <p className="terms-text">
                        By confirming your account you agree to the{' '}
                        <a
                            href={TERMS_URI}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="terms-link"
                        >
                            Terms and Conditions{' '}
                        </a>{' '}
                    </p>
                    {feedbackMessage ? (
                        <div className="feedback">
                            <FeedbackBarContentWrapper
                                message={feedbackMessage.text}
                                variant={feedbackMessage.type}
                                onClose={this.closeFeedbackMessage}
                            />
                            <br />
                        </div>
                    ) : (
                        <React.Fragment />
                    )}
                    <StyledButton
                        disabled={captchaScore < RECAPTCHA_THRESHOLD}
                        type="submit"
                        onClick={this.confirmAccount}
                        customClass={`narrow medium center ${
                            captchaScore < RECAPTCHA_THRESHOLD ? 'disabled' : ''
                        }`}
                    >
                        Confirm Account
                    </StyledButton>
                </form>
                <br />
                <div className="recaptcha-attribution">
                    <Attribution />
                </div>
                <br />
                <br />
            </StyledContainer>
        )
    }
}

ConfirmForm.defaultProps = {
    history: {},
    token: '',
    userConfirmed: () => {},
    captchaScore: 1,
}

ConfirmForm.propTypes = {
    history: PropTypes.object,
    token: PropTypes.string,
    userConfirmed: PropTypes.func,
    captchaScore: PropTypes.number,
}

export default ConfirmForm
