import React from 'react'

import packageContents from '../../../package.json'
import buildInfo from '../../REACT_APP_BUILD_INFO'

const style = {
    color: 'white',
    fontSize: 12,
    textAlign: 'center',
}

const dirtyBuildMarker = buildInfo.isWorkingDirectoryDirty ? '*' : ''
const appVersion = packageContents.version
const versionInfo = `${appVersion} / ${buildInfo.revisionId}${dirtyBuildMarker}`

function BuildInfo() {
    return (
        <div style={style}>
            <span>Version: {versionInfo}</span>
            <br />
            <span>Built on: {buildInfo.buildTime}</span>
        </div>
    )
}

export default BuildInfo
