import React from 'react'
import { Link } from 'react-router-dom'
import { Page } from '../components/Page'
import { HeaderLogo } from '../components/FormElements'

const UnauthenticatedPage = () => {
    return (
        <Page>
            <HeaderLogo />
            <div style={{ textAlign: 'center' }}>
                <div>You must sign in to do that.</div>
                <br />
                <Link to="/">Sign in</Link>
            </div>
        </Page>
    )
}

export default UnauthenticatedPage
