import {
    postApi,
    setLocalStorage,
    getApi,
    putApi,
    patchApi,
    BASE_URI,
    AUTH_URI,
    assertAuthentication,
} from '../utils'

const requestAccount = async user => {
    const res = await postApi(`${BASE_URI}/user/request`, user)
    return res
}

const resetPassword = async email => {
    const res = await postApi(`${BASE_URI}/user/password/reset?email=${email}`)
    return res
}

const getUserFromToken = async token => {
    const res = await getApi(`${BASE_URI}/user/password/reset?reset_token=${token}`)
    return res
}

const confirmAccount = async user => {
    const res = await postApi(`${BASE_URI}/user/password/setup`, user)
    if (res.status === 200) {
        setLocalStorage('accessToken', res.data.access_token)
    }
    return res
}

const login = async user => {
    const res = await postApi(`${AUTH_URI}/auth/login`, user)
    if (res && res.data && res.data.access_token) {
        setLocalStorage('accessToken', res.data.access_token)
    } else if (!res) {
        return {
            status: 400,
            data: {
                error: 'Unable to reach network',
                message: 'Unable to reach network',
            },
        }
    }
    return res
}

const authentication = async () => {
    const res = await getApi(`${BASE_URI}/user`)
    return res
}

const getUserProfile = async () => {
    const res = await getApi(`${BASE_URI}/user`)
    return res
}

const updatePassword = async emailAndPasswords => {
    const res = await putApi(`${BASE_URI}/user/password/update`, emailAndPasswords)
    return res
}

const deleteAccount = async userAndPassword => {
    const res = await patchApi(`${BASE_URI}/user`, {
        status: 'deletion_requested',
        password: userAndPassword.password,
    })
    return assertAuthentication(res)
}

const onboardingSeen = async () => {
    const res = await patchApi(`${BASE_URI}/user`, {
        onboardingSeen: true,
    })
    return assertAuthentication(res)
}

const termsConfirmed = async () => {
    const res = await patchApi(`${BASE_URI}/user`, {
        termsConfirmed: true,
    })
    return assertAuthentication(res)
}

const fetchHistory = async page => {
    const res = await getApi(`${BASE_URI}/user/history/${page}`)
    return assertAuthentication(res)
}

export {
    requestAccount,
    resetPassword,
    getUserFromToken,
    confirmAccount,
    login,
    authentication,
    getUserProfile,
    updatePassword,
    deleteAccount,
    onboardingSeen,
    termsConfirmed,
    fetchHistory,
}
