const BASE_URI = process.env.REACT_APP_API_URL
const AUTH_URI = process.env.REACT_APP_AUTH_URL
const GoogleSiteKey = process.env.REACT_APP_GOOGLE_SITE_KEY
const TERMS_URI = 'https://www.bcg.com/about/privacy-policy.aspx'
const RECAPTCHA_THRESHOLD = parseFloat(process.env.REACT_APP_GOOGLE_RECAPTCHA_THRESHOLD) || 0.5
const MIN_PASSWORD_LENGTH = 8
const MIN_PASSWORD_STRENGTH = 3
export {
    BASE_URI,
    AUTH_URI,
    GoogleSiteKey,
    TERMS_URI,
    RECAPTCHA_THRESHOLD,
    MIN_PASSWORD_LENGTH,
    MIN_PASSWORD_STRENGTH,
}
