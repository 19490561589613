import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Page } from '../components/Page'
import { StyledButton } from '../components/Styles'
import { FeedbackBarContentWrapper } from '../components/Feedback'
import CONTACTS from '../references/contacts'
import { getFeedbackTypes, postFeedback } from '../api'
import michael_grebe from '../assets/contacts/Grebe.png'
import patrick_rouvillois from '../assets/contacts/Patrick.png'
import ching_fong_ong from '../assets/contacts/ching_fong_ong.png'
import ganga_kannan from '../assets/contacts/ganga_kannan.png'
import { DropdownSelect } from '../components/FormElements'

const StyledPage = styled.div`
    position: relative;
    padding: 20px;
    background: linear-gradient(210deg, #eaeaea 30%, #9bb8ad 120%);
    min-height: 100vh;

    .section-title {
        font-size: 24px;
        padding: 0px 30px 10px 0px;
        color: ${props => props.theme.blue};
        border-bottom: 2px solid ${props => props.theme.blue};
        display: inline-block;
        margin-bottom: 15px;
    }

    .contact-image {
        border-radius: 100%;
        border: 2px solid ${props => props.theme.bcgGreen};
        margin: 0 auto;
    }

    .contact-address {
        margin-top: 15px;
        margin-bottom: 15px;

        a {
            margin-left: 5px;
        }
    }

    .contact-support {
        min-height: 400px;
    }

    .contact-item {
        padding: 15px 0px 15px 0px;
        max-width: 400px;
        .name,
        .title {
            padding-left: 5px;
        }
        .title {
            color: ${props => props.theme.bcgGreen};
        }
    }

    textarea {
        margin: 0px 0px 15px 0px;
        // width: 320px;
        width: 100%;
        height: 140px;
        border: none;
        border-radius: 0;
        font-size: 16px;
        padding: 5px;
    }

    @media only screen and (min-width: 768px) {
        .contact-image {
            width: 80px;
            height: 80px;
        }
    }

    .feedback {
        margin: 0 auto;
        max-width: 350px;
    }
`

class ContactPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedValue: [],
            textarea: '',
        }
    }

    async componentDidMount() {
        const res = await getFeedbackTypes()
        if (res.status === 200) {
            this.setState({
                selectionList: res.data,
                selectedValue: res.data[0],
            })
        }
    }

    selected = value => {
        this.setState({ selectedValue: value })
    }

    handleInputChange = e => {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value,
        })
    }

    submitToContact = async e => {
        e.preventDefault()
        const { selectedValue, textarea } = this.state
        if (!selectedValue) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'Please select a message type.',
                },
            })
            return
        }
        if (!textarea.length || textarea === '') {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'Please enter a comment, we look forward to hearing from you.',
                },
            })
            return
        }
        const res = await postFeedback({ type: selectedValue.value, message: textarea })
        if (res.status !== 201) {
            this.setState({
                textarea: '',
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error processing your request, pleast try again.',
                },
            })
        } else {
            this.setState({
                textarea: '',
                feedbackMessage: {
                    type: 'success',
                    text: 'Thank you for your message.',
                },
            })
        }
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { textarea, feedbackMessage, selectionList } = this.state
        const contacts = (
            <div className="contacts-list">
                {CONTACTS.contacts.map((c, ii) => (
                    <ContactItem contact={c} key={c.contactId} index={ii} />
                ))}
            </div>
        )
        return (
            <Page {...this.props} appbar background>
                <StyledPage>
                    <Grid container>
                        <Grid item md={2} />
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="section-title">DAI Team</div>
                            {contacts}
                            <p className="contact-address">
                                Reach out to:
                                <a href="mailto:DAI@bcg.com">DAI@bcg.com</a>
                            </p>
                            <br />
                            <br />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="contact-support">
                            <p className="section-title">Contact the support team</p>
                            <DropdownSelect
                                list={selectionList}
                                selected={this.selected}
                                placeholder="Select feedback type"
                            />
                            <textarea
                                type="text"
                                name="textarea"
                                onChange={this.handleInputChange}
                                value={textarea}
                            />
                            <StyledButton onClick={this.submitToContact} customClass="narrow">
                                Submit
                            </StyledButton>
                            <br />
                            {feedbackMessage ? (
                                <div className="feedback">
                                    <FeedbackBarContentWrapper
                                        message={feedbackMessage.text}
                                        variant={feedbackMessage.type}
                                        onClose={this.closeFeedbackMessage}
                                    />
                                    <br />
                                </div>
                            ) : (
                                <React.Fragment />
                            )}
                        </Grid>
                        <Grid item md={2} />
                    </Grid>
                </StyledPage>
            </Page>
        )
    }
}

export default ContactPage

const ContactItem = props => {
    const { contact, index } = props
    let imgUrl
    switch (index) {
        case 0:
            imgUrl = michael_grebe
            break
        case 1:
            imgUrl = patrick_rouvillois
            break
        case 2:
            imgUrl = ching_fong_ong
            break
        case 3:
            imgUrl = ganga_kannan
            break
        default:
            imgUrl = null
            break
    }
    const imgWidth = 70
    return (
        <Grid container spacing={0} className="contact-item" alignItems="center">
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                <img
                    className="contact-image"
                    src={imgUrl}
                    width={imgWidth}
                    height={imgWidth}
                    alt="contact"
                />
            </Grid>
            <Grid item xs={9}>
                <p className="name">{contact.name}</p>
                <p className="title">{contact.title}</p>
            </Grid>
        </Grid>
    )
}

ContactItem.defaultProps = {
    index: 0,
    contact: {},
}

ContactItem.propTypes = {
    index: PropTypes.number,
    contact: PropTypes.object,
}
