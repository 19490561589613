import { withStyles, WithStyles } from '@material-ui/core'
import React from 'react'
import bionicToggleOn from '../../assets/bionic/toggle-on.svg'
import bionicToggleOff from '../../assets/bionic/toggle-off.svg'
import bionicLogoOn from '../../assets/bionic/bionic_logo_on.png'
import bionicLogoOff from '../../assets/bionic/bionic_logo_off.png'
import Switch from '@material-ui/core/Switch'

export type BionicToggleProps = {
    bionic: boolean,
    setBionic: (bionic: boolean) => void,
}

type Props = WithStyles & BionicToggleProps

function BionicToggleRow({ bionic, setBionic, classes }: React.PropsWithChildren<Props>) {
    return (
        <div className={classes.root}>
            <img src={bionic ? bionicLogoOn : bionicLogoOff} alt="Bionic Logo" className={classes.logo} />
            <Switch
                checked={bionic}
                onChange={() => setBionic(!bionic)}
                icon={<img alt="Bionic ON" src={bionicToggleOff} />}
                checkedIcon={<img alt="Bionic OFF" src={bionicToggleOn} />}
                classes={{
                    switchBase: classes.colorSwitchBase,
                    checked: classes.colorChecked,
                    bar: classes.colorBar,
                }}
            />
            {bionic ? (
                <span className={classes.labelOn}>ON</span>
            ) : (
                <span className={classes.labelOff}>OFF</span>
            )}
        </div>
    )
}

const styles = {
    root: {
        fontSize: 16,
        fontFamily: 'Proxima Nova',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    labelOn: {
        color: '#fff',
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
    labelOff: {
        color: '#424b5a',
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
    logo: {
        '@media only screen and (max-width: 768px)': {
            maxHeight: 18,
        },
    },

    /* This is taken from examples in the docs and I have no idea what is going on here */
    colorSwitchBase: {
        color: '#c8cfcc',
        '& + $colorBar': {
            backgroundColor: '#c8cfcc',
            opacity: 1,
        },
        '&$colorChecked': {
            color: '#424b5a',
            '& + $colorBar': {
                backgroundColor: '#424b5a',
            },
        },
    },
    colorBar: {},
    colorChecked: {},
}

export default withStyles(styles)(BionicToggleRow)
