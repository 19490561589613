import React from 'react'
import { Block, ResultsResponse } from '../Results/ResultsDefinition'
import BionicWheel from '../Bionic/BionicWheel'
import BenchmarksBionicModal from './BenchmarksBionicModal'
import BionicScoreCircle, { COLOR_BENCHMARK, COLOR_SCORE } from '../Bionic/BionicScoreCircle'

type Props = {
    results: ResultsResponse
}

export default function BenchmarksBionicWheel({ results }: React.PropsWithChildren<Props>) {
    return (
        <BionicWheel
            results={results}
            renderScore={(block: Block) => (
                <>
                    <BionicScoreCircle
                        fontSize={11}
                        size={20}
                        score={block.preresults.userScore}
                        label=""
                        borderColor={COLOR_SCORE}
                        fontWeight="normal"
                        screen="xs"
                    />
                    <BionicScoreCircle
                        fontSize={15}
                        size={30}
                        score={block.preresults.userScore}
                        label=""
                        borderColor={COLOR_SCORE}
                        fontWeight="normal"
                        screen="sm"
                    />
                    &nbsp;
                    <BionicScoreCircle
                        fontSize={11}
                        size={20}
                        score={block.benchmark.benchmarkScore}
                        label=""
                        borderColor={COLOR_BENCHMARK}
                        fontWeight="normal"
                        screen="xs"
                    />
                    <BionicScoreCircle
                        fontSize={15}
                        size={30}
                        score={block.benchmark.benchmarkScore}
                        label=""
                        borderColor={COLOR_BENCHMARK}
                        fontWeight="normal"
                        screen="sm"
                    />
                </>
            )}
            renderModal={(block, onClose) => (
                <BenchmarksBionicModal block={block} onClose={onClose} />
            )}
        />
    )
}
