import React, { Component } from 'react'
import CopyContext from '../../contexts/Copy'

class LanguageSwitch extends Component {
    static contextType = CopyContext
    switchLanguage = () => {
        const { switchLanguage } = this.context
        switchLanguage()
    }
    render() {
        return <div onClick={this.switchLanguage}> switch languages </div>
    }
}

export default LanguageSwitch
