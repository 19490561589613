import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core'

export const COLOR_SCORE = '#146fe7'
export const COLOR_TARGET = '#4ebd6c'
export const COLOR_BENCHMARK = '#ddb63c'

type Props = {
    score: number,
    label: string,
    fontSize: number,
    size: number,
    borderColor: string,
    fontWeight?:
        | number
        | 'inherit'
        | 'initial'
        | '-moz-initial'
        | 'revert'
        | 'unset'
        | 'normal'
        | 'bold'
        | 'bolder'
        | 'lighter',
    screen?: ('xs' | 'sm'),
} & WithStyles

function BionicScoreCircle({
    score,
    label,
    borderColor,
    classes,
    fontSize,
    size,
    fontWeight,
    screen,
}: Props) {
    return (
        <div
            className={`${classes.root} ${screen === 'xs' ? classes.xsOnly : ''} ${
                screen === 'sm' ? classes.smOnly : ''
            }`}
            style={{
                paddingBottom: !!label ? 21 : undefined,
            }}
        >
            <div
                className={classes.circle}
                style={{
                    borderColor,
                    fontSize,
                    width: size,
                    height: size,
                    fontWeight: fontWeight || 300,
                }}
            >
                {Math.round(score)}
            </div>
            {label && <div className={classes.label}>{label}</div>}
        </div>
    )
}

const styles = {
    root: {
        display: 'inline-block',
        textAlign: 'center',
        position: 'relative',
    },
    xsOnly: {
        '@media only screen and (min-width: 768px)': {
            display: 'none',
        },
    },
    smOnly: {
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
    circle: {
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: 2,
        fontFamily: 'Kelson',
        lineHeight: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        letterSpacing: -1,
    },
    label: {
        fontSize: 13,
        marginTop: 8,
        lineHeight: 1,
        whiteSpace: 'nowrap',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        fontFamily: 'Proxima Nova',
        '@media only screen and (max-width: 768px)': {
            fontSize: 11,
        },
    },
}

// @ts-ignore
export default withStyles(styles)(BionicScoreCircle)
