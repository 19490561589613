import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { Redirect } from 'react-router'
import * as qs from 'query-string'
import { scrollTo, routePaths, GoogleSiteKey, getBrowser } from '../utils'
import { ReCaptcha } from '../components/GoogleRecaptcha'
import { Page } from '../components/Page'
import { SigninForm, RequestForm, ConfirmForm } from '../components/SigninPageForms'
import UserContext from '../contexts/User'
import { sendToken } from '../api'
import { HeaderLogo } from '../components/FormElements'
import { StyledBackground } from '../components/Styles'
import { FeedbackBar, FeedbackBarContentWrapper } from '../components/Feedback'
import { CopyConsumer } from '../contexts/Copy'

const StyledPage = styled.div`
    position: relative;

    .slide-page {
        min-height: 100vh;
    }
    .image-wrapper {
        width: 300px;
        margin: 0px auto;
        img {
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .image-wrapper {
            width: 350px;
        }
        .header {
            width: 330px;
        }
    }

    .update-browser {
        font-size: 26px;
        padding: 30px;
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
    }
`

class SigninPage extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            upgradeBrowser: null,
            index: null,
        }
    }

    componentDidMount() {
        // check the browser type
        // if MSIE and < 10, display upgrade message
        const browser = getBrowser()
        if (browser.name === 'MSIE' && parseInt(browser.version, 10) < 10) {
            this.setState({
                upgradeBrowser: {
                    message:
                        'This application offers the best experience with versions of IE greater than 9. Please update or switch browsers.',
                },
            })
        }
        this.setForm()
        window.onpopstate = () => {
            this.setForm()
        }
    }

    componentWillUnmount() {
        window.onpopstate = () => {}
        this.unmounted = true
    }

    setForm() {
        const { history } = this.props
        const search = qs.parse(history.location.search)
        let feedbackMessage = null
        if (search.expired) {
            feedbackMessage = {
                type: 'error',
                text: 'Your session has expired, please login again.',
            }
        }
        if (search.request) {
            history.push(`${routePaths.signin}?request=1`)
            this.setState({ index: 1, recaptchaAction: 'request_account' })
        } else if (search.confirm) {
            const { token } = search
            history.push(`${routePaths.signin}?confirm=1&token=${token}`)
            this.setState({ index: 2, token, recaptchaAction: 'confirm_account' })
        } else {
            history.push(`${routePaths.signin}`)
            this.setState({ index: 0, recaptchaAction: 'login', feedbackMessage })
        }
    }

    signedIn = user => {
        const location = routePaths.home
        const { authenticated, history } = this.props
        authenticated(user)
        history.push(location)
    }

    requestAccount = () => {
        this.nav(`${routePaths.signin}?request=1`)
        this.setState({
            index: 1,
            recaptchaAction: 'request_account',
        })
    }

    backToLogin = () => {
        this.nav(`${routePaths.signin}`)
        this.setState({
            index: 0,
            recaptchaAction: 'login',
        })
    }

    userConfirmed = () => {
        this.nav(`${routePaths.signin}`)
        this.setState({
            index: 0,
        })
    }

    nav = route => {
        scrollTo(0, 0)
        const { history } = this.props
        history.push(route)
    }

    signin = () => {
        const { history } = this.props
        history.push(routePaths.home)
    }

    verifyCallback = async recaptchaToken => {
        const res = await sendToken(recaptchaToken)
        if (res && res.status === 200) {
            if (this.unmounted) {
                return
            }
            this.setState({
                score: res.data.score,
            })
        }
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { index, token, score, recaptchaAction, upgradeBrowser, feedbackMessage } = this.state

        // check the browser
        // if ie and version less than 10 display upgrade prompt
        if (upgradeBrowser) {
            return (
                <StyledPage>
                    <HeaderLogo />
                    <div className="update-browser">{upgradeBrowser.message}</div>
                </StyledPage>
            )
        }

        // check if user is authenticated on ever render
        // if so, redirect to homepage
        const { isAuthenticated } = this.context
        if (isAuthenticated) {
            return <Redirect to={{ pathname: routePaths.home, state: 'none' }} />
        }
        if (index === null) {
            return <React.Fragment />
        }
        return (
            <Page appbar={false} page="signin">
                <ReCaptcha
                    sitekey={GoogleSiteKey}
                    action={recaptchaAction}
                    verifyCallback={this.verifyCallback}
                />
                <CopyConsumer>
                    {({ copy }) => {
                        return (
                            <StyledPage>
                                <StyledBackground />
                                <SwipeableViews
                                    disabled
                                    index={index}
                                    onChangeIndex={this.handleChangeIndex}
                                    style={{
                                        root: { height: '100vh' },
                                        slide: { minHeight: '100vh' },
                                    }}
                                >
                                    <div className="slide-page">
                                        <HeaderLogo text={copy.login.header} />
                                        <SigninForm
                                            signin={this.signin}
                                            signedIn={this.signedIn}
                                            requestAccount={this.requestAccount}
                                            captchaScore={score}
                                        />
                                    </div>
                                    <div className="slide-page">
                                        <HeaderLogo text={copy.request.header} />
                                        <RequestForm
                                            signedIn={this.signedIn}
                                            backToLogin={this.backToLogin}
                                            nav={this.nav}
                                            captchaScore={score}
                                        />
                                    </div>
                                    <div className="slide-page">
                                        {token && (
                                            <React.Fragment>
                                                <HeaderLogo text={copy.confirm.header} />
                                                <ConfirmForm
                                                    token={token}
                                                    signedIn={this.signedIn}
                                                    goToLogin={this.backToLogin}
                                                    userConfirmed={this.userConfirmed}
                                                    captchaScore={score}
                                                    {...this.props}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </SwipeableViews>
                            </StyledPage>
                        )
                    }}
                </CopyConsumer>

                {feedbackMessage ? (
                    <FeedbackBar open={!!feedbackMessage}>
                        <FeedbackBarContentWrapper
                            message={feedbackMessage.text}
                            variant={feedbackMessage.type}
                            onClose={this.closeFeedbackMessage}
                        />
                    </FeedbackBar>
                ) : (
                    <React.Fragment />
                )}
            </Page>
        )
    }
}

SigninPage.defaultProps = {
    authenticated: () => {},
    history: {},
}

SigninPage.propTypes = {
    authenticated: PropTypes.func,
    history: PropTypes.object,
}

export default SigninPage
