import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledContainer = styled.div`
    padding-bottom: 5px;

    .bar-title {
        margin-bottom: 2px;
        font-size: 14px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .bar-wrapper-outer {
        width: 80%;
        display: inline-block;
        vertical-align: top;

        .bar-wrapper-inner {
            width: 100%;
            height: 15px;
            position: relative;

            .bar {
                width: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(
                    to right,
                    rgb(167, 40, 2),
                    rgb(232, 182, 30),
                    rgb(138, 210, 67),
                    rgb(50, 183, 134)
                );
                -webkit-transition: width 2s;
                transition: width 2s;
            }

            .bar-overlay {
                position: absolute;
                top: -1px;
                bottom: -1px;
                width: 0;
                right: -1px;
                margin-right: -1px;
                float: right;
                background: linear-gradient(to left, #e8e8e8, #e8e8e8, #e8e8e8, #e8e8e8);
                -webkit-transition: width 1.5s ease-in-out;
                -moz-transition: width 1.5s ease-in-out;
                -o-transition: width 1.5s ease-in-out;
                transition: width 1.5s ease-in-out;
                transition-delay: 0.7s;
            }
        }
    }

    .number {
        top: -1px;
        left: 5px;
        font-size: 1rem;
        position: absolute;
        -webkit-transition: opacity 2s ease-in-out;
        transition: opacity 2s ease-in-out;
        transition-delay: 1s;
    }

    @media only screen and (min-width: 767px) {
    }

    @media only screen and (max-width: 321px) {
        .bar-title {
            font-size: 12px;
        }
    }
`

class BarGraph extends Component {
    state = {
        full: 100,
        showNumber: false,
        overlayFill: 101,
    }

    componentDidMount() {
        const { fill } = this.props
        this.overlayFill = setTimeout(() => {
            this.setState({ overlayFill: 100 - fill })
        })
        this.showNumber = setTimeout(() => {
            this.setState({ showNumber: true })
        }, 800)
    }

    componentWillUnmount() {
        if (this.overlayFill) {
            clearTimeout(this.overlayFill)
            this.overlayFill = 0
        }
        if (this.fill) {
            clearTimeout(this.fill)
            this.fill = 0
        }
        if (this.showNumber) {
            clearTimeout(this.showNumber)
            this.showNumber = 0
        }
    }

    fill = () => {
        const fill = setTimeout(() => {
            this.setState({ full: 100 })
        }, 100)
        return fill
    }

    showNumber = () => {
        const timeout = setTimeout(() => {
            this.setState({ showNumber: true })
        }, 800)
        return timeout
    }

    render() {
        const { title, fill } = this.props
        const { full, showNumber, overlayFill } = this.state
        return (
            <StyledContainer>
                <div className="bar-title">{title}</div>
                <div className="bar-wrapper-outer">
                    <div className="bar-wrapper-inner">
                        <div className="bar" style={{ width: `${full}%` }} />
                        <div className="bar-overlay" style={{ width: `${overlayFill}%` }}>
                            <div className="number" style={{ opacity: showNumber ? 1 : 0 }}>
                                {fill}
                            </div>
                        </div>
                    </div>
                </div>
            </StyledContainer>
        )
    }
}

BarGraph.defaultProps = {
    fill: 0,
    title: '',
}

BarGraph.propTypes = {
    fill: PropTypes.number,
    title: PropTypes.string,
}

export default BarGraph
