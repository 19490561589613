import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getTintedColor } from '../../utils'

const StyledContainer = styled.div`
    .bar-title {
        margin-bottom: 2px;
        font-size: 1rem;
    }

    .bar-wrapper-outer {
        width: calc(100%);
        display: inline-block;
        vertical-align: top;

        .bar-wrapper-inner {
            width: 100%;
            height: 10px;
            position: relative;

            .bar {
                width: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                border: 1px solid #d1d1d1;
                -webkit-transition: width 2s;
                transition: width 2s;
            }

            .bar-overlay {
                border: 1px solid #d1d1d1;
                border-left: none;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 0;
                margin-right: -1px;
                background: ${props => props.theme.lightgrey};
                -webkit-transition: width 0.5s ease-in-out;
                -moz-transition: width 0.5s ease-in-out;
                -o-transition: width 0.5s ease-in-out;
                transition: width 0.5s ease-in-out;
                transition-delay: 0.2s;
            }

            .left {
                left: -1px;
                border-right: none;
                margin-right: -1px;
                border-left: 1px solid #d1d1d1;
            }
            .right {
                right: 0;
            }
        }
    }

    .number {
        top: -1px;
        left: 5px;
        font-size: 1rem;
        position: absolute;
        -webkit-transition: opacity 1.5s ease-in-out;
        transition: opacity 1.5s ease-in-out;
        transition-delay: 0.5s;
    }
`

const ResultBar = props => {
    const [full, setFull] = useState(100)
    const [overlayFill, setOverlayFill] = useState('100%')
    const { fill, left, color } = props

    useEffect(() => {
        setOverlayFill(`${100 - fill}%`)
        const fillBar = setTimeout(() => {
            setFull(100)
        }, 100)

        return () => {
            clearTimeout(fillBar)
        }
    }, [fill, left, color, setOverlayFill])

    const directionColor = left
        ? 'linear-gradient(to left,rgb(167, 40, 2),rgb(232, 182, 30),rgb(138, 210, 67),rgb(50,183,134))'
        : 'linear-gradient(to right,rgb(167, 40, 2),rgb(232, 182, 30),rgb(138, 210, 67),rgb(50,183,134))'
    const directionOverlay = left ? 'left' : 'right'
    const fillColor = color ? getTintedColor(color, -20) : directionColor
    return (
        <StyledContainer>
            {typeof fill === 'number' ? (
                <div className="bar-wrapper-outer">
                    <div className="bar-wrapper-inner">
                        <div
                            className="bar"
                            style={{ width: `calc(${full}%)`, background: fillColor }}
                        />
                        <div
                            className={`bar-overlay ${directionOverlay}`}
                            style={{ width: `${overlayFill}` }}
                        />
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    {directionOverlay === 'right' ? (
                        <div style={{ textAlign: 'right' }}>Benchmark unavailable</div>
                    ) : (
                        <div>Result unavailable</div>
                    )}
                </React.Fragment>
            )}
        </StyledContainer>
    )
}

ResultBar.defaultProps = {
    fill: 0,
}

ResultBar.propTypes = {
    fill: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ResultBar
