import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledContainer = styled.div`
    min-height: 100px;
    margin: 10px auto;
    margin-bottom: 0;
    background-color: ${props => props.theme.lightgrey};
    box-shadow: 0px 0px 4px ${props => props.theme.darkblue};
    border-radius: 3px;
    padding: 10px;
    position: relative;
    z-index: 2;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    padding: 10px;

    .title {
        font-weight: bold;
        font-size: 24px;
    }
    .text {
        margin-top: 5px;
    }
`

const WelcomeMsg = props => {
    const { title, text } = props
    return (
        <StyledContainer>
            <div className="title">{title}</div>
            <br />
            <div className="text">{text}</div>
        </StyledContainer>
    )
}

WelcomeMsg.defaultProps = {
    title: '',
    text: '',
}

WelcomeMsg.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
}

export default WelcomeMsg
