import React from 'react'
import { Block, ResultsResponse } from './ResultsDefinition'
import BionicWheel from '../Bionic/BionicWheel'
import ResultsBionicModal from './ResultsBionicModal'
import BionicScoreCircle, { COLOR_SCORE, COLOR_TARGET } from '../Bionic/BionicScoreCircle'

type Props = {
    results: ResultsResponse
}

export default function ResultsBionicWheel({ results }: React.PropsWithChildren<Props>) {
    return (
        <BionicWheel
            results={results}
            renderScore={(block: Block) => (
                <>
                    <BionicScoreCircle
                        fontSize={11}
                        size={20}
                        score={block.preresults.userScore}
                        label=""
                        borderColor={COLOR_SCORE}
                        fontWeight="normal"
                        screen="xs"
                    />
                    <BionicScoreCircle
                        fontSize={15}
                        size={30}
                        score={block.preresults.userScore}
                        label=""
                        borderColor={COLOR_SCORE}
                        fontWeight="normal"
                        screen="sm"
                    />
                    &nbsp;
                    <BionicScoreCircle
                        fontSize={11}
                        size={20}
                        score={block.ambition.ambitionScore}
                        label=""
                        borderColor={COLOR_TARGET}
                        fontWeight="normal"
                        screen="xs"
                    />
                    <BionicScoreCircle
                        fontSize={15}
                        size={30}
                        score={block.ambition.ambitionScore}
                        label=""
                        borderColor={COLOR_TARGET}
                        fontWeight="normal"
                        screen="sm"
                    />
                </>
            )}
            renderModal={(block, onClose) => <ResultsBionicModal block={block} onClose={onClose} />}
        />
    )
}
