/* eslint-disable no-undef */
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getScoreColor } from '../../utils'

const AmbitionGapBarStyled = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 10px;
    /* border: 1px solid ${props => props.theme.lightgrey}; */

    .ambition-description {
        background: #979FA7;
        padding: 10px;
        position: absolute;
        top: -60px;
        width: 140px;
        color: ${props => props.theme.white};
        border-radius: 3px;
        text-align: center;
        box-shadow: ${props => props.theme.shadowGrey}
    }

    .shift-left {
        margin-left: -120px;

        .arrow  {
            right: 0
        }
    }

    .shift-right {
        margin-left: -20px;
        .arrow {
            left: 0;
        }
    }

    .ambition-label {
        font-size: 16px;
    }

    .arrow {
            position: absolute;
            bottom: -9px;
            content: '';
            border-top: 10px solid #979FA7;
            border-right: 20px solid transparent;
            border-left: 20px solid transparent;
            margin: 0 auto;
            width: 15px;
            -webkit-transform: translate3d(0, 0, 0);
            -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
            transform: translate3d(0, 0, 0);
            filter: drop-shadow(0px 2px 2px #979FA7);
    }

    .gap {
        position: absolute;
        top: 2.5px;
        bottom: 2.5px;
        width: 0;
        -webkit-transition: width 4s ease-in-out;
        -moz-transition: width 4s ease-in-out;
        -o-transition: width 4s ease-in-out;
        transition: width 4s ease-in-out;
        transition-delay: 4s;
        background-color: gray;
        background: -webkit-linear-gradient(50deg, #979FA7, rgb(255, 255, 255), #979FA7, rgb(255, 255, 255),#979FA7);
        background: linear-gradient(50deg,rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139));
    }

    .ambition-circle {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        top: -6px;
    }
`
const ambitionThresholdsToLabels = [
    { threshold: 75, label: 'Leader' },
    { threshold: 50, label: 'Performer' },
    { threshold: 25, label: 'Literate' },
    { threshold: 0, label: 'Starter' },
    { threshold: Number.MIN_SAFE_INTEGER, label: 'unknown' },
]
const findLabel = ambitionValue =>
    ambitionThresholdsToLabels.find(item => item.threshold <= ambitionValue).label

class AmbitionGapBar extends Component {
    render() {
        const { ambition, score, open, color } = this.props
        let ambitionColor = getScoreColor(ambition / 100)
        let ambitionLabel = findLabel(ambition)
        // if ambition is N/A
        if (ambition === 1) {
            ambitionLabel = 'N/A'
            ambitionColor = '#ccc'
        }
        return (
            <AmbitionGapBarStyled>
                {open && (
                    <React.Fragment>
                        <div
                            className={`ambition-description ${
                                ambition > 50 ? 'shift-left' : 'shift-right'
                            }`}
                            style={{
                                left: `${ambition}%`,
                            }}
                        >
                            <span className="ambition-label">{`Goal: ${ambitionLabel}`}</span>
                            <div className="arrow" />
                        </div>
                        {ambition - score > 0 && (
                            <div
                                className="gap"
                                style={{
                                    left: `${score}%`,
                                    width: open ? `${ambition - score}%` : '0',
                                }}
                            />
                        )}
                    </React.Fragment>
                )}
                <div
                    className="ambition-circle"
                    style={{
                        background: open ? color : ambitionColor,
                        left: `calc(${ambition}% - 10px)`,
                        border: open ? '2px solid #979FA7' : 'none',
                    }}
                    onClick={this.showAmbitionDetails}
                />
            </AmbitionGapBarStyled>
        )
    }
}

AmbitionGapBar.defaultProps = {
    ambition: 0,
    score: 0,
    open: false,
}

AmbitionGapBar.propTypes = {
    ambition: PropTypes.number,
    score: PropTypes.number,
    open: PropTypes.bool,
}

export default AmbitionGapBar
