import axios from 'axios'
import { getLocalStorage, clearLocalStorage } from './local'
import routePaths from './routePaths'

const authExemptPages = ['/']

const assertAuthentication = res => {
    if (res.status === 401) {
        const { location } = window
        const { pathname } = location
        const exempt = authExemptPages.filter(p => p === pathname)
        if (exempt.length < 1) {
            clearLocalStorage()
            window.location.href = `${routePaths.signin}?expired=1`
        }
    }
    return res
}

const getApi = async (apiRoute, params) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${apiRoute}`,
            params,
            headers: {
                Authorization: `Bearer ${getLocalStorage('accessToken')}`,
            },
        })
        return response
    } catch (error) {
        return error.response
    }
}

const deleteApi = async apiRoute => {
    try {
        const response = await axios({
            method: 'delete',
            url: `${apiRoute}`,
            headers: {
                Authorization: `Bearer ${getLocalStorage('accessToken')}`,
            },
        })
        return response
    } catch (error) {
        return error.response
    }
}

const postApi = async (apiRoute, data) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${apiRoute}`,
            data,
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${getLocalStorage('accessToken')}`,
            },
        })
        return response
    } catch (error) {
        return error.response
    }
}

const putApi = async (apiRoute, data) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${apiRoute}`,
            data,
            headers: {
                Authorization: `Bearer ${getLocalStorage('accessToken')}`,
            },
        })
        return response
    } catch (error) {
        return error.response
    }
}

const patchApi = async (apiRoute, data) => {
    try {
        const response = await axios({
            method: 'patch',
            url: `${apiRoute}`,
            data,
            headers: {
                Authorization: `Bearer ${getLocalStorage('accessToken')}`,
            },
        })
        return response
    } catch (error) {
        return error.response
    }
}

export { getApi, deleteApi, postApi, putApi, assertAuthentication, patchApi }
