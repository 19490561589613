import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import styled from 'styled-components'
import { CardBody } from '../../Cards'

const StyledContainer = styled.div`
    margin-top: 30px;
`

class QuestionOption extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: this.props.option.selected || '',
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            checked: newProps.option.selected || '',
        })
    }

    select = e => {
        e.preventDefault()
        const { option, select } = this.props
        const selectedOption = option
        if (option.selected) {
            option.deselect = true
        }
        select(selectedOption)
    }

    render() {
        const { option, index } = this.props
        const { checked } = this.state
        return (
            <form>
                {option.text === 'NA' ? (
                    <div style={{ marginTop: '15px' }}>
                        <Switch checked={checked && checked !== ''} onChange={this.select} />
                        <span>Skip this question.</span>
                    </div>
                ) : (
                    <StyledContainer onClick={this.select}>
                        <CardBody selected={option.selected} index={index} hoverable>
                            <div dangerouslySetInnerHTML={{ __html: `${option.text}` }} />
                        </CardBody>
                    </StyledContainer>
                )}
            </form>
        )
    }
}

QuestionOption.defaultProps = {
    option: {},
    select: () => {},
    index: 0,
}

QuestionOption.propTypes = {
    option: PropTypes.object,
    select: PropTypes.func,
    index: PropTypes.number,
}

export default QuestionOption
