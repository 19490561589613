import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import COPY from '../../references/copy.json'
import COPY_LOREM from '../../references/copy_lorem.json'

const DEFAULT_CONTEXT = {
    copy: COPY,
    switchLanguage: () => {},
}

const CopyContext = React.createContext(DEFAULT_CONTEXT)

class Provider extends Component {
    constructor() {
        super()
        this.state = {
            ...DEFAULT_CONTEXT,
            switchLanguage: this.switchLanguage,
        }
    }

    switchLanguage = () => {
        this.setState({
            copy: COPY_LOREM,
        })
    }

    render() {
        const { children } = this.props
        return <CopyContext.Provider value={this.state}>{children}</CopyContext.Provider>
    }
}

Provider.defaultProps = {
    children: {},
}

Provider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default CopyContext
export const CopyConsumer = CopyContext.Consumer
export const CopyProvider = withRouter(Provider)
