import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'
import { mod } from 'react-swipeable-views-core'
import { ButtonBase } from '@material-ui/core'
import CarouselDots from '../Home/CarouselDots'
import OnboardingSlide from './OnboardingCard'
import OnboardingPWACard from './OnboardingPWACard'

const VirtAutoPlaySwipeableViews = virtualize(SwipeableViews)

const styles = {
    root: {
        padding: 0,
        position: 'relative',
    },
}

class OnboardingCarousel extends React.Component {
    state = {
        index: 0,
    }

    componentDidMount() {
        this.resize()
        this.displayPWA()
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        const sliderType = window.innerWidth > 700 ? 'slider-big' : 'slider-small'
        this.setState({
            sliderType,
        })
    }

    displayPWA = () => {
        if (this.isIos() && this.isInStandaloneMode) {
            this.setState({
                displayPWA: true,
            })
        } else {
            this.setState({
                displayPWA: false,
            })
        }
    }

    isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test(userAgent)
    }

    isInStandaloneMode = () => {
        return 'standalone' in window.navigator && window.navigator.standalone
    }

    handleChangeIndex = index => {
        this.setState({
            index,
        })
    }

    navToDownload = () => {
        this.setState({
            index: 4,
        })
    }

    slideRenderer = params => {
        const { index, key } = params
        switch (mod(index, 5)) {
            case 0:
                return (
                    <div key={key}>
                        <OnboardingSlide index={0} />
                    </div>
                )

            case 1:
                return (
                    <div key={key}>
                        <OnboardingSlide index={1} />
                    </div>
                )

            case 2:
                return (
                    <div key={key}>
                        <OnboardingSlide index={2} />
                    </div>
                )

            case 3:
                return (
                    <div key={key}>
                        <OnboardingSlide index={3} />
                    </div>
                )

            case 4:
                return (
                    <div key={key}>
                        <OnboardingPWACard />
                    </div>
                )

            default:
                return null
        }
    }

    render() {
        const { index, sliderType, displayPWA } = this.state

        if (!sliderType) {
            return <React.Fragment />
        }

        return (
            <div style={styles.root}>
                <VirtAutoPlaySwipeableViews
                    enableMouseEvents
                    index={index}
                    slideRenderer={this.slideRenderer}
                    interval={6000}
                    slideCount={displayPWA ? 5 : 4}
                    onChangeIndex={this.handleChangeIndex}
                />
                <div>
                    <br />
                    <CarouselDots
                        dots={displayPWA ? 5 : 4}
                        index={index}
                        onChangeIndex={this.handleChangeIndex}
                    />
                </div>
                <br />
                {displayPWA ? (
                    <ButtonBase
                        style={{ textDecoration: 'underline' }}
                        onClick={this.navToDownload}
                    >
                        How to download app
                    </ButtonBase>
                ) : (
                    <React.Fragment />
                )}
            </div>
        )
    }
}

export default OnboardingCarousel
