import React, { Component } from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router'
import { routePaths } from '../utils'
import { Page } from '../components/Page'

const StyledPage = styled.div``

class NotFoundPage extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <Redirect to={{ pathname: routePaths.home, state: 'none' }} />

        // return (
        //     <Page>
        //       <StyledPage>
        //         <div>The page you are looking for does not exist.</div>
        //       </StyledPage>
        //     </Page>
        // );
    }
}

export default NotFoundPage
