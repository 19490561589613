import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledBar = styled.div`
    .bar {
        width: 160px;
        margin: 0 auto;
        position: relative;
        background: linear-gradient(
            to right,
            rgb(167, 40, 2),
            rgb(232, 182, 30),
            rgb(138, 210, 67),
            rgb(50, 183, 134)
        );
        height: 15px;
    }

    .ranges {
        width: 160px;
        position: relative;
        margin: 0 auto;

        .range {
            position: absolute;
            text-align: center;
            width: 100px;
            margin-top: 5px;
            color: ${props => props.theme.textGrey};
        }

        .range:nth-of-type(1) {
            left: 0;
            margin-left: -60px;
        }
        .range:nth-of-type(2) {
            right: 0;
            margin-right: -60px;
        }
    }
`

const StyledPosition = styled.div`
    position: absolute;
    left: 0;
    right: 0;

    input {
        &:focus {
            outline: none;
        }
        cursor: pointer;
    }
    .position {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: white;
        top: -3px;
        border: 2px solid ${props => props.theme.grey};
    }

    .position-1 {
        left: -10px;
    }

    .position-2 {
        left: calc(33% - 10px);
    }

    .position-3 {
        left: calc(66% - 10px);
    }

    .position-4 {
        left: calc(100% - 10px);
    }

    .position-1.active {
        background: rgb(167, 40, 2);
    }

    .position-2.active {
        background: rgb(232, 182, 30);
    }

    .position-3.active {
        background: rgb(138, 210, 67);
    }

    .position-4.active {
        background: rgb(50, 183, 134);
    }
`

const positions = [1, 2, 3, 4]
const valueIndex = [0, 33, 66, 100]
class AmbitionBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentIndex: 0,
        }
    }

    selectPosition = e => {
        const { target } = e
        const { name } = target
        let { currentIndex } = this.state
        if (currentIndex === 1 && parseInt(name) === 1) {
            currentIndex = 0
        } else {
            currentIndex = parseInt(name)
        }
        this.setState(
            {
                currentIndex,
            },
            () => {
                if (currentIndex !== 0) {
                    const { selected } = this.props
                    selected(this.valueCurrentIndex(currentIndex))
                }
            }
        )
    }

    valueCurrentIndex = index => {
        const value = parseInt(valueIndex[index - 1])
        return value
    }

    render() {
        const { currentIndex } = this.state
        return (
            <StyledBar>
                <div className="bar">
                    <StyledPosition>
                        {positions.map(p => (
                            <input
                                type="checkbox"
                                key={p}
                                className={`position position-${p} ${
                                    currentIndex !== 0 && p <= currentIndex ? 'active' : 'inactive'
                                }`}
                                name={p}
                                onClick={this.selectPosition}
                            />
                        ))}
                    </StyledPosition>
                </div>
                <div className="ranges">
                    <div className="range">Starter</div>
                    <div className="range">Leader</div>
                </div>
            </StyledBar>
        )
    }
}

AmbitionBar.defaultProps = {
    selected: () => {},
}

AmbitionBar.propTypes = {
    selected: PropTypes.func,
}

export default AmbitionBar
