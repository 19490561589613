import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { Button } from '@material-ui/core'
import { StyledButton } from '../Styles'

const StyledPreSurveyStatusBar = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.lightgrey};
    box-shadow: 0 -1px 2px #3333331f; //${props => props.theme.darkgrey}
    position: relative;
    height: 120px;

    .bar-container {
        max-width: 700px;
        width: 50%;
        float: left;
        margin-top: 5px;
    }

    .progress-bar {
        margin-left: 5%;
        width: 100%;
        height: 7px;
        border-radius: 20px;
        overflow: hidden;
        background-color: ${props => props.theme.grey};

        .progress {
            height: 100%;
            border-radius: 20px;
            background-color: ${props => props.theme.bluegrey};
        }
    }

    .progress-bar-description {
        margin-left: 5%;

        .description {
            padding: 5px;
            margin-top: 5px;
            border-radius: 0 !important;
            font-size: 1rem;
            font-weight: bold;
            color: ${props => props.theme.bluegrey} !important;
            text-transform: uppercase;
        }
    }

    .progress-buttons {
        float: right;
        margin-right: 3%;
        padding-top: 7px;

        .progress-button {
            font-size: 2rem;
            line-height: 0px;
            display: inline-block;
            height: 50px !important;
            width: 50px !important;
            min-width: 50px;
            margin-right: 5px;
            background-color: ${props => props.theme.darkblue};
            cursor: pointer;
            color: ${props => props.theme.white};

            &:hover {
                background-color: ${props => props.theme.darkgrey};
                color: ${props => props.theme.white};
            }
        }

        .disable-color {
            background-color: ${props => props.theme.grey} !important;
        }
    }

    .animate-progress {
        -webkit-transition: width 0.5s;
        transition: width 0.5s;
    }

    .finished-button {
        display: inline-block !important;
        width: 60%;
        padding: 15px;
    }

    .finished {
        position: absolute;
        top: -150px;
        left: 0;
        right: 0;
        bottom: 0;
        height: 150px;
        background: white;
        box-shadow: 0px -2px 1px #ccc;
        animation-duration: 0.5s;
        animation-name: animateHeight;
        transition: opacity 2s;
        text-align: center;
        p {
            padding: 10px 10px 0px 10px;
        }
    }

    @keyframes animateHeight {
        from {
          opacity: 0;
          top: 0px;
        }
        to {
        opacity: 1;
        top: -150px;
        }
    }

`

class PreSurveyStatusBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hide: false,
            originalHeight: window.innerHeight,
        }
        this.move = this.move.bind(this)
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        const { originalHeight } = this.state
        if (window.innerHeight < originalHeight) {
            this.setState({
                hide: true,
            })
        } else {
            this.setState({
                hide: false,
            })
        }
    }

    move = e => {
        let { currentIndex } = this.props
        const { scrolling, questions, updateIndex } = this.props
        if (scrolling) {
            return
        }
        // check for up or down
        // update index
        // notify container
        if (e.currentTarget.name === 'up') {
            if (currentIndex === -1) {
                currentIndex = questions.length
            } else if (currentIndex === 0) {
                currentIndex = 0
            } else {
                currentIndex -= 1
            }
        } else {
            if (currentIndex === -1 || currentIndex === this.props.questions.length) {
                currentIndex = -1
            } else {
                currentIndex += 1
            }
        }
        updateIndex(currentIndex)
    }

    render() {
        const { height, progress, scrolling, currentIndex, finish, questions } = this.props

        const { hide } = this.state
        const finished =
            !scrolling &&
            currentIndex !== -1 &&
            currentIndex - 1 !== questions.length - 1 &&
            progress === 100
        if (hide) {
            return <React.Fragment />
        }
        return (
            <div>
                <StyledPreSurveyStatusBar
                    style={{
                        height: `${height}px`,
                        position: 'fixed',
                        bottom: '0',
                        left: '0',
                        right: '0',
                    }}
                >
                    {progress < 110 ? (
                        <div className="bar-container">
                            <div className="progress-bar-description">
                                <div className="description">{`${progress}% Complete`}</div>
                            </div>
                            <div className="progress-bar">
                                <div
                                    className="progress animate-progress"
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="finished-button">
                            <StyledButton onClick={finish} customClass="narrow">
                                Finish
                            </StyledButton>
                        </div>
                    )}
                    <div className="progress-buttons">
                        <Button
                            name="down"
                            className={`progress-button${scrolling ? ' disable ' : ''}${
                                currentIndex === -1 ? ' disable-color' : ''
                            }`}
                            onClick={this.move}
                        >
                            <FontAwesomeIcon icon={faAngleDown} />
                        </Button>
                        <Button
                            name="up"
                            className={`progress-button${scrolling ? ' disable ' : ''}${
                                currentIndex === 0 ? ' disable-color' : ''
                            }`}
                            onClick={this.move}
                        >
                            <FontAwesomeIcon icon={faAngleUp} />
                        </Button>
                    </div>
                    {finished && (
                        <div className="finished">
                            <p>
                                {
                                    "You've completed the company profile. You can now continue to the survey."
                                }
                            </p>
                            <br />
                            <StyledButton onClick={finish} customClass=" medium center">
                                Continue
                            </StyledButton>
                            <br />
                        </div>
                    )}
                </StyledPreSurveyStatusBar>
            </div>
        )
    }
}

PreSurveyStatusBar.defaultProps = {
    questions: [],
    currentIndex: 0,
    updateIndex: () => {},
    scrolling: false,
    height: 0,
    progress: 0,
    finish: () => {},
}

PreSurveyStatusBar.propTypes = {
    questions: PropTypes.array,
    currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.number,
    scrolling: PropTypes.bool,
    updateIndex: PropTypes.func,
    progress: PropTypes.number,
    finish: PropTypes.func,
}

export default PreSurveyStatusBar
