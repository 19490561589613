import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import BlocksList from './BlocksListContainer'
import BlockQuestionsContainer from './BlockQuestionsContainer'
import { scrollTo } from '../../utils'

const backgroundGradient = 'linear-gradient(210deg, #EAEAEA 30%, #9BB8AD 120%)'
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
})

const StyledContainer = styled.div`
    background: '#EAEAEA';
    .overview {
        transform: translateY(100%);
        transition: transform;
    }
    .questions {
        transform: translateY(-100%);
        transition: transform;
    }
`

const StyledResultsBar = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 45px;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    background-color: ${props => props.theme.darkblue};
    z-index: 9;
    box-shadow: 0 -2px 4px ${props => props.theme.shadowGreyLight};
    cursor: pointer;
    z-index: 9999;
`

class SurveyContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            screen: this.props.display,
            drawerTransitionDuration: 0,
        }
        this.overviewRef = React.createRef()
        this.questionsRef = React.createRef()
    }

    componentDidMount() {
        const { display } = this.props
        const appBar = document.getElementById('appBar')
        const appBarHeight = appBar.clientHeight
        const containerHeight = `${window.innerHeight + appBarHeight * 2}px`
        // const orientation = this.checkOrientation();
        this.setState({
            screen: display,
            containerHeight,
            // orientation,
            appBar: `${appBarHeight}px`,
        })
        window.addEventListener('resize', this.resize)
    }

    componentWillReceiveProps(newProps) {
        scrollTo(0, 500)
        const { display } = newProps
        if (display === 'list') {
            this.resize()
        }
        const { screen } = this.state

        if (display !== screen) {
            this.setScreens(display)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    setScreens(display) {
        const $this = this
        $this.setState(
            {
                drawerTransitionDuration: 400,
            },
            () => {
                const timer = setTimeout(() => {
                    $this.setState({
                        screen: display,
                    })
                    scrollTo(0, 0)
                    clearTimeout(timer)
                }, 450)
            }
        )
    }

    resize = () => {
        const { display } = this.props
        if (display === 'list') {
            const appBar = document.getElementById('appBar')
            const appBarHeight = appBar.offsetHeight
            const containerHeight = `${window.innerHeight - appBarHeight * 1}px`
            this.setState({
                containerHeight,
                appBar: `${appBarHeight}px`,
            })
        }
    }

    selectBlock = block => {
        const { setCurrentBlock } = this.props
        setCurrentBlock(block)
    }

    finishedBlock = async block => {
        const { retrieveBlockResults } = this.props
        await retrieveBlockResults(block)
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        })
    }

    render() {
        const {
            profile,
            blocks,
            updateBlock,
            currentBlock,
            updateCurrentBlock,
            toggleBlockList,
            goToResults,
            display,
        } = this.props

        const { drawerTransitionDuration, containerHeight, appBar } = this.state
        const completedBlocks = blocks.filter(b => b.preresults)
        const allCompleted = completedBlocks.length >= blocks.length
        return (
            <StyledContainer>
                {currentBlock && display !== 'list' ? (
                    <div id="blocks">
                        <BlockQuestionsContainer
                            currentBlock={currentBlock}
                            block={currentBlock}
                            updateBlockQuestion={updateBlock}
                            finishedBlock={this.finishedBlock}
                            toggleBlockList={toggleBlockList}
                            updateCurrentBlock={this.updateCurrentBlock}
                            containerHeight={containerHeight}
                        />
                    </div>
                ) : (
                    <div />
                )}
                <MuiThemeProvider theme={theme}>
                    <Drawer
                        className="drawer"
                        transitionDuration={drawerTransitionDuration}
                        anchor="bottom"
                        open={display === 'list'}
                        onClose={this.toggleDrawer('bottom', false)}
                        ModalProps={{
                            style: {
                                position: 'relative',
                                zIndex: 99,
                                top: appBar,
                                background: backgroundGradient,
                            },
                        }}
                        SlideProps={{
                            style: { top: appBar, background: backgroundGradient },
                        }}
                        PaperProps={{
                            style: {
                                top: appBar,
                                background: backgroundGradient,
                            },
                        }}
                        BackdropProps={{
                            style: { top: appBar, display: 'none' },
                        }}
                    >
                        <BlocksList
                            ref={this.overviewRef}
                            profile={profile}
                            blocks={blocks}
                            select={this.selectBlock}
                            currentBlock={currentBlock}
                            updateCurrentBlock={updateCurrentBlock}
                            goToResults={goToResults}
                        />
                    </Drawer>
                </MuiThemeProvider>
                {allCompleted && (
                    <StyledResultsBar onClick={goToResults}>View Final Results</StyledResultsBar>
                )}
            </StyledContainer>
        )
    }
}

SurveyContainer.defaultProps = {
    profile: {},
    blocks: [],
    updateBlock: () => {},
    updateCurrentBlock: () => {},
    setCurrentBlock: () => {},
    retrieveBlockResults: () => {},
    currentBlock: {},
    display: 'list',
    toggleBlockList: () => {},
    goToResults: () => {},
}

SurveyContainer.propTypes = {
    profile: PropTypes.object,
    blocks: PropTypes.array,
    updateBlock: PropTypes.func,
    updateCurrentBlock: PropTypes.func,
    setCurrentBlock: PropTypes.func,
    retrieveBlockResults: PropTypes.func,
    currentBlock: PropTypes.object,
    display: PropTypes.string,
    toggleBlockList: PropTypes.func,
    goToResults: PropTypes.func,
}

export default SurveyContainer
