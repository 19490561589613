import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { StyledButton } from '../Styles'
import { CardBody } from '../Cards'

const StyledContainer = styled.div`
    margin: 0 0 15px 0;
    width: 100%;
    input {
        width: 100%;
        height: 50px;
        border: none;
        font-size: 20px;
        padding: 5px;

        &:focus {
            outline: none;
        }
    }

    textarea {
        width: 100%;
        height: 100px;
        border: none;
        font-size: 20px;
        padding: 5px;
        resize: none;

        &:focus {
            outline: none;
        }
    }
`

class TextInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            [props.name]: props.value || '',
        }
    }

    componentDidMount() {
        const { name, value } = this.props
        this.setState({
            [name]: value,
        })
    }

    handleChange = e => {
        const { currentTarget } = e
        const { name, value } = currentTarget
        this.setState({
            [name]: value,
        })
    }

    select = e => {
        const { selected, name } = this.props
        const value = this.state[name]
        let answer = [{ label: value, value }]

        if (value === '' || !value) {
            answer = []
        }
        selected(answer)
    }

    render() {
        const { name } = this.props
        if (!this.state) {
            return <div> nothing to see here </div>
        }
        return (
            <StyledContainer>
                <form onSubmit={this.select}>
                    <CardBody key={name} index={0} hoverable>
                        <textarea
                            placeholder="Enter text here"
                            value={this.state[name]}
                            name={name}
                            onChange={this.handleChange}
                            onBlur={this.select}
                        />
                    </CardBody>
                    <br />
                    <br />
                </form>
            </StyledContainer>
        )
    }
}

TextInput.defaultProps = {
    name: '',
    value: '',
    selected: () => {},
}

TextInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.func,
}

export default TextInput
