import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PreResultGraph from './PreResultGraph'
import BlockQuickStart from '../Elements/BlockQuickStart'

const StyledContainer = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    box-shadow: ${props => props.theme.shadowGrey};
    cursor: pointer;
    border-radius: 3px;
    position: relative;

    @media only screen and (min-width: 768px) {
        min-height: 190px;
    }
`
class PreResultSlide extends Component {
    handleChangeIndex = index => {
        return index
    }

    render() {
        const { block, select, profile } = this.props
        const { properties } = block
        return (
            <StyledContainer
                style={{
                    backgroundColor: block.preresults ? '#e8e8e8' : properties.color,
                    border: block.preresults ? `5px solid ${properties.color}` : '',
                }}
            >
                {block.preresults && block.preresults.userScore > -100 ? (
                    <PreResultGraph block={block} profile={profile} />
                ) : (
                    <BlockQuickStart block={block} select={select} />
                )}
            </StyledContainer>
        )
    }
}

PreResultSlide.defaultProps = {
    profile: {},
    block: {},
    select: () => {},
}

PreResultSlide.propTypes = {
    profile: PropTypes.object,
    block: PropTypes.object,
    select: PropTypes.func,
}

export default PreResultSlide
