const baseUrl = process.env.PUBLIC_URL

const routePaths = [
    'signin',
    'home',
    'survey',
    'unauthenticated',
    'contact',
    'history',
    'presurvey',
    'results',
    'about',
    'settings',
    'howto',
    'framework',
]

const rpObject: {[route: string]: string} = {}
const rpArray = routePaths.map(p => {
    if (p === 'signin') {
        rpObject[p] = `${baseUrl}/`
    } else {
        rpObject[p] = `${baseUrl}/${p}`
    }
    return { p: `${baseUrl}/${p}` }
})

export default rpObject
