import React from 'react'
import { QuestionWeight, Block, Question } from './ResultsDefinition'
import BionicScoreCircle, { COLOR_SCORE, COLOR_TARGET } from '../Bionic/BionicScoreCircle'
import BionicModal from '../Bionic/BionicModal'
import { Grid, withStyles, WithStyles } from '@material-ui/core'
import iconTarget from '../../assets/bionic/icons/icon_target.png'
import iconYourScore from '../../assets/bionic/icons/icon_yourscore.png'

type Props = {
    block: Block,
    onClose: () => void,
}

const COLOR_EMPTY = '#d8d8d8'
const COLOR_SCORE_2 = '#0061d4'
const COLOR_SCORE_3 = '#1255ae'
const COLOR_SCORE_4 = '#00489c'

function AmbitionBars({ score, ambition }: { score: QuestionWeight, ambition: QuestionWeight }) {
    return (
        <div style={{ display: 'flex', height: 8 }}>
            <div
                style={{
                    flex: 1,
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    background: COLOR_SCORE,
                }}
            />
            <div
                style={{
                    flex: 1,
                    background:
                        score > 0 ? COLOR_SCORE_2 : ambition > 0 ? COLOR_TARGET : COLOR_EMPTY,
                    borderLeft: 'solid 1px #10263a',
                }}
            />
            <div
                style={{
                    flex: 1,
                    background:
                        score > 33 ? COLOR_SCORE_3 : ambition > 33 ? COLOR_TARGET : COLOR_EMPTY,
                    borderLeft: 'solid 1px #10263a',
                }}
            />
            <div
                style={{
                    flex: 1,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    background:
                        score > 66 ? COLOR_SCORE_4 : ambition > 66 ? COLOR_TARGET : COLOR_EMPTY,
                    borderLeft: 'solid 1px #10263a',
                }}
            />
        </div>
    )
}

export function NABar() {
    return (
        <div
            style={{
                height: 8,
                borderRadius: 5,
                backgroundColor: '#424b5a',
                position: 'relative',
                color: '#fff',
                fontFamily: 'Proxima Nova',
                fontSize: 11,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    left: '100%',
                    marginLeft: 4,
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
            >
                N/A
            </div>
        </div>
    )
}

function DetailRow({ question, classes }: { question: Question } & WithStyles) {
    const { weight } = question.answer
    return (
        <Grid container className={classes.root} alignItems="center" spacing={8}>
            <Grid item xs={12} sm={6}>
                {question.title}
            </Grid>
            <Grid item xs={12} sm={6}>
                {weight === 1 ? (
                    <NABar />
                ) : (
                    <AmbitionBars score={question.answer.weight} ambition={question.ambition} />
                )}
            </Grid>
        </Grid>
    )
}
const DetailRowStyled = withStyles({
    root: {
        fontSize: 16,
        marginBottom: 12,
        lineHeight: 1.1,
        fontFamily: 'Proxima Nova',
        '@media only screen and (max-width: 768px)': {
            fontSize: 14,
            marginBottom: 8,
        },
    },
})(DetailRow)

const styles = {
    chartNumbers: {
        position: 'absolute',
        top: '100%',
        marginTop: 8,
        fontSize: 15,
        lineHeight: 1,
        transform: 'translateX(-50%)',
        fontFamily: 'Kelson',
        letterSpacing: -1,
        '@media only screen and (max-width: 768px)': {
            fontSize: 13,
            marginTop: 4,
        },
    },
    separator: {
        height: 1,
        background: '#425566',
        border: 0,
        marginBottom: 32,
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
}

function ResultsBionicModal({ block, onClose, classes }: Props & WithStyles) {
    const score = Math.round(block.preresults.userScore)
    const ambition = Math.round(block.ambition.ambitionScore)
    return (
        <BionicModal block={block} onClose={onClose}>
            {/*Score circles on top*/}
            <div
                style={{
                    display: 'flex',
                    padding: '0 32px',
                    justifyContent: 'space-evenly',
                    marginBottom: 32,
                }}
            >
                <BionicScoreCircle
                    score={score}
                    size={50}
                    fontSize={23}
                    label="YOUR SCORE"
                    borderColor={COLOR_SCORE}
                    screen="xs"
                />
                <BionicScoreCircle
                    score={score}
                    size={75}
                    fontSize={40}
                    label="YOUR SCORE"
                    borderColor={COLOR_SCORE}
                    screen="sm"
                />
                <BionicScoreCircle
                    score={ambition}
                    size={50}
                    fontSize={23}
                    label="YOUR TARGET"
                    borderColor={COLOR_TARGET}
                    screen="xs"
                />
                <BionicScoreCircle
                    score={ambition}
                    size={75}
                    fontSize={40}
                    label="YOUR TARGET"
                    borderColor={COLOR_TARGET}
                    screen="sm"
                />
            </div>
            {/*Score charts*/}
            <div style={{ marginBottom: 32, paddingTop: 14, paddingBottom: 20 }}>
                <div style={{ position: 'relative', height: 19 }}>
                    {/*Background*/}
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            background: '#424b5a',
                        }}
                    />
                    {/*Target bar*/}
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            width: `${ambition}%`,
                            background: `repeating-linear-gradient(90deg, ${COLOR_TARGET}, ${COLOR_TARGET} 2px, transparent 2px, transparent 3px)`,
                        }}
                    />
                    {/*Score bar*/}
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            width: `${score}%`,
                            background: COLOR_SCORE,
                        }}
                    />
                    {/*Score icon*/}
                    <img
                        src={iconYourScore}
                        alt="Your Score"
                        style={{
                            position: 'absolute',
                            bottom: '100%',
                            left: `${score}%`,
                            height: 12,
                            transform: 'translateX(-50%)',
                            marginBottom: 2,
                        }}
                    />
                    {/*Target icon*/}
                    <img
                        src={iconTarget}
                        alt="Your Target"
                        style={{
                            position: 'absolute',
                            bottom: '100%',
                            left: `${ambition}%`,
                            height: 12,
                            transform: 'translateX(-2px)',
                            marginBottom: 2,
                        }}
                    />
                    {/*Score number*/}
                    <div
                        className={classes.chartNumbers}
                        style={{ left: `${score / 2}%`, color: '#006def' }}
                    >
                        {score}
                    </div>
                    {/*Target number*/}
                    <div
                        className={classes.chartNumbers}
                        style={{ left: `${(ambition - score) / 2 + score}%`, color: COLOR_TARGET }}
                    >
                        {ambition}
                    </div>
                </div>
            </div>
            <hr className={classes.separator} />
            {block.questions.map(question => (
                <DetailRowStyled question={question} key={question.questionId} />
            ))}
        </BionicModal>
    )
}

// @ts-ignore
export default withStyles(styles)(ResultsBionicModal)
