import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEye, faCheck } from '@fortawesome/pro-light-svg-icons'

const StyledContainer = styled.div`
    position: relative;

    .form-inputs {
        .icon {
            display: inline-block;
            height: 34px;
            width: 12%;
            vertical-align: top;
            background: white;
            text-align: center;
            line-height: 34px;
            color: #ccc;

            button {
                min-width: 0px;
                line-height: 30px;
                width: 30px !important;
                height: 30px;
                min-height: 0px;
                color: #ccc;
                padding: 0 !important;
                -webkit-appearance: none;
                width: 100%;
                border-radius: 100%;
            }
        }

        input {
            display: inline-block;
            height: 34px;
            width: 76%;
            padding-left: 3px;
            margin-bottom: 10px;
            -webkit-appearance: none;
            border: none;
            font-size: 16px;

            &:focus {
                outline: none;
            }
        }

        .password-strength {
            width: calc(100% - 0.1%);
            text-align: right;
            background: ${props => props.theme.lightgrey};
            padding: 0px 5px 5px 0px;
        }

        .strength-weak {
            color: #ccc;
        }

        .stength-ok {
            color: ${props => props.theme.bcgGreen};
        }

        .strength-strong {
            color: ${props => props.theme.bcgGreen};
        }
    }
`

const PASSWORD_STRENGTHS_LABELS = ['weak', 'weak', 'ok', 'strong']

class CreatePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newPassword: '',
            confirmPassword: '',
            passwordInputType: 'password',
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.reset) {
            this.setState({
                newPassword: '',
                confirmPassword: '',
                passwordStrength: null,
            })
        }
    }

    handleInputChange = e => {
        const { name, value } = e.target
        const passwordStrength = this.calculatePasswordStrength(value)
        let passwordStrengthLabel = PASSWORD_STRENGTHS_LABELS[passwordStrength]
        if (!passwordStrengthLabel) {
            passwordStrengthLabel = null
        }

        this.setState(
            {
                [name]: value,
                passwordStrengthLabel,
            },
            () => {
                const { returnPassword } = this.props
                const { newPassword, confirmPassword } = this.state
                returnPassword({ newPassword, confirmPassword, passwordStrength })
            }
        )
    }

    calculatePasswordStrength = value => {
        const { requiredLength } = this.props
        let passwordStrength = 0
        if (value < requiredLength) {
            passwordStrength = 0
            return passwordStrength
        }
        passwordStrength = this.hasNumber(value) ? passwordStrength + 1 : passwordStrength
        passwordStrength = this.hasMixed(value) ? passwordStrength + 1 : passwordStrength
        passwordStrength = this.hasSpecial(value) ? passwordStrength + 1 : passwordStrength

        return passwordStrength
    }

    hasNumber = value => {
        return new RegExp(/[0-9]/).test(value)
    }

    hasMixed = value => {
        return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value)
    }

    hasSpecial = value => {
        return new RegExp(/[!#@$%^&*)(+=._-]/).test(value)
    }

    showPassword = () => {
        const { passwordInputType } = this.state
        const inputType = passwordInputType === 'password' ? 'text' : 'password'
        this.setState({
            passwordInputType: inputType,
        })
    }

    render() {
        const {
            newPassword,
            confirmPassword,
            passwordInputType,
            passwordStrengthLabel,
        } = this.state
        return (
            <StyledContainer>
                <div className="form-inputs">
                    {passwordStrengthLabel ? (
                        <div className={`password-strength strength-${passwordStrengthLabel}`}>
                            {passwordStrengthLabel}
                        </div>
                    ) : (
                        <React.Fragment />
                    )}
                    <div>
                        <div className="icon">
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                        <input
                            autoComplete="new-password"
                            type={passwordInputType}
                            name="newPassword"
                            placeholder="Enter New Password"
                            onChange={this.handleInputChange}
                            value={newPassword}
                        />
                        <div className="icon">
                            <Button onClick={this.showPassword}>
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className="icon">
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                        <input
                            autoComplete="new-password"
                            type={passwordInputType}
                            name="confirmPassword"
                            placeholder="Re-enter New Password"
                            onChange={this.handleInputChange}
                            value={confirmPassword}
                        />
                        {newPassword !== '' && newPassword === confirmPassword ? (
                            <div className="icon">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                        ) : (
                            <div className="icon" />
                        )}
                    </div>
                </div>
            </StyledContainer>
        )
    }
}

CreatePassword.defaultProps = {
    requiredLength: 6,
    returnPassword: () => {},
}

CreatePassword.propTypes = {
    requiredLength: PropTypes.number,
    returnPassword: PropTypes.func,
}

export default CreatePassword
