import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Creatable from 'react-select/lib/AsyncCreatable'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import styled from 'styled-components'
import { CardBody } from '../Cards'

const StyledSelect = styled(Select)`
    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'] + span {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 12px;
        height: 12px;
        margin: -1px 0px 0 0;
        vertical-align: middle;
        background: white left top no-repeat;
        border: 1px solid #ccc;
        cursor: pointer;
    }

    input[type='checkbox']:checked + span {
        background: ${props => props.theme.selectedGrey} -19px top no-repeat;
    }

    input[type='checkbox'] + span {
        margin-right: 4px;
    }

    /* this is the class of the dropdown menu */
    .css-11unzgr {
        padding-top: 0px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: ${props => props.theme.white};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${props => props.theme.grey};
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
    .css-1s9izoc {
        padding: 0;
        .css-162fmd1 {
            margin: 0;
        }
    }
`

const StyledCreatable = styled(Creatable)`
    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'] + span {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 12px;
        height: 12px;
        margin: -1px 0px 0 0;
        vertical-align: middle;
        background: white left top no-repeat;
        border: 1px solid #ccc;
        cursor: pointer;
    }

    input[type='checkbox']:checked + span {
        background: ${props => props.theme.selectedGrey} -19px top no-repeat;
    }

    input[type='checkbox'] + span {
        margin-right: 4px;
    }

    /* this is the class of the dropdown menu */
    .css-11unzgr {
        padding-top: 0px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: ${props => props.theme.white};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${props => props.theme.grey};
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
    .css-1s9izoc {
        padding: 0;
        .css-162fmd1 {
            margin: 0;
        }
    }
`

const selectedColor = '#9ca4ad'
const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 9,
    },
    input: {
        display: 'flex',
        padding: 10,
    },
    underline: {
        borderBottom: '2px solid red',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
        backgroundColor: 'transparent',
        border: `1px solid ${selectedColor}`,
        color: '#10223A',
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    singleValueFocused: {},
    placeholder: {
        position: 'absolute',
        fontSize: 16,
        padding: 5,
        fontFamily: 'bcg, Helvetica Neue, sans-serif',
    },
    paper: {
        zIndex: 1,
        left: 0,
        right: 0,
        position: 'relative',
        boxShadow: 'none',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
    divider: {
        height: theme.spacing.unit * 3,
    },
    selected: {
        color: theme.palette.grey[300],
    },
    typography: {
        useNextVariants: true,
    },
})

const muiTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
})

// prepare components that will render the dropdown menu
function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />
}

function Menu(props) {
    const { selectProps, innerProps, children } = props
    return (
        <Paper square className={selectProps.classes.paper} {...innerProps}>
            {children}
        </Paper>
    )
}

function Control(props) {
    const { selectProps, innerRef, innerProps, children } = props

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                disableUnderline: true,
                inputProps: {
                    className: selectProps.classes.input,
                    inputRef: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...selectProps.textFieldProps}
        />
    )
}

function NoOptionsMessage(props) {
    const { selectProps, innerProps, children } = props

    return (
        <MuiThemeProvider theme={muiTheme}>
            <Typography
                color="textSecondary"
                className={selectProps.classes.noOptionsMessage}
                {...innerProps}
            >
                {children}
            </Typography>
        </MuiThemeProvider>
    )
}

function Option(props) {
    const { innerRef, isFocused, isSelected, children } = props

    const option = (
        <MenuItem
            buttonRef={innerRef}
            selected={isFocused}
            component="div"
            style={{
                padding: '10px 5px 10px 10px',
                backgroundColor: isSelected ? selectedColor : '',
            }}
            {...props.innerProps}
        >
            <ListItemText
                disableTypography
                primary={
                    <Typography
                        type="body2"
                        style={{
                            fontFamily: 'bcg, Helvetica Neue, sans-serif',
                            fontSize: '1.2rem',
                            fontWeight: isSelected ? 500 : 400,
                            color: isSelected ? 'white' : '',
                        }}
                    >
                        {children}
                    </Typography>
                }
            />
        </MenuItem>
    )
    return <React.Fragment>{option}</React.Fragment>
}

function Placeholder(props) {
    const { selectProps, innerProps, children } = props
    return (
        <Typography
            color="textSecondary"
            className={selectProps.classes.placeholder}
            {...innerProps}
        >
            {children}
        </Typography>
    )
}

function SingleValue(props) {
    const { selectProps, innerProps, children } = props
    return (
        <Typography className={selectProps.classes.singleValue} {...innerProps}>
            {children}
        </Typography>
    )
}

function ValueContainer(props) {
    const { selectProps, children } = props
    return <div className={selectProps.classes.valueContainer}>{children}</div>
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

class IntegrationReactSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            single: this.props.question.answer || [],
            placeholder: this.props.question.placeholder || 'Type here...',
            isSearchable: true,
        }
        this.selectRef = React.createRef()
    }

    handleChange = name => value => {
        const { selected } = this.props
        if (!value || value.length === 0) {
            selected([])
            return
        }
        this.setState(
            {
                [name]: value,
            },
            () => {
                const answer = [value]
                selected(answer)
            }
        )
    }

    render() {
        const { classes, theme, question, creatable, loadOptions } = this.props

        const { placeholder, isSearchable, single } = this.state
        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                display: 'flex',
                '& input': {
                    font: 'inherit',
                },
            }),
            menu: base => ({
                ...base,
            }),
        }

        let select = <React.Fragment />

        if (creatable) {
            select = (
                <CardBody index={0} short={false} dropdown>
                    <StyledCreatable
                        classes={classes}
                        styles={selectStyles}
                        components={components}
                        defaultValue={single}
                        onChange={this.handleChange('single')}
                        loadOptions={loadOptions}
                        placeholder={placeholder}
                        onFocus={this.selectFocused}
                        onBlur={this.selectBlurred}
                        isClearable
                        autoFocus={false}
                        isSearchable={isSearchable}
                    />
                </CardBody>
            )
        } else {
            select = (
                <CardBody index={0} short={false} dropdown>
                    <StyledSelect
                        classes={classes}
                        styles={selectStyles}
                        options={question.set_options}
                        components={components}
                        defaultValue={single}
                        onChange={this.handleChange('single')}
                        placeholder={placeholder}
                        onFocus={this.selectFocused}
                        onBlur={this.selectBlurred}
                        menuIsOpen
                        autoFocus={false}
                        isClearable
                        isSearchable={isSearchable}
                    />
                </CardBody>
            )
        }

        return <div key={`${question.id}-select`}>{select}</div>
    }
}

IntegrationReactSelect.defaultProps = {
    selected: () => {},
    creatable: false,
    question: {},
}

IntegrationReactSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    selected: PropTypes.func,
    creatable: PropTypes.bool,
    question: PropTypes.object,
}

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect)
