import React, { Component } from 'react'
import PropTypes from 'prop-types'

const isReady = () =>
    typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined' &&
    window.grecaptcha.render

let readyCheck

class ReCaptcha extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ready: isReady(),
        }
        const { ready } = this.state
        if (!ready) {
            readyCheck = setInterval(this.updateReadyState, 1000)
        }
    }

    componentDidMount() {
        const { ready } = this.state
        if (ready) {
            this.execute()
        }
    }

    componentWillReceiveProps(nextProps) {
        const { ready } = this.state
        const { action } = this.props
        if (ready && action !== nextProps.action) {
            this.execute(nextProps.action)
        }
    }

    componentDidUpdate(_, prevState) {
        // _, prevState
        const { ready } = this.state
        if (ready && !prevState.ready) {
            this.execute()
        }
    }

    componentWillUnmount() {
        clearInterval(readyCheck)
    }

    execute = overrideAction => {
        const { sitekey, verifyCallback, action } = this.props

        const { ready } = this.state

        if (ready && isReady()) {
            window.grecaptcha
                .execute(sitekey, { action: overrideAction ? overrideAction : action })
                .then(token => {
                    verifyCallback(token)
                })
        }
    }

    updateReadyState = () => {
        if (isReady()) {
            this.setState(() => ({ ready: true }))

            clearInterval(readyCheck)
        }
    }

    render() {
        const { elementID, verifyCallbackName } = this.props
        const { ready } = this.state
        return ready ? (
            <div id={elementID} data-verifycallbackname={verifyCallbackName} />
        ) : (
            <div id={elementID} className="g-recaptcha" />
        )
    }
}

ReCaptcha.defaultProps = {
    elementID: 'g-recaptcha',
    verifyCallbackName: 'verifyCallback',
}

ReCaptcha.propTypes = {
    elementID: PropTypes.string,
    verifyCallbackName: PropTypes.string,
    verifyCallback: PropTypes.func.isRequired,
    sitekey: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
}

export default ReCaptcha
