import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TweenOne from 'rc-tween-one'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { Grid, ButtonBase, Collapse } from '@material-ui/core'
import GoalDetails from './GoalDetails'
import Stars from '../ImportanceStars'

const StyledContainer = styled.div`
    margin: 8px auto;
    max-width: 800px;
    border-radius: 3px;
    padding: 5px;
    position: relative;
    z-index: 2;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .tween-one {
        opacity: 0;
    }

    .row-title {
        margin-top: 5px;
        font-size: 1.1rem;
        color: ${props => props.theme.darkblueText};
        font-weight: bold;
        text-transform: uppercase;
        white-space: pre-wrap;
        cursor: pointer;
    }

    .goals {
        float: right;

        .number {
            font-size: 1.2rem;
            text-align: right;
            line-height: 45px;
            color: ${props => props.theme.darkgrey};
            font-weight: bold;
            cursor: pointer;
            display: inline-block;
            margin-right: 10px;
        }
        .row-arrow {
            border-radius: 3px;
            min-width: 0 !important;
            padding: 0;
            font-size: 1.5rem;
            display: inline-block;
            margin: 5px auto;
            color: ${props => props.theme.darkgrey};
        }
    }
`
class GoalContainer extends Component {
    state = {
        open: false,
        color: false,
    }

    componentWillReceiveProps(newProps) {
        const { block, fade } = this.props
        const { open } = this.state
        if (!newProps.currentBlock) {
            return
        }
        if (newProps.currentBlock.blockId === block.blockId && !open) {
            this.open()
        } else if (newProps.currentBlock.blockId === block.blockId && open) {
            this.close(false)
        } else if (newProps.currentBlock.blockId !== block.blockId && fade === 'all') {
            this.close(false)
        } else {
            this.close(true)
        }
    }

    toggleOpen = () => {
        const { block, toggleOpen } = this.props
        toggleOpen(block)
    }

    open = () => {
        const { block } = this.props
        const { properties } = block
        const { color } = properties
        this.setState({ open: true, color })
    }

    close = () => {
        this.setState({ open: false, color: false })
    }

    render() {
        const { block, currentBlock, fade } = this.props
        const { open } = this.state
        return (
            <StyledContainer>
                <TweenOne
                    className="tween-one"
                    key="0"
                    animation={{
                        opacity:
                            currentBlock && fade === 'all' && block.blockId !== currentBlock.blockId
                                ? 0.5
                                : 1,
                        duration: 450,
                    }}
                >
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={10} onClick={this.toggleOpen}>
                            <div className="row-title">{block.title}</div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="goals">
                                <ButtonBase className="row-arrow" onClick={this.toggleOpen}>
                                    {open ? (
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    ) : (
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    )}
                                </ButtonBase>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    position: 'relative',
                                    height: '10px',
                                    marginBottom: '25px',
                                }}
                            >
                                <div onClick={this.toggleOpen}>
                                    <Stars
                                        starSize={24}
                                        fill={block.importance}
                                        readOnly
                                        style={{ margin: '0 !important' }}
                                    />
                                </div>
                            </div>
                            <Collapse in={open}>
                                <GoalDetails block={block} />
                            </Collapse>
                        </Grid>
                    </Grid>
                </TweenOne>
            </StyledContainer>
        )
    }
}

GoalContainer.defaultProps = {
    block: {},
    toggleOpen: () => {},
    fade: 'all',
    currentBlock: {},
}

GoalContainer.propTypes = {
    block: PropTypes.object,
    toggleOpen: PropTypes.func,
    fade: PropTypes.string,
    currentBlock: PropTypes.object,
}

export default GoalContainer
