import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { getScoreColor } from '../../utils'

const StyledBlock = styled.div`
    position: relative;

    .small-fixed {
        position: fixed;
        left: 0;
        right: 0;
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        color: ${props => props.theme.darkgrey};
        background-color: ${props => props.theme.lightgrey};
        text-align: center;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: bold;
        height: 50px;
        animation-duration: 0.5s;
        animation-name: animateHeight;
        transition: opacity 0.3s;
        box-shadow: 0px 1px 1px #ccc;

        .title {
            padding: 20px;
            font-size: 1rem;
        }

        .results-title {
            padding: 20px;
            font-size: 1rem;
            text-align: right;
        }
        .score-small {
            border-radius: 100%;
            margin-right: auto;
            width: 30px;
            height: 30px;
            text-align: center;
            margin-top: 12px;
            line-height: 25px;
            font-size: 0.9rem;
        }

        .descriptor {
            margin: auto;
        }

        @keyframes animateHeight {
            from {
                opacity: 0;
                margin-top: -50px;
            }
            to {
                opacity: 1;
                margin-top: 0px;
            }
        }
    }
`

const BENCHMARKS_TAB_INDEX = 1

class QuickBar extends Component {
    state = {
        scrollPosition: 0,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll)
    }

    scroll = () => {
        const doc = document.documentElement
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        const { scrollPosition } = this.state
        if (scrollPosition > 200 && top > 200) {
            //
        } else if (scrollPosition < 200 && top > 200) {
            this.setState({ scrollPosition: 201 })
        } else if (scrollPosition > 200 && top < 201) {
            this.setState({ scrollPosition: 0 })
        }
    }

    render() {
        const { top, index, results } = this.props
        const { scrollPosition } = this.state
        const userScoreColor = getScoreColor(results.results.userScore / 100)
        const benchmarkScoreColor = results.benchmark
            ? getScoreColor(results.benchmark.benchmarkScore / 100)
            : 'N/A'
        return (
            <StyledBlock>
                <div>
                    {scrollPosition > 0 ? (
                        <div
                            className="small-fixed"
                            style={{ top: `${top}px`, opacity: scrollPosition > 0 ? 1 : 0 }}
                        >
                            {index === BENCHMARKS_TAB_INDEX ? (
                                <Grid container spacing={8}>
                                    <Grid item xs={6}>
                                        <div className="title" style={{ color: userScoreColor }}>
                                            Your score
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div
                                            className="title"
                                            style={{ color: benchmarkScoreColor }}
                                        >
                                            Benchmark
                                        </div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={8}>
                                    <Grid item xs={8} sm={6}>
                                        <div className="results-title">
                                            {results.results.descriptor}
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} sm={6}>
                                        <div
                                            className="score-small"
                                            style={{
                                                border: `2px solid ${userScoreColor}`,
                                                color: userScoreColor,
                                            }}
                                        >
                                            {results.results.userScore}
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    ) : (
                        <React.Fragment />
                    )}
                </div>
            </StyledBlock>
        )
    }
}

QuickBar.defaultProps = {
    top: 0,
    index: 0,
    results: {},
}

QuickBar.propTypes = {
    top: PropTypes.number,
    index: PropTypes.number,
    results: PropTypes.object,
}

export default QuickBar
