import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Grid, Button, Drawer } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/pro-light-svg-icons'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import FilterQuestions from './FilterQuestions'
import { AppliedFilter, ResultsWithFilters } from '../../pages/ResultsPage'
import BionicToggleRow, { BionicToggleProps } from '../Bionic/BionicToggleRow'

const StyledContainer = styled.div`
    background-color: ${props => props.theme.white};
    max-width: 800px;
    margin: 15px auto 0;
    padding: 0 15px;

    .filters-row {
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
    }

    .filter-icon {
        min-width: 0 !important;
        padding: 0;
        width: 40px;
        font-size: 1.5rem;
        color: ${props => props.theme.darkblue};
    }

    .filter-drawer {
        padding: 15px;
    }

    .bionic & {
        background-color: transparent;
        .filter-icon {
            color: #fff;
        }
        .filters-row {
            padding: 0;
        }
    }
`

const StyledDrawer = styled.div`
    overflow-x: hidden;
    width: 100%;
    margin: 0 auto;

    .header-row {
        line-height: 40px;
        .title {
            padding-left: 10px;
            font-weight: bold;
            color: ${props => props.theme.darkblueText};
        }
        .close-icon {
            min-width: 0 !important;
            width: 100%;
            color: ${props => props.theme.white};
            margin: auto;
            border-radius: 3px !important;
        }
    }

    .header-icon {
        background: ${props => props.theme.darkblue};
        line-height: 40px;
        color: ${props => props.theme.white};
        border-radius: 3px;
        .close-icon {
            min-width: 0 !important;
            width: 100%;
            color: ${props => props.theme.white};
            margin: auto;
            border-radius: 3px !important;
        }
    }
`

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
})

type Props = {
    results: ResultsWithFilters,
    applyFilters: (filters: any) => void,
    resetFilters: (filters?: any) => void,
} & BionicToggleProps

type State = {
    showFilterOptions: boolean
}

/* Make sure that known filters are always displayed in a predetermined order */
const FILTER_ORDER = ['industry', 'subIndustry', 'geography']
function filterComparator(a: AppliedFilter, b: AppliedFilter): number {
    const aIndex = FILTER_ORDER.includes(a.type)
        ? FILTER_ORDER.indexOf(a.type)
        : FILTER_ORDER.length
    const bIndex = FILTER_ORDER.includes(b.type)
        ? FILTER_ORDER.indexOf(b.type)
        : FILTER_ORDER.length
    return aIndex - bIndex
}

export default class FilterBar extends Component<Props, State> {
    state = {
        showFilterOptions: false,
    }

    toggleDrawer = () => {
        const { showFilterOptions } = this.state
        // @ts-ignore
        const [doc] = document.getElementsByTagName('html')
        // @ts-ignore
        const [bod] = document.getElementsByTagName('body')
        if (!showFilterOptions) {
            doc.style.overflow = 'hidden'
            bod.style.position = 'fixed'
            bod.style['overflow-y'] = 'hidden'
        } else {
            doc.style.overflow = 'inherit'
            bod.style.position = 'relative'
            bod.style['overflow-y'] = 'inherit'
        }
        this.setState({
            showFilterOptions: !showFilterOptions,
        })
    }

    applyFilters = (filters: any[]) => {
        this.toggleDrawer()
        this.props.applyFilters(filters)
    }

    resetFilters = () => {
        this.toggleDrawer()
        this.props.resetFilters()
    }

    render() {
        const { results, bionic, setBionic } = this.props
        const { showFilterOptions } = this.state
        return (
            <StyledContainer>
                <Grid container spacing={0}>
                    <Grid item xs={6} sm={8}>
                        <div className="filters-row">
                            <Button className="filter-icon" onClick={this.toggleDrawer}>
                                <FontAwesomeIcon icon={faSlidersH} />
                            </Button>
                            {results.filters.length > 0 ? (
                                results.filters
                                    .sort(filterComparator)
                                    .map(f => <FilterTag key={f.value} filter={f.label} />)
                            ) : (
                                <React.Fragment />
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <BionicToggleRow bionic={bionic} setBionic={setBionic} />
                    </Grid>
                </Grid>
                <MuiThemeProvider theme={theme}>
                    <Drawer
                        className={`drawer ${bionic ? 'bionic' : ''}`}
                        transitionDuration={400}
                        anchor="right"
                        onClose={this.toggleDrawer}
                        open={showFilterOptions}
                        PaperProps={{
                            style: {
                                background: bionic ? '#7396a4' : '#f0f0f0',
                                maxWidth: window.innerWidth > 400 ? '450px' : '350px',
                                width: '100%',
                            },
                        }}
                    >
                        <StyledDrawer>
                            <FilterQuestions
                                results={results}
                                applyFilters={this.applyFilters}
                                toggleDrawer={this.toggleDrawer}
                                resetFilters={this.resetFilters}
                            />
                        </StyledDrawer>
                    </Drawer>
                </MuiThemeProvider>
            </StyledContainer>
        )
    }
}

const StyledFilter = styled.div`
    padding: 5px;
    background-color: ${props => props.theme.selectedGrey};
    color: ${props => props.theme.white};
    text-align: center;
    margin: 5px;
    display: inline-block;
    border-radius: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    .bionic & {
        border-radius: 3px;
        background-color: #3d4b5d;
        color: #fff;
        font-family: 'Proxima Nova Alt';
        font-size: 12px;
        font-weight: 300;
        padding: 10px;
    }
`

const FilterTag = (props: { filter: string }) => {
    const [tagWidth, setTagWidth] = useState('70px')
    const [toggle, setToggle] = useState(false)
    const { filter } = props

    const updateTagWidth = () => {
        const newToggleState = !toggle
        const width = newToggleState ? '100%' : '70px'
        setToggle(newToggleState)
        setTagWidth(width)
    }

    return (
        <StyledFilter style={{ maxWidth: tagWidth }} onClick={updateTagWidth}>
            {filter}
        </StyledFilter>
    )
}
