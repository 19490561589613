import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'

import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

const feedbackBarContentStyles = theme => ({
    success: {
        backgroundColor: '#4aa585',
    },
    error: {
        backgroundColor: '#f56262',
    },
    info: {
        backgroundColor: '#62a4f5',
    },
    warning: {
        backgroundColor: '#f5ad62',
    },
    icon: {
        fontSize: 18,
        // display: 'flex'
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: window.innerWidth < 500 ? '220px' : '',
        textAlign: 'left',
    },
    close: {
        paddingLeft: '10px',
    },
    root: {
        padding: '10px',
        borderRadius: '3px',
        display: 'flex',
    },
})

function FeedbackBarContent(props) {
    const { classes, className, message, onClose, variant } = props
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            className={classNames(classes[variant], classes.root, className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
        />
    )
}

FeedbackBarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

const FeedbackBarContentWrapper = withStyles(feedbackBarContentStyles)(FeedbackBarContent)

export default FeedbackBarContentWrapper
