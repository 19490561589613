import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import backgroundImage from '../../assets/logo/background_logo.svg'

const StyledBackground = styled.div`
    .logo-background {
        position: fixed;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.8;
        background-image: url(${backgroundImage});
        background-position: 0 150px;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        min-height: 100vh;
        -webkit-overflow-scrolling: touch;
        // -webkit-filter: drop-shadow(0px 2px 2px rgba(0,0,0,.5));
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .color-background {
        position: fixed;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        opacity: 1;
        min-height: 100vh;
        background: ${props => props.theme.greenToGray};
        -webkit-overflow-scrolling: touch;
        // -webkit-filter: drop-shadow(0px 2px 2px rgba(0,0,0,.5));
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .light-background {
        background: linear-gradient(210deg, #eaeaea 30%, #9bb8ad 120%);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
`

class Background extends Component {
    state = {}

    componentDidMount() {
        this.resize()
        window.addEventListener('resize', this.resize)
    }

    componentWillReceiveProps() {
        this.resize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        const { body, documentElement } = document
        const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            documentElement.clientHeight,
            documentElement.scrollHeight,
            documentElement.offsetHeight
        )
        this.setState({ height })
    }

    render() {
        const { height } = this.state
        const { page } = this.props
        return (
            <StyledBackground>
                <div className="logo-background" style={{ height: `${height}px` }} />
                <div
                    className={`color-background ${page === 'results' ? 'light-background' : ''}`}
                    style={{ height: `${height}px` }}
                />
            </StyledBackground>
        )
    }
}

Background.defaultProps = {
    page: '',
}

Background.propTypes = {
    page: PropTypes.string,
}

export default Background
