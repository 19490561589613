import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ScrollAnim from 'rc-scroll-anim'
import TweenOne from 'rc-tween-one'
import QueueAnim from 'rc-queue-anim'
import { StyledButton } from '../Styles'
import { CardHead } from '../Cards'

const ScrollOverPack = ScrollAnim.OverPack

const StyledFinish = styled.div`
    padding-bottom: 200px;
    padding-top: 100px;
    .finish-section {
        width: 100%;
        min-height: 400px;
        height: 100%;
        background-color: ${props => props.theme.lightgrey};

        .finish-title {
            width: 100%;
            text-align: center;
            color: ${props => props.theme.darkgrey};
            font-size: 1.5rem;
            padding: 1rem;
        }

        .finish-description {
            color: ${props => props.theme.darkgrey};
            text-align: center;
            font-size: 1.2rem;
            padding: 1em;
        }

        .finish-button-container {
            transform: translateY(100px);
            opacity: 0;

            .finish-button {
                width: 200px;
                margin: 0 auto;
            }
        }
    }
`

class PreSurveyFinish extends Component {
    constructor(props) {
        super(props)
        this.state = {
            incomplete: [],
            isMounted: false,
        }
    }

    componentDidMount() {
        this.checkIncomplete()
    }

    componentWillReceiveProps() {
        // receive updated question-answers
        // check completion status to provide feedback
        this.checkIncomplete()
    }

    navToFeedback = feedbackElement => {
        const index = feedbackElement.currentTarget.attributes['data-id'].nodeValue
        const { updateIndex } = this.props
        updateIndex(index)
    }

    checkIncomplete() {
        // find out how many of the questions do not yet have answers
        const { questions } = this.props
        const incomplete = questions.filter(q => {
            return (!q.answer || q.answer.length < 1) && !q.optional
        })
        const isMounted = true
        this.setState({ incomplete, isMounted })
    }

    render() {
        let view = null
        const { incomplete, isMounted } = this.state
        const { finish } = this.props
        if (incomplete.length < 1 && isMounted) {
            view = (
                <StyledFinish>
                    <ScrollOverPack
                        className="finish-section"
                        playScale={0.1}
                        id="FIN"
                        key="finish-section"
                        targetId="preSurveyContainer"
                    >
                        <TweenOne
                            animation={{ opacity: 1 }}
                            style={{ opacity: 0 }}
                            key="finish-title"
                            className="finish-title"
                        >
                            <h3>{"You've successfully completed the company profile"}</h3>
                        </TweenOne>
                        <TweenOne
                            className="finish-button-container"
                            animation={{ y: 0, opacity: 1 }}
                            key="finish-button-container"
                        >
                            <div className="finish-button">
                                <StyledButton onClick={finish} customClass="narrow">
                                    Continue
                                </StyledButton>
                            </div>
                        </TweenOne>
                    </ScrollOverPack>
                </StyledFinish>
            )
        } else {
            view = (
                <StyledFinish>
                    <ScrollOverPack
                        className="finish-section"
                        playScale={0.1}
                        id="FIN"
                        key="finish-section"
                        targetId="preSurveyContainer"
                    >
                        <TweenOne
                            animation={{ opacity: 1 }}
                            style={{ opacity: 0 }}
                            key="finish-title"
                            className="finish-title"
                        >
                            <h3>{'Please complete the required questions'}</h3>
                        </TweenOne>
                        {incomplete.map(q => (
                            <Feedback question={q} key={q.id} navToFeedback={this.navToFeedback} />
                        ))}
                    </ScrollOverPack>
                </StyledFinish>
            )
        }
        return view
    }
}

const StyledFeedback = styled.div`
    margin: 0 auto;
    margin-top: 1rem;
    cursor: pointer;
    padding: 0 2rem 0 2rem;
    max-width: 400px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .number {
        vertical-align: top;
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: ${props => props.theme.neongreen};
        color: ${props => props.theme.darkgreen};
        border: none;
        cursor: pointer;
        font-size: 1.2em;
        font-weight: bold;

        &:hover {
            background-color: ${props => props.theme.darkgreen};
            color: ${props => props.theme.white};
        }
    }
`

const Feedback = props => {
    const { question, navToFeedback } = props
    return (
        <StyledFeedback data-id={question.index} onClick={navToFeedback}>
            <QueueAnim type="left" key="anim">
                <TweenOne animation={{ opacity: 1 }} style={{ opacity: 1 }} key="button">
                    <CardHead title={question.title} indicator={`${question.index}.`} arrow="up" />
                </TweenOne>
            </QueueAnim>
        </StyledFeedback>
    )
}

Feedback.defaultProps = {
    question: {},
    navToFeedback: () => {},
}

Feedback.propTypes = {
    question: PropTypes.object,
    navToFeedback: PropTypes.func,
}

PreSurveyFinish.defaultProps = {
    updateIndex: () => {},
    questions: [],
    finish: () => {},
}

PreSurveyFinish.propTypes = {
    updateIndex: PropTypes.func,
    questions: PropTypes.array,
    finish: PropTypes.func,
}

export default PreSurveyFinish
