import React, { useMemo, useState } from 'react'
import highlightAll from '../../assets/bionic/whole_shape/1600px/whole_bright.png'
import highlightNone from '../../assets/bionic/whole_shape/1600px/whole_dark.png'
import styled from 'styled-components'
import { Block, ResultsResponse } from '../Results/ResultsDefinition'
import WheelConfig from './bionic-wheel-segments-config'
import { withStyles, WithStyles } from '@material-ui/core'

/* Generic floating text component to be displayed on the bionic wheel */
const FloatingText = styled.div`
    position: absolute;
    transform: translate(-50%, -50%);
    color: #fff;
    max-width: 25%;
    text-align: center;
    font-size: 16px;
    line-height: 1.2;

    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
`

/* Floating text for the 3 block areas ("themes"): Tech, Human, Outcomes */
const AreaFloatingText = styled(FloatingText)`
    font-size: 17px;
    font-weight: bold;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`

export type RenderScore = (block: Block) => React.ReactNode

/* Floating text to display a block name on each section of the wheel. The block name is displayed, together with a
 * score which can be customized by using `renderScore` */
const BlockFloatingText = withStyles({
    score: {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: 1.1,
        marginTop: 2,
        '@media only screen and (min-width: 768px)': {
            lineHeight: 1.2,
            marginTop: 8,
        },
    },
})(
    ({
        block,
        top,
        left,
        blurred,
        renderScore,
        classes,
    }: {
        block: Block,
        top: string,
        left: string,
        blurred: boolean,
        renderScore: RenderScore,
    } & WithStyles) => (
        <FloatingText
            style={{
                top,
                left,
                opacity: blurred ? 0.5 : 1,
                fontFamily: 'Proxima Nova Alt',
                fontWeight: 300,
            }}
        >
            {block.title}
            <div className={classes.score}>{renderScore(block)}</div>
        </FloatingText>
    )
)

/* Set to a value higher than zero to make the clickable areas visible */
const DEBUG_OPACITY = 0.0

type Props = {
    results: ResultsResponse,
    renderScore: RenderScore,
    renderModal: (block: Block, onClose: () => void) => void,
}

/* The only use of this hook is to get around false syntax errors. I believe these errors are caused by an eslint plugin
 * that only parses an outdated version of TypeScript */
function useModalBlock(): [Block | null, (block: Block | null) => void] {
    // @ts-ignore
    return useState(null)
}

/* The hard-coded names of the block themes. This is to assign each block to the correct theme. */
export const BLOCK_THEME_TITLES = {
    outcomes: 'Outcomes',
    human: 'Human',
    tech: 'Technology',
    strategy: 'Strategy & Purpose',
}

export default function BionicWheel({
    results,
    renderScore,
    renderModal,
}: React.PropsWithChildren<Props>) {
    /* State to keep track of the highlighted section of the wheel. This will allow showing and hiding the correct
     * images. When the mouse is not over the wheel then all sections should be highlighted. */
    const [focusSection, setFocusSection] = useState('ALL')

    /* State to keep track of which modal window to show. Set to null or undefined to not show any modal. */
    const [modalBlock, setModalBlock] = useModalBlock()

    /* Assigns each block to a section of the wheel, based on theme and ID order */
    const blocksBySection = useMemo(() => {
        const sortedBlocks = results.blocks.sort((a, b) => a.blockId - b.blockId)
        const blocksByTheme = {
            outcomes: sortedBlocks.filter(b => b.blockThemeTitle === BLOCK_THEME_TITLES.outcomes),
            human: sortedBlocks.filter(b => b.blockThemeTitle === BLOCK_THEME_TITLES.human),
            tech: sortedBlocks.filter(b => b.blockThemeTitle === BLOCK_THEME_TITLES.tech),
            strategy: sortedBlocks.filter(b => b.blockThemeTitle === BLOCK_THEME_TITLES.strategy),
        }
        return {
            NW: blocksByTheme.outcomes[0],
            N: blocksByTheme.outcomes[1],
            NE: blocksByTheme.outcomes[2],
            E: blocksByTheme.human[1],
            SE: blocksByTheme.human[0],
            SW: blocksByTheme.tech[1],
            W: blocksByTheme.tech[0],
            C: blocksByTheme.strategy[0],
        }
    }, [results])

    /* Extracts all images from the config, and adds an "ALL" image for when no section is highlighted */
    const sectionImages = useMemo(
        () =>
            Object.entries(WheelConfig)
                .map(([section, config]) => ({
                    section,
                    image: config.highlightImage,
                }))
                .concat({ section: 'ALL', image: highlightAll }),
        []
    )

    return (
        <div
            style={{
                position: 'relative',
                maxWidth: 800,
                margin: '40px auto 0',
            }}
        >
            {/* The background image, with all sections blurred. This has 2 purposes:
                1) it is always displayed behind all the other images even when they are fading: without this, the page
                background would be visible during the fading animations;
                2) it sets the dimensions of the container: since this is the only image without "position: absolute",
                without this image the container height would default to zero and the page layout would be harder to
                control.
            */}
            <img alt="Dark" style={{ maxWidth: '100%' }} src={highlightNone} />

            {/* In each included image, only one of the sections is highlighted. The draw order doesn't matter. Normally,
            only one of these images is visible. When switching between wheel sections, the images are switched by
            animating their opacity, providing a fading animation between them. */}
            {sectionImages.map(({ section, image }) => (
                <img
                    key={section}
                    alt={section}
                    style={{
                        position: 'absolute',
                        maxWidth: '100%',
                        opacity: focusSection === section ? 1 : 0,
                        top: 0,
                        left: 0,
                        transition: 'opacity 300ms',
                    }}
                    src={image}
                />
            ))}

            {/*Floating texts for each of the block themes*/}
            <AreaFloatingText
                style={{
                    top: '-25px',
                    left: '50%',
                    color: '#709fcb',
                    fontFamily: 'Proxima Nova',
                    fontWeight: 'bold',
                }}
            >
                OUTCOMES
            </AreaFloatingText>
            <AreaFloatingText
                style={{
                    top: '85%',
                    left: '5%',
                    color: '#b5eb97',
                    fontFamily: 'Proxima Nova',
                    fontWeight: 'bold',
                }}
            >
                TECH
            </AreaFloatingText>
            <AreaFloatingText
                style={{
                    top: '85%',
                    left: '95%',
                    color: '#88e7e6',
                    fontFamily: 'Proxima Nova',
                    fontWeight: 'bold',
                }}
            >
                HUMAN
            </AreaFloatingText>

            {/*Floating texts in front of the wheel for each block*/}
            {Object.entries(WheelConfig).map(([section, config]) => (
                <BlockFloatingText
                    key={section}
                    top={config.textTop}
                    left={config.textLeft}
                    // @ts-ignore
                    block={blocksBySection[section]}
                    blurred={focusSection !== section && focusSection !== 'ALL'}
                    renderScore={renderScore}
                />
            ))}

            {/* Clickable sections. these are invisible polygons drawn on top of the wheel with event handlers attached
             to them. The shape of the polygons is obtained by the config using the "clip-path" css property. */}
            {Object.entries(WheelConfig).map(([section, config]) => (
                <div
                    key={section}
                    style={{
                        clipPath: config.clipPath,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: config.debugBackground,
                        opacity: DEBUG_OPACITY,
                        cursor: 'pointer',
                    }}
                    onMouseEnter={() => {
                        setFocusSection(section)
                    }}
                    onMouseLeave={() => {
                        setFocusSection('ALL')
                    }}
                    onTouchStart={() => {
                        setFocusSection(section)
                    }}
                    onClick={() => {
                        setTimeout(() => {
                            // @ts-ignore
                            setModalBlock(blocksBySection[section])
                        }, 150)
                    }}
                />
            ))}

            {modalBlock && renderModal(modalBlock, () => setModalBlock(null))}
        </div>
    )
}
