import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PreResultCircle from './PreResultCircle'

const StyledContainer = styled.div`
    margin: auto;
    width: 300px;

    @media only screen and (min-width: 767px) {
        width: 400px;
    }

    @media only screen and (min-width: 1024px) {
        width: 550px;
    }
`

const PreResultsCircles = props => {
    const { blocks, currentBlock, updateCurrentBlock } = props

    return (
        <StyledContainer>
            {/* <Grid container spacing={0} className="circles-grid"> */}
            {blocks.map((b, ii) => (
                // <Grid item xs={1} key={b.blockId}>
                <PreResultCircle
                    key={b.blockId}
                    index={ii}
                    block={b}
                    currentBlock={currentBlock}
                    updateCurrentBlock={updateCurrentBlock}
                />
                // </Grid>
            ))}
            {/* </Grid> */}
        </StyledContainer>
    )
}

PreResultsCircles.defaultProps = {
    blocks: [],
    currentBlock: {},
    updateCurrentBlock: () => {},
}

PreResultsCircles.propTypes = {
    blocks: PropTypes.array,
    currentBlock: PropTypes.object,
    updateCurrentBlock: PropTypes.func,
}

export default PreResultsCircles
