import React, { useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Page } from '../components/Page'
import UserContext from '../contexts/User'
import { OnboardingList } from '../components/Onboarding'
import BuildInfo from '../components/BuildInfo'
import BLOCKS_REFERENCE from '../references/blocks.json'
import LEVELS_REFERENCE from '../references/maturity_levels.json'
import { StyledButton } from '../components/Styles'
import AppBar from '../components/AppBar'

const BLOCKS = BLOCKS_REFERENCE.blocks
const LEVELS = LEVELS_REFERENCE.levels

const StyledPage = styled.div`
    position: relative;
    height: 100%;
    padding: 20px 20px 0px 20px;
    background: linear-gradient(210deg, #eaeaea 30%, #9bb8ad 120%);
    min-height: 100vh;

    .login {
        float: right;
    }

    .page-content {
        max-width: 780px;
        margin: 0 auto;

        .section-title {
            font-size: 24px;
            padding: 0px 30px 10px 0px;
            color: ${props => props.theme.blue};
            border-bottom: 2px solid ${props => props.theme.darkblue};
            display: inline-block;
            margin-bottom: 15px;
            text-align: center;
        }

        .section-title-body {
            font-size: 18px;
            padding: 0px 30px 5px 0px;
            color: ${props => props.theme.darkblue};
            border-bottom: 4px solid ${props => props.theme.blue};
            display: inline-block;
            margin: 10px 0px 10px 0px;
            text-align: center;
        }

        .section-title-body.digital-maturity {
            margin: 50px 0px 30px 0px;
        }
    }
`

const COPY = {
    header:
        'The Digital Acceleration Index (DAI) helps organizations assess their digital maturity. The DAI reveals digital strengths and weaknesses, determines whether digital capabilities are lacking or imbalanced, and evaluate how well they perform against peers in digital efforts.',
}

const AboutPage = props => {
    const user = useContext(UserContext)
    return (
        <Page {...props} background appbar={false}>
            {user.isAuthenticated && <AppBar {...props} />}
            <StyledPage>
                {!user.isAuthenticated && (
                    <div className="login">
                        <StyledButton
                            onClick={() => {
                                props.history.push('/')
                            }}
                            customClass="short narrow"
                        >
                            Login
                        </StyledButton>
                    </div>
                )}
                <div className="page-content">
                    <div className="section-title">About DAI</div>
                    <p>{COPY.header}</p>
                    <br />
                    <div className="section-title-body">{"DAI's main building blocks"}</div>
                    <Grid container spacing={0}>
                        {BLOCKS.map(block => (
                            <Grid item xs={12} sm={3} key={block.blockId}>
                                <AboutBlock block={block} />
                            </Grid>
                        ))}
                    </Grid>
                    <div className="section-title-body digital-maturity">
                        Digital maturity in 4 stages
                    </div>
                    {LEVELS.map(level => (
                        <Level key={level.levelId} level={level} />
                    ))}
                    <br />
                    <div className="section-title-body">How to use the application</div>
                    <br />
                    <OnboardingList />
                    <br />
                    <br />
                </div>
                <BuildInfo />
                <br />
                <br />
            </StyledPage>
        </Page>
    )
}

export default AboutPage

const StyledBlock = styled.div`
    padding: 10px;
    .block {
        min-height: 140px;
        margin: 0 auto;
        max-width: 780px;
        box-shadow: ${props => props.theme.shadowGrey};
        border-radius: 3px;
        color: ${props => props.theme.white};
        text-transform: uppercase;
        padding: 5px;
        position: relative;
        z-index: 2;
        -webkit-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;

        .block-top-title {
            font-size: 0.7rem;
            padding: 5px;
        }

        .block-title {
            min-height: 60px;
            font-size: 1.2rem;
            padding: 5px;
            font-weight: bold;
        }

        .block-description {
            font-size: 16px;
            color: ${props => props.theme.white};
            text-transform: none;
            padding: 5px;
        }
    }

    @media only screen and (min-width: 768px) {
        .block {
            min-height: 300px;
            .block-title {
                min-height: 100px;
            }
        }
    }
`

const AboutBlock = props => {
    const { block } = props
    const { properties } = block
    return (
        <StyledBlock>
            <div className="block" style={{ backgroundColor: properties.color }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div className="block-title">{block.title}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="block-description">{block.text}</div>
                    </Grid>
                </Grid>
            </div>
        </StyledBlock>
    )
}

AboutBlock.defaultProps = {
    block: {},
}

AboutBlock.propTypes = {
    block: PropTypes.object,
}

const StyledLevel = styled.div`
    margin: 0px 10px 15px 0;
    .title {
        font-weight: bold;
        padding-bottom: 2px;
        display: inline-block;
        width: 160px;
    }

    p {
        margin: 5px 0px;
    }
`

const Level = props => {
    const { level } = props
    return (
        <StyledLevel>
            <div className="title" style={{ borderBottom: `6px solid ${level.color}` }}>
                {level.title}
            </div>
            <p>{level.text}</p>
        </StyledLevel>
    )
}

Level.defaultProps = {
    level: {},
}

Level.propTypes = {
    level: PropTypes.object,
}
