import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { CardHead, CardBody } from '../Cards'
import QuestionOption from './Elements/QuestionOption'
import Stars from '../ImportanceStars'
import AmbitionBar from './Elements/AmbitionBar'
import { scrollTo } from '../../utils'

const StyledContainer = styled.div`
    width: 100%;
    max-width: 800px;
    padding: 5px;
    background-color: ${props => props.theme.lightgrey};
    border-radius: 3px;
    padding-bottom: 100px;
    z-index: 1;
    position: relative;
    margin: 0 auto;
    overflow-y: hidden;

    .slide-contents {
        max-width: 750px;
        margin: 0 auto;
        min-height: calc(100vh - 100px);
        padding: 15px;
        overflow: none;

        .question-overlap {
            height: 75px;
        }

        .question-title {
            text-align: center;
            padding: 0 1rem 1rem 1rem;
            font-size: 2rem;
        }
    }

    .icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 85px;
        color: ${props => props.theme.darkblue};
        line-height: 50px;
        font-size: 50px;
        text-align: center;
    }

    .arrow-bar {
        position: absolute;
        width: 55px;
        top: 0;
        bottom: 0;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .arrow-bar:hover {
        .icon {
            color: #ccc;
        }
    }

    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .transparent {
        background-image: none;
    }

    .desktop-only {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        .desktop-only {
            display: inherit;
        }
    }
`

const isNAOption = option => option.text === 'NA'
const isNotNAOption = option => !isNAOption(option)

function PrevButton(props) {
    return (
        <div
            className="arrow-bar left desktop-only"
            onClick={props.onClick}
            role="button"
            tabIndex={0}
        >
            <div className="icon right transparent" style={{ marginRight: '-20px' }}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </div>
        </div>
    )
}

function NextButton({ onClick }) {
    return (
        <div className="arrow-bar right desktop-only" onClick={onClick} role="button" tabIndex={-1}>
            <div className="icon left transparent" style={{ marginLeft: '-20px' }}>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
        </div>
    )
}

function Importance({ question, onSelect }) {
    return (
        <div>
            <CardBody>
                <br />
                <p
                    style={{
                        textAlign: 'center',
                        maxWidth: '300px',
                        margin: '0 auto',
                        color: '#5A5A5A',
                    }}
                >
                    Rate the importance of <strong>{question.title}</strong> for your organization
                </p>
                <br />
                <Stars starSize={30} selected={onSelect} center />
                <div style={{ height: '100px' }} />
            </CardBody>
        </div>
    )
}

function Ambition({ question, onSelect }) {
    return (
        <div>
            <CardBody>
                <br />
                <p
                    style={{
                        textAlign: 'center',
                        maxWidth: '300px',
                        margin: '0 auto',
                        color: '#5A5A5A',
                    }}
                >
                    What is the <strong>target maturity level</strong> of your organization for{' '}
                    <strong>{question.title}</strong>
                </p>
                <br />
                <AmbitionBar selected={onSelect} />
                <div style={{ height: '100px' }} />
            </CardBody>
        </div>
    )
}

class QuestionSlide extends Component {
    // select = option => {
    //     const optionSelected = option
    //     const { select, index, question } = this.props
    //     optionSelected.questionId = question.questionId
    //     // need to insert importance and ambition here
    //     const { importance, ambition } = this.state
    //     select(optionSelected, index)
    // }
    constructor(props) {
        super(props)
        this.state = {
            ambition: props.question.ambition,
            importance: props.question.importance,
            selectedOption: null,
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            selectedOption: null,
        })
    }

    select = () => {
        const { select, index, question } = this.props
        const { selectedOption, importance, ambition } = this.state
        selectedOption.questionId = question.questionId
        selectedOption.importance = importance
        selectedOption.ambition = ambition
        select(selectedOption, index)
        var timer = setTimeout(() => {
            this.setState({
                showAmbition: null,
            })
            clearTimeout(timer)
        }, 500)
    }

    prev = () => {
        const { changeIndex, index } = this.props
        if (index > 0) {
            changeIndex(index - 1)
        }
    }

    next = () => {
        const { changeIndex, index } = this.props
        const { block } = this.props
        if (index < block.questions.length - 1) {
            changeIndex(index + 1)
        }
    }

    selectOption = option => {
        if (isNAOption(option)) {
            this.setState(
                {
                    ambition: 1,
                    importance: 1,
                    selectedOption: option,
                    showAmbition: false,
                },
                () => {
                    this.select()
                    scrollTo(0, 200)
                }
            )
            return
        }

        this.setState(
            {
                selectedOption: option,
            },
            () => {
                const { index, question } = this.props
                const { selectedOption } = this.state
                selectedOption.questionId = question.questionId
                if (question.ambition) {
                    this.select()
                } else {
                    scrollTo(0, 200)
                    var timer = setTimeout(() => {
                        this.setState({
                            showAmbition: true,
                        })
                        clearTimeout(timer)
                    }, 500)
                }
            }
        )
    }

    importanceSelected = value => {
        var timer = setTimeout(() => {
            this.setState(
                {
                    importance: value,
                },
                () => {
                    scrollTo(0, 200)
                    clearTimeout(timer)
                }
            )
        }, 400)
    }

    ambitionSelected = value => {
        var timer = setTimeout(() => {
            this.setState(
                {
                    ambition: value,
                },
                () => {
                    this.select()
                    clearTimeout(timer)
                }
            )
        }, 100)
    }

    render() {
        const { question, block, index } = this.props
        const { selectedOption, importance, showAmbition } = this.state
        return (
            <div>
                <StyledContainer>
                    <div className="slide-contents">
                        <div className="question-overlap" />
                        <h3 className="question-title" style={{ color: block.color }}>
                            {question.title}
                        </h3>

                        {index > 0 && <PrevButton onClick={this.prev} />}
                        {index !== block.questions.length - 1 && <NextButton onClick={this.next} />}

                        <div>
                            <CardHead title={question.text} />

                            {question.importance === undefined && importance === undefined && (
                                <Importance
                                    question={question}
                                    onSelect={this.importanceSelected}
                                />
                            )}
                            {((question.ambition === undefined &&
                                !question.answer &&
                                showAmbition) ||
                                showAmbition) && (
                                <Ambition question={question} onSelect={this.ambitionSelected} />
                            )}
                        </div>

                        {(question.importance !== undefined || importance !== undefined) &&
                            !showAmbition && (
                                <React.Fragment>
                                    {question.options.filter(isNotNAOption).map((option, ii) => {
                                        if (
                                            selectedOption &&
                                            selectedOption.optionId === option.optionId
                                        ) {
                                            option.selected = true
                                        }
                                        return (
                                            <QuestionOption
                                                key={option.optionId}
                                                index={ii}
                                                option={option}
                                                select={this.selectOption}
                                            />
                                        )
                                    })}
                                </React.Fragment>
                            )}

                        <React.Fragment>
                            {question.options.filter(isNAOption).map((option, ii) => {
                                if (selectedOption && selectedOption.optionId === option.optionId) {
                                    option.selected = true
                                }
                                return (
                                    <QuestionOption
                                        key={option.optionId}
                                        index={ii}
                                        option={option}
                                        select={this.selectOption}
                                    />
                                )
                            })}
                        </React.Fragment>
                    </div>
                </StyledContainer>
            </div>
        )
    }
}

QuestionSlide.defaultProps = {
    question: {},
    block: {},
    select: () => {},
    index: 0,
    changeIndex: () => {},
}

QuestionSlide.propTypes = {
    question: PropTypes.object,
    block: PropTypes.object,
    select: PropTypes.func,
    index: PropTypes.number,
    changeIndex: PropTypes.func,
}

export default QuestionSlide
