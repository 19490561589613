import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ScrollAnim from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import COPY from '../../references/copy'

const { Element } = ScrollAnim

const StyledIntro = styled.div`
    .intro-section {
        padding-top: 50px;
        padding-bottom: 10px;
        height: 100%;
        background-color: ${props => props.theme.lightgrey};
    }

    .intro-title {
        position: relative;
        top: 30%;
        text-align: center;
    }

    .title {
        font-size: 1.5rem;
        color: ${props => props.theme.darkgrey};
    }

    .title-description {
        p {
            padding: 1em;
            font-size: 1.2rem;
            color: ${props => props.theme.darkgrey};
        }
    }

    .start-button {
        width: 200px;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    @media only screen and (max-width: 320px) {
        .intro-section {
            padding-top: 100px;
        }
    }
`

class PreSurveyIntro extends Component {
    startPresurvey = () => {
        const { start } = this.props
        start()
    }

    render() {
        return (
            <StyledIntro>
                <Element className="intro-section" key="intro-element">
                    <QueueAnim type="left" className="intro-title">
                        <div className="title" key="title">
                            <h2>{COPY.presurvey.intro}</h2>
                        </div>
                        <div className="title-description" key="title-description">
                            <p>{COPY.presurvey.description}</p>
                        </div>
                    </QueueAnim>
                </Element>
            </StyledIntro>
        )
    }
}

PreSurveyIntro.defaultProps = {
    start: () => {},
}

PreSurveyIntro.propTypes = {
    start: PropTypes.func,
}

export default PreSurveyIntro
