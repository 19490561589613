import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/pro-light-svg-icons'
import { StyledButton } from '../Styles'
import SimpleDialog from '../SimpleDialog'
import { FeedbackBarContentWrapper } from '../Feedback'
import { deletePresurveyAndSurvey } from '../../api'
import HistoryCard from './HistoryCard'
import { scrollTo, routePaths } from '../../utils'

const StyledContainer = styled.div``

const HistoryCardContainer = props => {
    const [dialogState, setDialogState] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState(null)
    const [surveyToDelete, setSurveyToDelete] = useState(null)
    const { historyItem, history } = props

    const continueSurvey = useCallback(() => {
        const {
            presurvey: {
                profile: { results },
                status,
                presurveyId,
            },
            survey,
        } = historyItem

        let path = null
        let id = null
        let idType = null
        if (status === 'COMPLETE') {
            if (results && typeof results.userScore === 'number') {
                path = 'results'
                id = survey.surveyId
            } else if (survey) {
                path = 'survey'
                id = survey.surveyId
            } else {
                path = 'presurvey'
                id = presurveyId
            }
        } else if (status === 'INCOMPLETE') {
            path = 'presurvey'
            id = presurveyId
        }

        idType = path === 'presurvey' ? 'presurveyId' : 'surveyId'
        history.push({
            pathname: routePaths[path],
            state: { [idType]: id },
        })
    }, [history, historyItem])

    const deleteSurvey = surveyToDelete => {
        setDialogState(true)
        setSurveyToDelete(surveyToDelete)
    }

    const toggleDialog = () => {
        setDialogState(!dialogState)
    }

    const confirmDelete = async () => {
        const res = await deletePresurveyAndSurvey(surveyToDelete.presurvey)
        let feedbackMessage = null
        if (res && res.status === 200) {
            setDialogState(false)
            setFeedbackMsg(feedbackMessage)
            const { updateHistory } = props
            updateHistory(surveyToDelete)
        } else {
            feedbackMessage = {
                type: 'error',
                text: 'There was an error deleting the survey. Please try again.',
            }
            setFeedbackMsg(feedbackMessage)
        }
    }

    const closeFeedbackMessage = () => {
        setFeedbackMsg(null)
    }

    return (
        <StyledContainer>
            <HistoryCard
                continueSurvey={continueSurvey}
                historyItem={historyItem}
                deleteSurvey={deleteSurvey}
            />
            <SimpleDialog dialogOpen={dialogState} closeDialog={toggleDialog}>
                <div className="dialog-icon-caution">
                    <FontAwesomeIcon icon={faMinus} />
                </div>
                <p style={{ padding: '15px' }}>Are you sure you want to delete this survey?</p>
                <div style={{ maxWidth: '250px', margin: '20px auto' }}>
                    <HistoryCard
                        continueSurvey={continueSurvey}
                        historyItem={surveyToDelete}
                        deleteSurvey={deleteSurvey}
                        readOnly
                    />
                </div>
                <div style={{ display: 'inline-block', margin: '5px' }}>
                    <StyledButton onClick={toggleDialog} customClass="narrow small">
                        Cancel
                    </StyledButton>
                </div>
                <div style={{ display: 'inline-block', margin: '5px' }}>
                    <StyledButton onClick={confirmDelete} customClass="narrow small caution">
                        Delete
                    </StyledButton>
                </div>
                {feedbackMsg ? (
                    <div style={{ padding: '15px' }}>
                        <FeedbackBarContentWrapper
                            message={feedbackMsg.text}
                            variant={feedbackMsg.type}
                            onClose={closeFeedbackMessage}
                        />
                    </div>
                ) : (
                    <React.Fragment />
                )}
            </SimpleDialog>
        </StyledContainer>
    )
}

HistoryCardContainer.defaultProps = {
    historyItem: {},
    continueSurvey: () => {},
    updateHistory: () => {},
}

HistoryCardContainer.propTypes = {
    historyItem: PropTypes.object,
    continueSurvey: PropTypes.func,
    updateHistory: PropTypes.func,
}

export default HistoryCardContainer
