import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'
import Block from './Elements/Block'
import QuestionSlide from './QuestionSlide'

const StyledContainer = styled.div`
    overflow-x: hidden;
    padding: 10px;
    background: linear-gradient(210deg, #eaeaea 30%, #9bb8ad 120%);
    width: 100%;

    .block-head {
        position: relative;
        z-index: 2 !important;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
        margin-bottom: -175px;
        max-width: 780px;
    }

    .block-head-question-buffer {
        height: 100px;
    }
`

class BlockQuestionsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0,
            swipeDelay: '0s',
            swipeDuration: '0.5s',
            sliderStyles: {
                root: {
                    maxWidth: '800px',
                    margin: '0 auto',
                    backgroundColor: '#f5f5f5',
                },
            },
        }
        this.container = React.createRef()
        this.scrollSpeed = 500
    }

    componentDidMount() {
        const { block } = this.props
        this.updateSwipeIndex(block)
    }

    componentWillReceiveProps() {
        const { block } = this.props
        this.updateSwipeIndex(block)
    }

    answerSelected = async (answer, index) => {
        // update block
        // move on to next question
        // scroll back to the top after each question
        const { block, finishedBlock, updateBlockQuestion } = this.props
        await updateBlockQuestion(block, answer)
        let nextIndex = index
        if (
            index + 1 === block.questions.length &&
            block.questions[block.questions.length - 1].answer
        ) {
            return this.setState(
                {
                    swipeDelay: '0s',
                    swipeDuration: '0s',
                },
                () => {
                    const timer = setTimeout(() => {
                        finishedBlock(block)
                        clearTimeout(timer)
                    }, 400)
                }
            )
        }
        if (!block.questions[index].answer) {
            return this.setState({ index, currentBlockId: block.blockId })
        }
        nextIndex += 1
        const time = setTimeout(() => {
            this.setState({
                index: nextIndex,
            })
            clearTimeout(time)
        }, 400)
        return time
    }

    handleChangeIndex = index => {
        const oldIndex = this.state.index
        const { block } = this.props
        this.setState(
            {
                index,
            },
            () => {
                if (index > oldIndex && !block.questions[oldIndex].answer) {
                    this.setState({
                        index: oldIndex,
                    })
                }
            }
        )
    }

    toggleBlockList = () => {
        const { toggleBlockList } = this.props
        this.setState(
            {
                index: 0,
                swipeDelay: '0s',
                swipeDuration: '0s',
            },
            () => {
                toggleBlockList()
            }
        )
    }

    updateSwipeIndex(block) {
        const { currentBlockId } = this.state
        if (block.blockId !== currentBlockId) {
            this.setState(
                {
                    currentBlockId: block.blockId,
                    index: 0,
                },
                () => {
                    let newIndex = 0
                    for (let i = 0; i < block.questions.length; i += 1) {
                        if (block.questions[i].answer) {
                            if (newIndex + 1 <= block.questions.length - 1) {
                                newIndex += 1
                            }
                        } else {
                            newIndex = i
                            break
                        }
                    }
                    this.setState({
                        showQuestions: true,
                        index: newIndex,
                        swipeDelay: '0s',
                        swipeDuration: '0.5s',
                    })
                }
            )
        } else {
            const { index } = this.state
            if (index < block.questions.length - 1) {
                this.setState({
                    index,
                    currentBlockId: block.blockId,
                })
            }
        }
    }

    render() {
        const { index, swipeDelay, swipeDuration, showQuestions, sliderStyles } = this.state
        const { block } = this.props
        return (
            <StyledContainer>
                <div className="block-head">
                    <Block
                        block={block}
                        progressindex={index}
                        listblock={false}
                        toggleBlockList={this.toggleBlockList}
                    />
                </div>
                <div className="block-head-question-buffer" />
                <SwipeableViews
                    index={index}
                    enableMouseEvents
                    axis="x"
                    ignoreNativeScroll
                    resistance
                    style={sliderStyles.root}
                    onChangeIndex={this.handleChangeIndex}
                    springConfig={{
                        duration: swipeDuration,
                        easeFunction: 'cubic-bezier(0.1, 0.35, 0.2, 1)',
                        delay: swipeDelay,
                    }}
                >
                    {showQuestions ? (
                        block.questions.map((q, ii) => (
                            <div key={q.questionId}>
                                <QuestionSlide
                                    index={ii}
                                    question={q}
                                    block={block}
                                    select={this.answerSelected}
                                    changeIndex={this.handleChangeIndex}
                                />
                            </div>
                        ))
                    ) : (
                        <React.Fragment />
                    )}
                </SwipeableViews>
            </StyledContainer>
        )
    }
}

BlockQuestionsContainer.defaultProps = {
    block: {},
    finishedBlock: () => {},
    updateBlockQuestion: () => {},
    toggleBlockList: () => {},
}

BlockQuestionsContainer.propTypes = {
    block: PropTypes.object,
    finishedBlock: PropTypes.func,
    updateBlockQuestion: PropTypes.func,
    toggleBlockList: PropTypes.func,
}

export default BlockQuestionsContainer
