import { getApi, postApi, BASE_URI, assertAuthentication, deleteApi } from '../utils'

const fetchPresurvey = async presurveyId => {
    const res = await getApi(`${BASE_URI}/presurvey/${presurveyId}`)
    return assertAuthentication(res)
}

const createPresurvey = async () => {
    const res = await postApi(`${BASE_URI}/presurvey`)
    return assertAuthentication(res)
}

const updatePresurveyQuestion = async (presurveyId, presurvey) => {
    const res = await postApi(`${BASE_URI}/presurvey/${presurveyId}/answers`, presurvey)
    return assertAuthentication(res)
}

const deletePresurveyAndSurvey = async presurvey => {
    const res = await deleteApi(`${BASE_URI}/presurvey/${presurvey.presurveyId}`)
    return assertAuthentication(res)
}

export { fetchPresurvey, createPresurvey, updatePresurveyQuestion, deletePresurveyAndSurvey }
