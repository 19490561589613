import { getApi, postApi, BASE_URI } from '../utils'

const getFeedbackTypes = async () => {
    const res = await getApi(`${BASE_URI}/feedback/types`)
    return res
}

const postFeedback = async feedbackObj => {
    const res = await postApi(`${BASE_URI}/feedback/`, feedbackObj)
    return res
}

export { getFeedbackTypes, postFeedback }
