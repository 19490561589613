import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getScoreColor } from '../../../utils'

const StyledContainer = styled.div`
    width: 29px;
    height: 29px;
    font-size: 1rem;
    text-align: center;
    border-radius: 100%;
    border: 2px solid ${props => props.theme.grey};
    color: ${props => props.theme.grey};
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
    margin: 4px;
    line-height: 26px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    @media only screen and (min-width: 767px) {
        margin: 4px 10px 4px 10px;
    }

    @media only screen and (min-width: 1024px) {
        margin: 4px 13px 4px 13px;
    }

    @media only screen and (max-width: 320px) {
        width: 25px;
        height: 25px;
        line-height: 21px;
        font-size: 0.9rem;
    }

    @media only screen and (min-width: 1050px) {
        width: 35px;
        height: 35px;
        line-height: 33px;
        font-size: 18px;
        margin: 6px 12px 6px 13px;
    }
`

class PreResultCircle extends Component {
    updateCurrentBlock = () => {
        const { block, index, updateCurrentBlock } = this.props
        updateCurrentBlock(block, index)
    }

    render() {
        const { block, currentBlock } = this.props
        const currentBlockColor = currentBlock.properties ? currentBlock.properties.color : '#ccc'
        const selectedColor = currentBlock.preresults
            ? getScoreColor(currentBlock.preresults.userScore / 100)
            : currentBlockColor
        const { preresults } = block
        let score = null
        if (preresults) {
            score = preresults.userScore >= 0 ? preresults.userScore.toString() : 'N/A'
        }
        return (
            <StyledContainer
                style={{
                    color: currentBlock.blockId === block.blockId ? selectedColor : '#ccc',
                    border:
                        currentBlock.blockId === block.blockId
                            ? `2px solid ${selectedColor}`
                            : '1px solid #ccc',
                }}
                onClick={this.updateCurrentBlock}
            >
                {score ? <div>{score}</div> : ''}
            </StyledContainer>
        )
    }
}

PreResultCircle.defaultProps = {
    block: {},
    currentBlock: {},
    updateCurrentBlock: () => {},
    index: 0,
}

PreResultCircle.propTypes = {
    block: PropTypes.object,
    currentBlock: PropTypes.object,
    updateCurrentBlock: PropTypes.func,
    index: PropTypes.number,
}

export default PreResultCircle
