import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

const StyledCardHead = styled.div`
    pointer-events: none;
    .card-container {
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.bluegrey};
        box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
        position: relative;
        border-radius: 3px;
        margin-bottom: 1rem;
        position: relative;
        line-height: 1.5rem;
        padding-top: 15px;
        padding-bottom: 10px;

        .indicator {
            height: 100%;
            position: relative;
            text-align: right;
            padding-left: 15px;
        }

        .title {
            padding: 0 15px 10px 15px;
            display: inline-block;
            vertical-align: top;
            position: relative;
        }

        .arrow {
            position: absolute;
            bottom: -9px;
            content: '';
            border-top: 10px solid #29323c;
            border-right: 20px solid transparent;
            border-left: 20px solid transparent;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 20px;
            -webkit-transform: translate3d(0, 0, 0);
            -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
            transform: translate3d(0, 0, 0);
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
        }

        .arrow-up {
            position: absolute;
            top: -9px;
            content: '';
            border-bottom: 10px solid #29323c;
            border-right: 20px solid transparent;
            border-left: 20px solid transparent;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 20px;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate(0, 0, 0);
        }
    }
`

const CardHead = props => {
    const { indicator, title, optional, arrow } = props
    return (
        <StyledCardHead>
            <div className="card-container">
                <Grid container spacing={8}>
                    {indicator ? (
                        <Grid style={{ padding: 0 }} item xs={1}>
                            <div className="indicator">{indicator}</div>
                        </Grid>
                    ) : (
                        <React.Fragment />
                    )}
                    <Grid style={{ padding: 0 }} item xs={indicator ? 11 : 12}>
                        <div className="title">
                            {title}
                            <span className="optional">{optional ? ' (Optional)' : ''}</span>
                        </div>
                    </Grid>
                </Grid>
                <div className={arrow === 'up' ? 'arrow-up' : 'arrow'} />
            </div>
        </StyledCardHead>
    )
}

CardHead.defaultProps = {
    indicator: 0,
    title: '',
    optional: false,
    arrow: '',
}

CardHead.propTypes = {
    indicator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    optional: PropTypes.bool,
    arrow: PropTypes.string,
}

export default CardHead
