import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
    .red {
        background-color: red !important;
    }

    .narrow {
        height: 35px;
        line-height: 5px;
    }

    .thick {
        height: 50px;
        line-height: 15px;
    }

    .short {
        max-width: 100px;
    }

    .medium {
        max-width: 200px;
    }

    .center {
        margin: 0 auto;
    }

    .bold {
        font-weight: bold;
    }

    .caution {
        background: red !important;
    }

    .disabled {
        background: #ccc !important;
        &:hover {
            background: #ccc !important;
        }
    }
`

const StyledButton = styled(Button)`
    background-color: ${props => props.theme.darkblue} !important;
    color: ${props => props.theme.white} !important;
    box-shadow: 0px 2px 4px ${props => props.theme.darkblue};
    width: 100%;
    display: block !important;
    border-radius: 3px !important;
    padding: 1rem !important;
    font-size: 1.2rem !important;
    font-family: 'bcg', 'Helvetica Neue', 'sans-serif';
    text-transform: none !important;
    font-weight: lighter;

    &:hover {
        background-color: #6e7e86 !important;
        color: ${props => props.theme.white} !important;
    }

    .bionic & {
        border-radius: 5px !important;
        background-color: #146fe7 !important;
        font-size: 12px !important;
        font-weight: bold !important;
        font-family: 'Proxima Nova' !important;
        text-transform: uppercase !important;
        height: 45px !important;

        &.bionic-dark {
            background-color: #17304f !important;
            text-transform: none !important;
            font-size: 19px !important;
            font-weight: normal !important;
        }
    }
`

const CustomButton = props => {
    const { children, customClass, onClick, type, disabled } = props
    return (
        <ButtonWrapper>
            <StyledButton
                type={type || 'button'}
                disabled={disabled}
                onClick={onClick}
                className={customClass}
            >
                {children}
            </StyledButton>
        </ButtonWrapper>
    )
}

CustomButton.defaultProps = {
    customClass: '',
    onClick: () => {},
    children: {},
}

CustomButton.propTypes = {
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CustomButton
