import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import logo from '../../assets/logo/logo_tm.png'

const HeaderContainer = styled.div`
    position: relative;
    width: 100%;
    margin-top: 50px;
    .image-wrapper {
        width: 300px;
        margin: 0px auto;
        img {
            width: 100%;
        }
    }

    .header {
        width: 300px;
        margin: 0 auto;
        text-align: left;
        font-size: 20px;
        color: #10223a;
    }

    @media only screen and (min-width: 768px) {
        .image-wrapper {
            width: 350px;
        }
        .header {
            width: 330px;
        }
    }
`

const HeaderLogo = props => {
    const { text } = props
    return (
        <HeaderContainer>
            <br />
            <div className="image-wrapper">
                <img alt="logo" src={logo} />
            </div>
            {!text || text === '' ? (
                <React.Fragment />
            ) : (
                <div>
                    <br />
                    <p className="header" dangerouslySetInnerHTML={{ __html: `${text}` }} />
                    <br />
                    <br />
                </div>
            )}
        </HeaderContainer>
    )
}

HeaderLogo.defaultProps = {
    text: '',
}

HeaderLogo.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default HeaderLogo
