import { getApi, BASE_URI } from '../utils'

const getAllCompanies = async ({ limit = 30 } = {}) => {
    const res = await getApi(`${BASE_URI}/companies?limit=${limit}`)
    return res
}

const searchCompanies = async term => {
    const res = await getApi(`${BASE_URI}/companies?term=${term}`)
    if (res.status === 200) {
        return res.data
    } else {
        return { data: [] }
    }
}

export { getAllCompanies, searchCompanies }
