import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

const StyledBlock = styled.div`
    border-radius: 3px;
    .block {
        min-height: 140px;
        margin: 1rem auto;
        max-width: 500px !important;
        box-shadow: ${props => props.theme.shadowGrey};
        border-radius: 3px;
        color: ${props => props.theme.white};
        text-transform: uppercase;
        padding: 5px;
        position: relative;
        z-index: 2;
        -webkit-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;

        .block-top-title {
            font-size: 0.7rem;
            padding: 5px;
        }

        .block-title {
            font-size: 1.2rem;
            padding: 5px;
            font-weight: bold;
        }

        .result-number {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            font-size: 1.5rem;
            text-align: center;
            line-height: 45px;
            border: 2px solid ${props => props.theme.white};
            color: ${props => props.theme.white};
            float: right;
            margin: 5px 5px;
            cursor: pointer;
        }

        .result-descriptor {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 15px;
            text-transform: none;
            font-size: 1rem;
        }
    }

    @media only screen and (min-width: 660px) {
        .block {
            min-height: 230px;
            text-align: center;
            .result-number {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                font-size: 1.5rem;
                text-align: center;
                line-height: 45px;
                border: 2px solid ${props => props.theme.white};
                color: ${props => props.theme.white};
                cursor: pointer;
                margin: 0 auto;
                position: absolute;
                bottom: 40px;
                left: 0;
                right: 0;
                bottom: 70px;
            }
            .result-descriptor {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 15px;
                text-transform: none;
                font-size: 1rem;
            }
        }
    }
`
class BlockCompleted extends Component {
    state = {
        blockLoaded: 0,
    }

    componentDidMount() {
        this.setState({
            blockLoaded: 1,
        })
    }

    select = () => {
        const { select, block } = this.props
        select(block)
    }

    render() {
        const { block } = this.props
        const { blockLoaded } = this.state
        const userScoreColor = 'white'
        const { properties, preresults } = block
        const score = preresults.userScore >= 0 ? preresults.userScore : 'N/A'
        return (
            <StyledBlock
                onClick={this.select}
                style={{ backgroundColor: properties.color, opacity: blockLoaded }}
            >
                <div className="block">
                    <Grid container spacing={0}>
                        <Grid item xs={9} sm={12}>
                            <div className="block-title">{block.title}</div>
                        </Grid>
                        <Grid item xs={3} sm={12}>
                            <div
                                className="result-number"
                                style={{
                                    border: `2px solid ${userScoreColor}`,
                                    color: userScoreColor,
                                }}
                            >
                                {score}
                            </div>
                        </Grid>
                    </Grid>
                    <div className="block-stats" style={{ opacity: 1 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} className="result-descriptor">
                                <div>{block.preresults.descriptor}</div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </StyledBlock>
        )
    }
}

BlockCompleted.defaultProps = {
    block: {},
    select: () => {},
}

BlockCompleted.propTypes = {
    block: PropTypes.object,
    select: PropTypes.func,
}

export default BlockCompleted
