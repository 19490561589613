import React from 'react'
import { Switch, Route, Router } from 'react-router'
import PropTypes from 'prop-types'
import { routePaths } from '../utils'

// import app pages
import PrivateRoute from '../components/PrivateRoute'
import HomePage from '../pages/HomePage'
import SigninPage from '../pages/SigninPage'
import SurveyPage from '../pages/SurveyPage'
import UnauthenticatedPage from '../pages/UnauthenticatedPage'
import NotFoundPage from '../pages/NotFoundPage'
import ContactPage from '../pages/ContactPage'
import HistoryPage from '../pages/HistoryPage'
import PreSurveyPage from '../pages/PreSurveyPage'
import ResultsPage from '../pages/ResultsPage'
import AboutPage from '../pages/AboutPage'
import AccountSettingsPage from '../pages/AccountSettingsPage'
// import user context
import { UserProvider } from '../contexts/User'

const AppRouter = props => {
    const { history } = props
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={routePaths.signin}>
                    <UserProvider>
                        <SigninPage {...props} />
                    </UserProvider>
                </Route>
                <Route exact path={routePaths.unauthenticated} component={UnauthenticatedPage} />
                <PrivateRoute exact path={routePaths.about} component={AboutPage} />
                <PrivateRoute exact path={routePaths.home} component={HomePage} />
                <PrivateRoute exact path={routePaths.contact} component={ContactPage} />
                <PrivateRoute exact path={routePaths.settings} component={AccountSettingsPage} />
                <PrivateRoute exact path={routePaths.presurvey} component={PreSurveyPage} />
                <PrivateRoute exact path={routePaths.survey} component={SurveyPage} />
                <PrivateRoute exact path={routePaths.results} component={ResultsPage} />
                <PrivateRoute exact path={routePaths.history} component={HistoryPage} />
                <PrivateRoute exact path={routePaths.howto} component={HistoryPage} />
                <Route component={NotFoundPage} />
            </Switch>
        </Router>
    )
}

AppRouter.defaultProps = {
    history: {},
}

AppRouter.propTypes = {
    history: PropTypes.object,
}

export default AppRouter
