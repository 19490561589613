import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TweenOne from 'rc-tween-one'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { Grid, ButtonBase, Collapse } from '@material-ui/core'
import { ResultBar } from '../Results'
import BenchmarkDetails from './BenchmarkDetails'

const StyledContainer = styled.div`
    margin: 1rem auto;
    border-radius: 3px;
    position: relative;
    z-index: 2;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;

    .tween-one {
        opacity: 0;
    }

    .row-title {
        font-size: 1rem;
        padding: 5px;
        color: ${props => props.theme.darkgrey};
        font-weight: bold;
        text-transform: uppercase;
        white-space: pre-wrap;
        text-align: center;
        cursor: pointer;
    }

    .benchmark {
        float: right;
        .number {
            font-size: 1.2rem;
            text-align: right;
            line-height: 45px;
            color: ${props => props.theme.darkgrey};
            font-weight: bold;
            cursor: pointer;
            display: inline-block;
            margin-right: 10px;
        }
        .row-arrow {
            border-radius: 3px;
            min-width: 0 !important;
            padding: 0;
            font-size: 1.5rem;
            display: inline-block;
            margin: 5px auto;
            color: ${props => props.theme.darkgrey};
        }
    }

    .user-score {
        float: left;

        .number {
            font-size: 1.2rem;
            text-align: right;
            line-height: 45px;
            color: ${props => props.theme.darkgrey};
            font-weight: bold;
            cursor: pointer;
            display: inline-block;
            margin-left: 10px;
        }
        .row-arrow {
            border-radius: 3px;
            min-width: 0 !important;
            padding: 0;
            font-size: 1.5rem;
            display: inline-block;
            margin: 5px auto;
            color: ${props => props.theme.darkgrey};
        }
    }

    .middle-title {
        display: none;
    }

    @media only screen and (min-width: 1024px) {
        .middle-title {
            display: inherit;
            cursor: pointer;
            .middle-title-text {
                font-size: 1rem;
                padding: 5px;
                color: ${props => props.theme.darkgrey};
                font-weight: bold;
                text-transform: uppercase;
                white-space: pre-wrap;
                text-align: center;
                margin-top: -10px;
                width: 100%;
            }
        }
        .row-title {
            display: none;
        }
    }
`

class BenchmarkContainer extends Component {
    state = {
        open: false,
        color: false,
    }

    componentWillReceiveProps(newProps) {
        const { block } = this.props
        const { open } = this.state
        if (!newProps.currentBlock) {
            return
        }
        if (newProps.currentBlock.blockId === block.blockId && !open) {
            this.open()
        } else {
            this.close()
        }
    }

    toggleOpen = () => {
        const { block, toggleOpen } = this.props
        toggleOpen(block)
    }

    open = () => {
        const { block } = this.props
        const { properties } = block
        const { color } = properties
        this.setState({ open: true, color })
    }

    close = () => {
        this.setState({ open: false, color: false })
    }

    render() {
        const { block, currentBlock, fade } = this.props
        const { open, color } = this.state
        const { preresults } = block
        const userScore = preresults && preresults.userScore >= 0 ? preresults.userScore : 'N/A'
        return (
            <StyledContainer onClick={this.toggleOpen}>
                <TweenOne
                    className="tween-one"
                    key="0"
                    animation={{
                        opacity:
                            currentBlock && fade === 'all' && block.blockId !== currentBlock.blockId
                                ? 0.5
                                : 1,
                        duration: 450,
                    }}
                >
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={3}>
                            <div className="user-score">
                                <ButtonBase className="row-arrow" onClick={this.toggleOpen}>
                                    {open ? (
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    ) : (
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    )}
                                </ButtonBase>
                                <div className="number">{userScore}</div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="row-title">{block.title}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="benchmark">
                                <div className="number">
                                    {block.benchmark ? block.benchmark.benchmarkScore : 'N/A'}
                                </div>
                                <ButtonBase className="row-arrow" onClick={this.toggleOpen}>
                                    {open ? (
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    ) : (
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    )}
                                </ButtonBase>
                            </div>
                        </Grid>
                        <Grid item xs={12} className="row-row">
                            <Grid container spacing={16} alignItems="center">
                                <Grid item xs={6} sm={6} md={4}>
                                    <ResultBar
                                        left
                                        fill={userScore >= 0 ? userScore : 0}
                                        color={color}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} className="middle-title">
                                    <div className="middle-title-text">{block.title}</div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <ResultBar
                                        fill={block.benchmark ? block.benchmark.benchmarkScore : 0}
                                        color={color}
                                    />
                                </Grid>
                            </Grid>
                            <Collapse in={open}>
                                <BenchmarkDetails block={block} />
                            </Collapse>
                        </Grid>
                    </Grid>
                </TweenOne>
            </StyledContainer>
        )
    }
}

BenchmarkContainer.defaultProps = {
    block: {},
    toggleOpen: () => {},
    currentBlock: {},
    fade: 'start',
}

BenchmarkContainer.propTypes = {
    block: PropTypes.object,
    toggleOpen: PropTypes.func,
    currentBlock: PropTypes.object,
    fade: PropTypes.string,
}

export default BenchmarkContainer
