import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import AppBar from '../AppBar'
import { StyledBackground } from '../Styles'
import { CopyProvider, CopyConsumer } from '../../contexts/Copy'
import CookiesConsent from '../CookiesConsent'

const theme = {
    red: 'red',
    white: '#FFFFFF',
    darkblue: '#10223A',
    darkblueGradient: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
    greenGrayGradient: 'linear-gradient(60deg, #9BB8AD 0%, #9A9A9A 100%)',
    darkgreenGray: 'linear-gradient(60deg, #1D7352 0%, #9B9B9B 100%)',
    darkblueText: '#29323c',
    bluegrey: '#29323c',
    lightgrayGradient: 'linear-gradient(60deg, rgb(222, 222, 222) 0%, rgb(249, 252, 255) 100%',
    greenToGray: 'linear-gradient(50deg, rgba(25,122,86, 0.9), rgba(183, 183, 183, 0.3))',
    grayToGray: 'linear-gradient(50deg,  rgba(16,34,58, 0.6), rgba(37, 71, 108, .27))',
    silverGray: 'rgba(16,34,58, 0.6)',
    grey: '#dedede',
    darkgrey: '#29323c',
    // lightgrey is used for company profile background
    // lightgrey is used for triangles in answer cards
    lightgrey: '#f5f5f5',
    selectedGrey: '#9ca4ad',
    shadowGrey: '0px 2px 4px rgba(0, 0, 0, 0.3)',
    shadowGreyLight: '#aeb4b9',
    bcgGreen: '#00512F',
    textGrey: '5A5A5A',
}

const StyledPage = styled.div`
    color: ${props => props.theme.black};
    min-height: 100vh;

    .loading-icon-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        color: ${props => props.theme.bcgGreen};
    }
`

const Inner = styled.div`
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
`

const Page = props => {
    const { children, appbar, background, page } = props
    return (
        <ThemeProvider theme={theme}>
            <CopyProvider>
                <StyledPage>
                    {background && <StyledBackground page={page} />}
                    {appbar && <AppBar {...props} />}
                    <Inner>{children}</Inner>
                    {/* <CookiesConsent /> */}
                </StyledPage>
            </CopyProvider>
        </ThemeProvider>
    )
}

Page.defaultProps = {
    appbar: false,
    children: [],
    background: false,
    page: '',
}

Page.propTypes = {
    appbar: PropTypes.bool,
    background: PropTypes.bool,
    page: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default Page
