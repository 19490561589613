import React, { Component } from 'react'
import styled from 'styled-components'
import { scrollTo, clone } from '../../utils'
import GoalTopBanner from './GoalTopBanner'
import GoalContainer from './GoalContainer'
import QueueAnim from 'rc-queue-anim'
import FilterBar from '../Filters/FilterBar'
import { Block } from '../Results/ResultsDefinition'
import { BionicToggleProps } from '../Bionic/BionicToggleRow'
import GoalsBionicWheel from './GoalsBionicWheel'
import { ResultsWithFilters } from '../../pages/ResultsPage'

const StyledContainer = styled.div`
    position: relative;
    -webkit-overflow-scrolling: touch;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    backface-visibility: hidden;
    padding: 15px;
    width: 100%;
`

type Props = {
    results: ResultsWithFilters,
    applyFilters: (filters: any) => void,
    resetFilters: (filters: any) => void,
    currentIndex: number,
} & BionicToggleProps

type State = {
    show: boolean,
    showCount: number,
    currentBlock: Partial<Block>,
    fade: string,
    fadeTicker: boolean,
}

class GoalsContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            show: true,
            showCount: 0,
            currentBlock: {},
            fade: 'start',
            fadeTicker: false,
        }
    }

    componentWillReceiveProps(newProps: Props) {
        // we use showCount to trigger animations when the user first sees container
        const { showCount } = this.state
        scrollTo(1, 0)
        const { currentIndex } = newProps
        this.setState({ currentBlock: {}, fade: 'none' })
        if (showCount < 1 && currentIndex == 2) {
            this.setState({ show: false }, () => {
                this.setState({ show: true, showCount: 1 })
            })
        }
    }

    toggleOpen = (block: Block) => {
        // fadeTicker helps to keep track if single  block is being toggle repeatedly
        const { currentBlock, fadeTicker } = this.state
        if (block.blockId === currentBlock.blockId) {
            if (fadeTicker) {
                this.setState({ currentBlock: block, fade: 'all', fadeTicker: false })
            } else {
                this.setState({ currentBlock: block, fade: 'none', fadeTicker: true })
            }
        } else {
            this.setState({ currentBlock: block, fade: 'all', fadeTicker: false })
        }
    }

    render() {
        const { currentBlock, fade, show } = this.state
        const { results, applyFilters, resetFilters, bionic, setBionic } = this.props
        const sortedBlocks = clone(this.props.results.blocks).sort(
            (a: Block, b: Block) => b.importance - a.importance
        )
        return (
            <StyledContainer>
                {show && (
                    <>
                        <div style={{ margin: '50px auto 0' }}>
                            <GoalTopBanner bionic={bionic} />
                        </div>
                        <FilterBar
                            results={results}
                            applyFilters={applyFilters}
                            resetFilters={resetFilters}
                            bionic={bionic}
                            setBionic={setBionic}
                        />
                        {bionic && <GoalsBionicWheel results={results} />}
                        {!bionic && (
                            <QueueAnim type="left" key="anim" interval={50}>
                                {sortedBlocks.map((b: Block) => (
                                    <GoalContainer
                                        key={b.blockId}
                                        block={b}
                                        toggleOpen={this.toggleOpen}
                                        currentBlock={currentBlock}
                                        fade={fade}
                                    />
                                ))}
                            </QueueAnim>
                        )}
                    </>
                )}
            </StyledContainer>
        )
    }
}

export default GoalsContainer
