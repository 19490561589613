import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import rightArrow from '../../../assets/customIcons/right_arrow.png'

const StyledContainer = styled.div`
    padding: 10px 10px 0px 10px;
    height: 100%;
    color: ${props => props.theme.white};
    // position: relative;

    .title {
        font-weight: bold;
        text-transform: uppercase;
    }

    .incomplete {
        margin-top: 10px;
    }

    .block-top-title {
        font-size: 0.7rem;
        padding: 5px 0px 5px 0px;
        text-transform: uppercase;
    }

    .quick-start-row {
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        position: absolute;

        .btn-title {
            position: absolute;
            left: 10px;
            bottom: 20px;
            text-transform: uppercase;
            font-size: 1rem;
        }

        .btn-icon {
            vertical-align: top;
            float: right;
            min-width: 50px !important;
            width: 50px;
        }

        button {
            padding: 0;
        }
    }
`

class BlockQuickStart extends Component {
    select = () => {
        const { block, select } = this.props
        select(block)
    }

    render() {
        const { block } = this.props
        return (
            <StyledContainer onClick={this.select}>
                <div className="title">{block.title}</div>
                <div className="quick-start-row">
                    <div className="btn-title">Quick start</div>
                    <Button className="btn-icon">
                        <img alt="quick-start" src={rightArrow} width="50" height="50" />
                    </Button>
                </div>
            </StyledContainer>
        )
    }
}

BlockQuickStart.defaultProps = {
    block: {},
    select: () => {},
}

BlockQuickStart.propTypes = {
    block: PropTypes.object,
    select: PropTypes.func,
}

export default BlockQuickStart
