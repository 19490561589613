import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BarGraph from '../Elements/BarGraph'

const StyledContainer = styled.div`
    padding: 10px 10px 0px 10px;
    height: 100%;
    // position: relative;

    .title {
        font-weight: bold;
        color: ${props => props.theme.darkblueText};
        text-transform: uppercase;
    }

    .graphs {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px 10px 5px 10px;
    }

    .graphs-space {
        height: 100px;
    }

    @media only screen and (min-width: 768px) {
        .title {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 321px) {
        .title {
            font-size: 14px;
        }
    }
`

const PreResultGraph = props => {
    const { block, profile } = props
    return (
        <StyledContainer>
            <div className="title" dangerouslySetInnerHTML={{ __html: `${block.title}` }} />
            <div className="graphs">
                <BarGraph
                    key="average"
                    fill={block.benchmark.benchmarkScore}
                    title={`Average for ${profile.industry || 'your'} industry`}
                />
                <BarGraph key="user" fill={block.preresults.userScore} title="Your score" />
            </div>
            <div className="graphs-space" />
        </StyledContainer>
    )
}

PreResultGraph.defaultProps = {
    profile: {},
    block: {},
}

PreResultGraph.propTypes = {
    profile: PropTypes.object,
    block: PropTypes.object,
}

export default PreResultGraph
