import React, { Component } from 'react'
import styled from 'styled-components'

const StyledBar = styled.div`
    width: 25px;
    height: 5px;
    display: inline-block;
    vertical-align: top;
    margin-right: 2px;
    margin-left: 2px;
    background-color: white;
`

class ProgressBar extends Component {
    select = () => {
        const { question, current, listblock, selected } = this.props
        if (listblock) {
            return
        }
    }

    render() {
        const { question, current, listblock } = this.props
        return (
            <StyledBar
                onClick={this.select}
                style={{
                    opacity: question.answer ? 1 : 0.5,
                    backgroundColor: current && !listblock ? 'black' : 'white',
                }}
            />
        )
    }
}

export default ProgressBar
