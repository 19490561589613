import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledCardBody = styled.div`
    border-radius: 3px;
    box-shadow: 0px 3px 4px ${props => props.theme.grey};

    .wrap {
        position: relative;
        margin: 0 auto;
        cursor: pointer;

        .content {
            padding: 5px 10px 8px 10px;
            border-radius: 3px;
            font-size: 1.2rem;
            text-align: left;
            background: ${props => props.theme.white};
            b {
                color: ${props => props.theme.darkblue};
            }
        }
    }

    .selected {
        .content {
            background-color: ${props => props.theme.selectedGrey};
            color: ${props => props.theme.white};
            border-radius: 3px;
        }
    }

    .dropdown {
        .content {
            padding: 0 !important;
        }
    }

    .card-hover {
    }

    .arrow-triangle {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${props => props.theme.lightgrey};
    }
    .arrow-triangle:before,
    .arrow-triangle:after {
        content: '';
        position: absolute;
        top: 0;
        box-sizing: border-box;
    }
    .arrow-triangle:before {
        right: 50%;
        border-top: 10px solid ${props => props.theme.lightgrey};
        border-left: 20px solid transparent;
    }
    .arrow-triangle:after {
        left: 50%;
        border-top: 10px solid ${props => props.theme.lightgrey};
        border-right: 20px solid transparent;
    }

    .arrow-triangle-right {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${props => props.theme.lightgrey};
    }
    .arrow-triangle-right:before {
        content: '';
        position: absolute;
        top: -1px;
        box-sizing: border-box;
        right: -1px;
        border-top: 10px solid ${props => props.theme.lightgrey};
        border-left: 20px solid transparent;
    }

    .arrow-triangle-left {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${props => props.theme.lightgrey};
    }
    .arrow-triangle-left:after {
        content: '';
        position: absolute;
        top: -1px;
        box-sizing: border-box;
        left: -1px;
        border-top: 10px solid ${props => props.theme.lightgrey};
        border-right: 20px solid transparent;
    }

    .bionic & {
        box-shadow: none;

        .wrap .content {
            border-radius: 4px;
            background-color: #90afbc;
            color: #15202c;
            padding: 2px 30px 5px;
        }

        .selected .content {
            background-color: #15202c;
            color: #fff;
        }
    }
`

const CardBody = props => {
    const { index, short, selected, children, noDecoration } = props

    const mobile = window.innerWidth < 500
    return (
        <StyledCardBody>
            <div className={`wrap${selected ? ' selected' : ''}`}>
                <div className="content">
                    {!noDecoration && index === 0 && (!short || mobile) ? (
                        <div className="arrow-triangle" />
                    ) : (
                        <React.Fragment />
                    )}
                    {!noDecoration && index === 0 && short && !mobile ? (
                        <div className="arrow-triangle-right" />
                    ) : (
                        <React.Fragment />
                    )}
                    {!noDecoration && index === 1 && short && !mobile ? (
                        <div className="arrow-triangle-left" />
                    ) : (
                        <React.Fragment />
                    )}
                    <div style={{ height: '10px' }} />
                    {children}
                </div>
            </div>
        </StyledCardBody>
    )
}

CardBody.defaultProps = {
    index: 0,
    short: false,
    selected: false,
    noDecoration: false,
    children: [],
}

CardBody.propTypes = {
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    short: PropTypes.bool,
    selected: PropTypes.bool,
    noDecoration: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default CardBody
