import React, { Component } from 'react'
import { Provider } from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import createHashHistory from 'history/createHashHistory'
import AppRouter from './router'
import store from './store'
import { GlobalStyles } from './components/Styles'
import { loadReCaptcha } from './components/GoogleRecaptcha'
import { GoogleSiteKey, getBrowser } from './utils'

// check browser compatibility
// ie < 10 takes issue with browser history push method so replace with hash
// ie < 10 teakes issue with certain methods and rendering so it is not compatible
// ie < 10 will result in an incompatiblity message on landing page
const browser = getBrowser()
let browserCompatible = true
let history
if (browser.name === 'MSIE' && parseInt(browser.version, 10) < 10) {
    history = createHashHistory()
    browserCompatible = false
} else {
    history = createHistory()
}

// load global styles
// add redux store - redux is not being used in this application, but if there is a need to use it in the future a provider is provided
// initiate routes
class App extends Component {
    componentDidMount() {
        if (browserCompatible) {
            loadReCaptcha(GoogleSiteKey)
            history.listen(location => {
                // eslint-disable-next-line no-undef
                window.dispatchEvent(new CustomEvent('routechange', { detail: location }))
            })
        }
    }

    render() {
        return (
            <div>
                <GlobalStyles />
                <Provider store={store}>
                    <AppRouter history={history} />
                </Provider>
            </div>
        )
    }
}

export default App
