import React, { Component } from 'react'
import styled from 'styled-components'
import { ButtonBase } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faUser,
    faCheck,
    faTimes,
    faMinus,
    faBuilding,
} from '@fortawesome/pro-light-svg-icons'
import { updatePassword, deleteAccount } from '../api'
import UserContext from '../contexts/User'
import { Page } from '../components/Page'
import { StyledButton } from '../components/Styles'
import { FeedbackBarContentWrapper } from '../components/Feedback'
import SimpleDialog from '../components/SimpleDialog'
import { SimpleInput, CreatePassword, PasswordInput } from '../components/FormElements'
import { CopyConsumer } from '../contexts/Copy'
import { MIN_PASSWORD_LENGTH, MIN_PASSWORD_STRENGTH } from '../utils/config'

const StyledPage = styled.div`
    position: relative;
    padding: 20px;
    min-height: calc(100vh - 56px);
    background: linear-gradient(210deg, #eaeaea 30%, #9bb8ad 120%);
    min-height: 100vh;

    .section-title {
        font-size: 24px;
        padding: 0px 30px 10px 0px;
        color: ${props => props.theme.blue};
        border-bottom: 2px solid ${props => props.theme.blue};
        display: inline-block;
        margin-bottom: 15px;
        text-align: center;
    }

    .form-container {
        // width: 250px;
        max-width: 400px;
        margin: 0 auto;

        p {
            padding-bottom: 10px;
        }
    }

    .delete-account {
        margin-bottom: 15px;
        margin-top: 5px;
        button {
            text-transform: none;
            font-size: 16px;
            text-decoration: underline;
            // text-align: center;
            color: ${props => props.theme.darkgrey};
            cursor: pointer;
            padding: 0;
            display: block;
        }
    }
`

class AccountSettings extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            givenName: '',
            familyName: '',
            company: '',
            email: '',
            password: '',
            passwordStrength: null,
            feedbackMessage: null,
            dialogOpen: false,
        }
    }

    componentDidMount() {
        const { user } = this.context
        this.setUser(user)
    }

    setUser = user => {
        this.setState({
            givenName: user.givenName,
            familyName: user.familyName,
            email: user.email,
            company: user.company ? user.company.name : 'Company Unknown',
            deleted: user.status === 'deletion_requested',
        })
    }

    handleInputChange = e => {
        const { currentTarget } = e
        const { name, type } = currentTarget
        let { value } = currentTarget
        value = type === 'checkbox' ? currentTarget.checked : value
        this.setState({
            [name]: value,
            feedbackMessage: null,
        })
    }

    passwordCreated = passwordObject => {
        const { newPassword, confirmPassword, passwordStrength } = passwordObject
        this.setState({
            confirmPassword,
            newPassword,
            passwordStrength,
            feedbackMessage: null,
        })
    }

    saveChanges = async e => {
        e.preventDefault()
        const { newPassword, confirmPassword, passwordStrength } = this.state

        if (!newPassword) {
            this.setState({
                feedbackMessage: { type: 'warning', text: 'No changes have been made.' },
            })
            return
        }

        if (passwordStrength < MIN_PASSWORD_STRENGTH) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text:
                        'Please choose a password containing lower case and upper case letters, numbers and special characters.',
                },
            })
            return
        }

        if (newPassword && newPassword.length < MIN_PASSWORD_LENGTH) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: `Please choose a password at least ${MIN_PASSWORD_LENGTH} characters long.`,
                },
            })
            return
        }
        if (newPassword && newPassword !== confirmPassword) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'The passwords you enter must match.' },
            })
            return
        }
        const dialogType = 'update'
        this.setState({
            dialogOpen: true,
            dialogType,
        })
    }

    requestToDelete = () => {
        this.setState({
            feedbackMessage: null,
            dialogType: 'delete',
            dialogOpen: true,
        })
    }

    toggleDialog = () => {
        const { dialogOpen } = this.state
        this.setState({
            dialogOpen: !dialogOpen,
            feedbackMessage: null,
        })
    }

    updateAccount = async () => {
        // make request to update account
        const { newPassword, password, email } = this.state
        if (!newPassword) {
            this.setState({
                password: '',
                feedbackMessage: { type: 'warning', text: 'You have not made any changes' },
            })
            return
        }
        if (!password) {
            this.setState({
                password: '',
                feedbackMessage: {
                    type: 'error',
                    text: 'Please enter a password to complete your request.',
                },
            })
            return
        }

        const passwordsAndEmail = {
            email,
            newPassword,
            currentPassword: password,
        }
        const res = await updatePassword(passwordsAndEmail)
        if (res.status === 200) {
            this.setState(
                {
                    password: '',
                    feedbackMessage: {
                        type: 'success',
                        text: 'Your account has been successfully updated',
                    },
                    resetCreatePassword: true,
                },
                () => {
                    this.setState({
                        resetCreatePassword: false,
                    })
                }
            )
        } else {
            this.setState({
                password: '',
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error with your request. Please try again later.',
                },
            })
        }
    }

    deleteAccount = async () => {
        // make request to update account
        const { password } = this.state

        if (!password) {
            this.setState({
                password: '',
                feedbackMessage: {
                    type: 'error',
                    text: 'Please enter a password to complete your request.',
                },
            })
            return
        }

        const { user } = this.context
        const { userId } = user

        const res = await deleteAccount({ password })
        if (res && res.status === 200) {
            // get the data from the user and push it up to the router to modify user across all routes
            const { getProfile } = this.context
            await getProfile()
            this.setState({
                password: '',
                feedbackMessage: {
                    type: 'success',
                    text:
                        'Thank you for using the Digital Acceleration Index. You request to delete your account is being processed.',
                },
                deleted: true,
            })
        } else {
            this.setState({
                password: '',
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error with your request. Please try again later.',
                },
            })
        }
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const {
            givenName,
            familyName,
            company,
            email,
            password,
            feedbackMessage,
            dialogOpen,
            dialogType,
            resetCreatePassword,
            deleted,
        } = this.state

        // the dialog view decides which dialog contents to present when updating or deleting an account
        // it also needs to account for results of the requests to update or delete the account

        return (
            <Page {...this.props} appbar background>
                <CopyConsumer>
                    {({ copy }) => {
                        return (
                            <React.Fragment>
                                <StyledPage>
                                    <br />
                                    <form className="form-container" onSubmit={this.submit}>
                                        <div className="section-title">
                                            {copy.accountSettings.bodyText.accountSettingsTitle}
                                        </div>
                                        {givenName && (
                                            <SimpleInput
                                                handleInputChange={this.handleInputChange}
                                                value={`${givenName} ${familyName}`}
                                                name="givenName"
                                                placeholder="Given Name"
                                                locked
                                            >
                                                <FontAwesomeIcon icon={faUser} />
                                            </SimpleInput>
                                        )}
                                        <SimpleInput
                                            handleInputChange={this.handleInputChange}
                                            value={company}
                                            name="company"
                                            placeholder="Company"
                                            locked
                                        >
                                            <FontAwesomeIcon icon={faBuilding} />
                                        </SimpleInput>
                                        <SimpleInput
                                            handleInputChange={this.handleInputChange}
                                            value={email}
                                            name="email"
                                            placeholder="Email"
                                            locked
                                        >
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </SimpleInput>
                                        <p>{copy.accountSettings.bodyText.setANewPassword}</p>
                                        <CreatePassword
                                            returnPassword={this.passwordCreated}
                                            requiredLength={MIN_PASSWORD_LENGTH}
                                            reset={resetCreatePassword}
                                        />
                                        {!dialogOpen && (
                                            <React.Fragment>
                                                {feedbackMessage && (
                                                    <div className="feedback">
                                                        <FeedbackBarContentWrapper
                                                            message={feedbackMessage.text}
                                                            variant={feedbackMessage.type}
                                                            onClose={this.closeFeedbackMessage}
                                                        />
                                                        <br />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                        <StyledButton
                                            type="submit"
                                            onClick={this.saveChanges}
                                            customClass="narrow medium"
                                        >
                                            Save Changes
                                        </StyledButton>
                                        <br />
                                        <div className="delete-account">
                                            {deleted ? (
                                                <div>
                                                    {
                                                        copy.accountSettings.info
                                                            .deleteRequestFeedback
                                                    }
                                                    <a href="mailto:DAI@bcg.com">DAI@bcg.com</a>
                                                </div>
                                            ) : (
                                                <ButtonBase onClick={this.requestToDelete}>
                                                    {
                                                        copy.accountSettings.buttonsText
                                                            .requestToDelete
                                                    }
                                                </ButtonBase>
                                            )}
                                        </div>
                                    </form>
                                </StyledPage>

                                {/* this dialog handles
                                password confirmation
                                success and error messages from update and delete account */}

                                <SimpleDialog
                                    dialogOpen={dialogOpen}
                                    closeDialog={this.toggleDialog}
                                >
                                    {dialogOpen ? (
                                        <div>
                                            {!feedbackMessage && (
                                                <div>
                                                    <div className="dialog-icon-caution">
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </div>
                                                    <p>
                                                        {dialogType === 'update'
                                                            ? copy.accountSettings.info
                                                                  .passwordPromptToCompleteUpdateRequest
                                                            : copy.accountSettings.info
                                                                  .passwordPromptToCompleteDeleteRequest}
                                                    </p>
                                                </div>
                                            )}
                                            {feedbackMessage && feedbackMessage.type === 'error' && (
                                                <div>
                                                    <div className="dialog-icon-caution">
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </div>
                                                    <div className="feedback">
                                                        <FeedbackBarContentWrapper
                                                            message={feedbackMessage.text}
                                                            variant={feedbackMessage.type}
                                                            onClose={this.closeFeedbackMessage}
                                                        />
                                                        <br />
                                                    </div>
                                                </div>
                                            )}
                                            {feedbackMessage && feedbackMessage.type === 'success' && (
                                                <div>
                                                    <div className="dialog-icon-success">
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </div>
                                                    <p>
                                                        {dialogType === 'update'
                                                            ? ''
                                                            : feedbackMessage.text}
                                                    </p>
                                                </div>
                                            )}
                                            <br />
                                            {
                                                <React.Fragment>
                                                    {dialogType === 'update' ? (
                                                        <React.Fragment>
                                                            {feedbackMessage &&
                                                            feedbackMessage.type === 'success' ? (
                                                                <React.Fragment>
                                                                    <p>
                                                                        Your account has been
                                                                        updated successfully!
                                                                    </p>
                                                                    <StyledButton
                                                                        onClick={this.toggleDialog}
                                                                        customClass="narrow medium center"
                                                                    >
                                                                        Continue
                                                                    </StyledButton>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <form
                                                                        style={{
                                                                            borderBottom:
                                                                                '1px solid #ccc',
                                                                            maxWidth: '250px',
                                                                            margin: '0 auto',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type="hidden"
                                                                            name="username"
                                                                            autoComplete="off"
                                                                        />
                                                                        <PasswordInput
                                                                            handleInputChange={
                                                                                this
                                                                                    .handleInputChange
                                                                            }
                                                                            value={password}
                                                                        />
                                                                    </form>
                                                                    <br />
                                                                    <div
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            margin: '5px',
                                                                        }}
                                                                    >
                                                                        <StyledButton
                                                                            onClick={
                                                                                this.toggleDialog
                                                                            }
                                                                            customClass="narrow small"
                                                                        >
                                                                            Cancel
                                                                        </StyledButton>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            margin: '5px',
                                                                        }}
                                                                    >
                                                                        <StyledButton
                                                                            onClick={
                                                                                this.updateAccount
                                                                            }
                                                                            customClass="narrow small"
                                                                        >
                                                                            Submit
                                                                        </StyledButton>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    ) : (
                                                        // handle delete account
                                                        <React.Fragment>
                                                            {feedbackMessage &&
                                                            feedbackMessage.type === 'success' ? (
                                                                <p>
                                                                    Goodbye and thank you for using
                                                                    DAI!
                                                                </p>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <form
                                                                        style={{
                                                                            borderBottom:
                                                                                '1px solid #ccc',
                                                                            maxWidth: '250px',
                                                                            margin: '0 auto',
                                                                        }}
                                                                    >
                                                                        <PasswordInput
                                                                            handleInputChange={
                                                                                this
                                                                                    .handleInputChange
                                                                            }
                                                                            value={password}
                                                                        />
                                                                    </form>
                                                                    <br />
                                                                    <div
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            margin: '5px',
                                                                        }}
                                                                    >
                                                                        <StyledButton
                                                                            onClick={
                                                                                this.toggleDialog
                                                                            }
                                                                            customClass="narrow small"
                                                                        >
                                                                            Cancel
                                                                        </StyledButton>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            margin: '5px',
                                                                        }}
                                                                    >
                                                                        <StyledButton
                                                                            onClick={
                                                                                this.deleteAccount
                                                                            }
                                                                            customClass="narrow small caution"
                                                                        >
                                                                            Delete
                                                                        </StyledButton>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </div>
                                    ) : (
                                        <React.Fragment />
                                    )}
                                </SimpleDialog>
                                {/* end of dialog */}
                            </React.Fragment>
                        )
                    }}
                </CopyConsumer>
            </Page>
        )
    }
}

export default AccountSettings
