import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { clone } from '../../utils'
import Stars from '../ImportanceStars'
import GoalFeedback from './GoalFeedback'

const StyledContainer = styled.div`
    border-radius: 3px;
    box-shadow: 0px 0px 4px ${props => props.theme.black};
    padding: 10px;

    .divider {
        height: 2px;
        width: 100%;
        background-color: white;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .score-circle {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 2px solid white;
        display: inline-block;
        color: white;
        font-size: 18px;
        line-height: 35px;
        text-align: center;
        margin-bottom: 10px;
    }

    .score-description {
        display: inline-block;
        text-transform: uppercase;
        color: white;
        font-size: 24px;
        margin-left: 10px;
        line-height: 40px;
    }
`

const GoalDetails = props => {
    const { block } = props
    const sortedQuestions = clone(block.questions).sort((a, b) => b.importance - a.importance)
    const score = block.preresults.userScore >= 0 ? block.preresults.userScore : 'N/A'
    let ambitionGap = 'N/A'
    let ambitionGapDescription = 'Ambition gap'
    if (typeof score === 'number') {
        ambitionGap = Math.abs(Math.ceil(block.ambition.ambitionScore - score))
        ambitionGapDescription =
            block.ambition.ambitionScore - score > 0 ? 'behind goal' : 'ahead of goal'
    }
    return (
        <StyledContainer style={{ backgroundColor: block.properties.color }}>
            <Grid container spacing={0}>
                {sortedQuestions.map(q => (
                    <Grid item xs={12} key={q.questionId}>
                        <GoalDetailsRow question={q} block={block} />
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <div className="divider" />
                </Grid>
                <Grid item xs={12}>
                    <div className="score-circle">{score}</div>
                    <div className="score-description">your score</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="score-circle">{ambitionGap}</div>
                    <div className="score-description">{ambitionGapDescription}</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="score-circle">
                        {block.benchmark && score >= 0
                            ? Math.abs(block.benchmark.benchmarkScore - score)
                            : 'N/A'}
                    </div>
                    <div className="score-description">
                        {block.benchmark && score >= 0
                            ? block.benchmark.benchmarkScore - score > 0
                                ? 'Below benchmark'
                                : 'Over benchmark'
                            : 'Benchmark gap'}
                    </div>
                </Grid>
                <GoalFeedback block={block} />
            </Grid>
        </StyledContainer>
    )
}

GoalDetails.defaultProps = {
    block: {},
}

GoalDetails.propTypes = {
    block: PropTypes.object,
}

export default GoalDetails

const StyledRow = styled.div`
    padding: 5px;
    color: ${props => props.theme.white};
    font-weight: bold;

    .dimension-title {
        font-size: 16px;
    }
    .bar-wrapper-ambition {
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;

        .bar-ambition {
            width: 100%;
            height: 16px;
            background: ${props => props.theme.white};

            .ambition-box {
                display: inline-block;
                vertical-align: top;
                width: 25%;
                height: 100%;
                border: 1px solid white;
                height: 16px;
                background: -webkit-linear-gradient(50deg,rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139), rgb(255, 255, 255),rgb(139, 139, 139));
                background: linear-gradient(50deg,rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139), rgb(255, 255, 255), rgb(139, 139, 139));
            }
        }
    }
    .bar-wrapper {

        .bar {
            width: 100%;
            /* background: ${props => props.theme.white}; */

            .bar-box {
                display: inline-block;
                vertical-align: top;
                width: 25%;
                height: 100%;
                border: 1px solid white;
            }
            .skipped {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                text-align: center;
                font-size: 0.9rem;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }
`

const GoalDetailsRow = props => {
    const { question } = props
    return (
        <StyledRow>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={8} sm={10} className="dimension-title">
                    {question.title}
                </Grid>
                <Grid item xs={4} sm={2} style={{ position: 'relative' }}>
                    <div className="bar-wrapper">
                        <div className="bar">
                            <Stars fill={question.importance} readOnly starSize={16} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </StyledRow>
    )
}

GoalDetailsRow.defaultProps = {
    question: {},
    block: {},
}

GoalDetailsRow.propTypes = {
    question: PropTypes.object,
    block: PropTypes.object,
}
