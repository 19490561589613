const getLocalStorage = item => {
    return window.localStorage.getItem(item)
}

const setLocalStorage = (item, data) => {
    window.localStorage.setItem(item, data)
}

const clearLocalStorage = () => {
    window.localStorage.clear()
}

const removeLocalStorageItem = key => {
    window.localStorage.removeItem(key)
}

export { getLocalStorage, setLocalStorage, clearLocalStorage, removeLocalStorageItem }
