import { createGlobalStyle } from 'styled-components'
import bcgFontBold from '../../assets/fonts/BCGHenSansBold.ttf'
import bcgFontBoldIta from '../../assets/fonts/BCGHenSansBoldIta.ttf'
import bcgFontBoldRegular from '../../assets/fonts/BCGHenSansRegular.ttf'
import bcgFontLight from '../../assets/fonts/BCGHenSansLight.ttf'

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'bcg';
    src: url(${bcgFontBold}) format('truetype');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'bcg';
    src: url(${bcgFontBoldIta}) format('truetype');
    font-style: italic, oblique;
    font-weight: bold;
  }

  @font-face {
    font-family: 'bcg';
    src: url(${bcgFontBoldRegular}) format('truetype');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'bcg';
    src: url(${bcgFontLight}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  html {
    box-sizing: border-box;
    background-color: #fbf9f9;
    overflow-x: hidden;
    scroll-behavior: smooth;
    // height: 100%;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    width: 100%;
    padding: 0;
    margin: 0;
    font-family:'bcg', 'Helvetica Neue', sans-serif;
    font-size: 1.2rem;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
    scroll-behavior: smooth;
    height: 100%;
    overflow-x: hidden;
  }

  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  button, input, textarea {
    font-family: 'bcg', 'Helvetica Neue', sans-serif !important;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    border-radius: 0;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea,html input[type="button"],
  input[type="reset"],
  input[type="submit"],button[disabled],
  html input[disabled],button::-moz-focus-inner,
  input::-moz-focus-inner, input[type="checkbox"],
  input[type="radio"], input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button, input[type="search"], input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input {
    border-radius: 0;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
    font-size: 16px
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ccc;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #ccc;
  }

  div, button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
 
  .grecaptcha-badge {
    display: none;
  }

  @media only screen and (min-width: 1024px) {
    div, button {
      -webkit-touch-callout: inherit;
      -webkit-user-select: inherit;
      -khtml-user-select: inherit;
      -moz-user-select: inherit;
      -ms-user-select: noninherite;
      user-select: inherit;
      -webkit-tap-highlight-color: transparent;
    }
  }
`

export default GlobalStyles
