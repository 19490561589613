import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { getTintedColor } from '../../utils'

const StyledContainer = styled.div`
    border-radius: 3px;
    box-shadow: 0px 0px 4px ${props => props.theme.black};
    padding: 5px;
`

const BenchmarkDetails = props => {
    const { block } = props
    return (
        <StyledContainer style={{ backgroundColor: block.properties.color }}>
            <Grid container spacing={0}>
                {block.questions.map(q => (
                    <Grid item xs={12} key={q.questionId}>
                        <BenchmarkDetailsRow question={q} block={block} />
                    </Grid>
                ))}
            </Grid>
        </StyledContainer>
    )
}

BenchmarkDetails.defaultProps = {
    block: {},
}

BenchmarkDetails.propTypes = {
    block: PropTypes.object,
}

export default BenchmarkDetails

const StyledRow = styled.div`
    padding: 5px;
    color: ${props => props.theme.white};
    font-weight: bold;

    .title {
        text-align: center;
        margin-bottom: -10px;
        font-size: 16px;
        width: 100%;
    }

    .tablet-only {
        display: none;
    }

    @media only screen and (min-width: 1024px) {
        .mobile-only {
            display: none;
        }
        .tablet-only {
            display: inherit;
        }
    }
`

const BenchmarkDetailsRow = props => {
    const { question, block } = props
    return (
        <StyledRow>
            <Grid container spacing={16}>
                <Grid item xs={12} className="mobile-only">
                    <div className="title">{question.title}</div>
                </Grid>
                <Grid item xs={6} md={4}>
                    <BenchmarkBar
                        left
                        fill={question.answer.weight}
                        color={block.properties.color}
                    />
                </Grid>
                <Grid item md={4} className="tablet-only">
                    <div className="title">{question.title}</div>
                </Grid>
                <Grid item xs={6} md={4}>
                    <BenchmarkBar fill={question.benchmark} color={block.properties.color} />
                </Grid>
            </Grid>
        </StyledRow>
    )
}

BenchmarkDetailsRow.defaultProps = {
    question: {},
    block: {},
}

BenchmarkDetailsRow.propTypes = {
    question: PropTypes.object,
    block: PropTypes.object,
}

const StyledBar = styled.div`
    margin-bottom: 20px;

    .bar-title {
        margin-bottom: 2px;
        font-size: 1rem;
    }

    .bar-wrapper-outer {
        width: calc(100% - 15px);
        display: inline-block;
        vertical-align: top;
        max-width: 120px;

        .bar-wrapper-inner {
            width: 100%;
            height: 16px;
            background: ${props => props.theme.white};
            position: relative;
            // border: 1px solid ${props => props.theme.white};
            padding: 2px;

            .bar-overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 0;
                border: 1px solid white;
                -webkit-transition: width 1.5s ease-in-out;
                -moz-transition: width 1.5s ease-in-out;
                -o-transition: width 1.5s ease-in-out;
                transition: width 1.5s ease-in-out;
                transition-delay: 1s;
            }

            .left {
                right: 0;
            }
            .right {
                left: 0;
            }
            .skipped {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                text-align: center;
                font-size: 0.9rem;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .bar-wrapper-outer {
            max-width: 300px;
        }
    }

`

const BenchmarkBar = props => {
    const { fill, color, left } = props
    const directionOverlay = left ? 'left' : 'right'
    const darkerColor = getTintedColor(color, -20)
    const weightOfNA = 1
    const isNumber = typeof fill === 'number' && fill !== weightOfNA
    return (
        <StyledBar>
            <div className="bar-wrapper-outer" style={{ float: left ? 'right' : 'left' }}>
                <div
                    className="bar-wrapper-inner"
                    style={{ backgroundColor: !isNumber ? '#ccc' : 'white' }}
                >
                    {!isNumber && <div style={{ fontSize: '14px', textAlign: 'center' }}>N/A</div>}
                    {isNumber && (
                        <div
                            className={`bar-overlay ${directionOverlay}`}
                            style={{ width: `${fill}%`, background: darkerColor }}
                        />
                    )}
                </div>
            </div>
        </StyledBar>
    )
}

BenchmarkBar.defaultProps = {
    fill: 0,
    color: '',
    left: false,
}

BenchmarkBar.propTypes = {
    fill: PropTypes.number,
    color: PropTypes.string,
    left: PropTypes.bool,
}
