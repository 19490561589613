import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faBuilding, faCheck } from '@fortawesome/pro-light-svg-icons'
import { routePaths, validateEmailFormat, RECAPTCHA_THRESHOLD } from '../../utils'
import { StyledButton, StyledSpinner } from '../Styles'
import SimpleDialog from '../SimpleDialog'
import { Attribution } from '../GoogleRecaptcha'
import { requestAccount } from '../../api'
import { FeedbackBarContentWrapper } from '../Feedback'
import { HeaderLogo, DropdownSelect, SimpleInput, TermsCheck } from '../FormElements'
// this should move to the backend
const ROLE_LIST = [
    { label: 'CEO (Chief Executive Officer)', value: 'ceo' },
    { label: 'COO (Chief Operating Officer)', value: 'coo' },

    { label: 'CFO (Chief Financial Officer)', value: 'cfo' },
    { label: 'CIO (Chief Information Officer)', value: 'cio' },
    { label: 'CDO (Chief Digital Officer)', value: 'cdo' },
    { label: 'Director/Executive vice president', value: 'director/vp' },
    { label: 'Head of Business Unit or Function', value: 'hbf' },
    { label: 'CEO/Head of Service Function (Business & IT Services Entity)', value: 'ceo/hsf' },
    { label: 'General manager', value: 'gm' },
    { label: 'Other', value: 'other' },
]

const StyledContainer = styled.div`
    position: relative;
    height: 100%;

    .form-container {
        max-width: 300px;
        margin: 0 auto;
        padding: 10px;

        .forgot-password {
            max-width: 250px;
            margin: 0 auto;

            button {
                text-transform: none;
                font-size: 16px;
                text-decoration: underline;
                text-align: right;
                color: ${props => props.theme.darkgrey};
                cursor: pointer;
                float: right;
                padding: 0;
            }
        }

        .submit {
            padding: 10px;

            button {
                max-width: 180px;
                height: 35px;
                line-height: 5px;
                text-transform: none;
                box-shadow: none;
                margin: 0 auto;
            }
        }
    }

    .switch-views {
        max-width: 250px;
        margin: 10px auto;

        button {
            text-transform: none;
            font-size: 16px;
            text-decoration: underline;
            text-align: center;
            color: ${props => props.theme.darkgrey};
            cursor: pointer;
            padding: 0;
            display: block;
            margin: 0 auto;
        }
    }

    .feedback {
        margin: 0 auto;
        // max-width: 350px;
    }

    .recaptcha-attribution {
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
    }

    @media only screen and (min-width: 768px) {
        .form-container {
            max-width: 350px;
        }
    }
`

class RequestForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            passwordInputType: 'password',
            role: null,
            dialogOpen: false,
            terms: false,
            feedbackMessage: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        const { captchaScore } = nextProps
        // console.log('captcha score', captchaScore);
        if (captchaScore < RECAPTCHA_THRESHOLD) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text:
                        'We have detected suspicious activity from this web address. Please refresh and try again or check your network setup.',
                },
            })
        } else {
            // fetch possible user roles
        }
    }

    handleInputChange = e => {
        const { target } = e
        const { name, type } = target
        let { value } = target
        value = type === 'checkbox' ? target.checked : value
        this.setState({
            [name]: value,
            feedbackMessage: null,
        })
    }

    showPassword = () => {
        const { passwordInputType } = this.state
        const inputType = passwordInputType === 'password' ? 'text' : 'password'
        this.setState({
            passwordInputType: inputType,
        })
    }

    requestAccount = async e => {
        e.preventDefault()
        const { firstName, lastName, company, email, role, terms } = this.state
        const { captchaScore } = this.props

        if (captchaScore < RECAPTCHA_THRESHOLD) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text:
                        'We have detected suspicious activity from this web address. Please refresh and try again or check your network setup.',
                },
            })
            return
        }
        if (firstName.length < 1) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'Please enter your first name' },
            })
            return
        }
        if (lastName.length < 1) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'Please enter your last name' },
            })
            return
        }
        if (company.length < 1) {
            this.setState({ feedbackMessage: { type: 'error', text: 'Please enter your company' } })
            return
        }
        if (!validateEmailFormat(email)) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'Please enter a valid email' },
            })
            return
        }
        if (!role) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'Please select or describe your role' },
            })
            return
        }
        if (terms < 1) {
            this.setState({ feedbackMessage: { type: 'error', text: 'Please check the terms' } })
            return
        }

        const user = {
            company: {
                name: company,
                role: role.label,
            },
            email,
            familyName: lastName,
            givenName: firstName,
        }
        this.setState({
            loading: true,
        })
        const res = await requestAccount(user)
        if (res.status === 201) {
            this.setState({
                loading: false,
                dialogOpen: true,
                firstName: '',
                lastName: '',
                company: '',
                email: '',
                terms: false,
            })
        } else if (res.status === 200) {
            this.setState({
                loading: false,
                feedbackMessage: { type: 'warning', text: 'An account was already requested.' },
            })
        } else {
            this.setState({
                loading: false,
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error processing your request, please try again.',
                },
            })
        }
    }

    toggleDialog = () => {
        const { dialogOpen } = this.state
        this.setState({
            dialogOpen: !dialogOpen,
        })
    }

    toggleTerms = () => {
        const { terms } = this.state
        this.setState({
            terms: !terms,
            feedbackMessage: null,
        })
    }

    dialogAction = () => {
        this.setState({
            dialogOpen: false,
        })
        const { nav } = this.props
        nav(routePaths.about)
    }

    dropdownSelect = selectedValue => {
        this.setState({
            role: selectedValue,
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const {
            firstName,
            lastName,
            company,
            email,
            terms,
            feedbackMessage,
            dialogOpen,
            loading,
        } = this.state

        const { backToLogin, captchaScore } = this.props
        if (loading) {
            return (
                <div>
                    <div style={{ height: '100px' }} />
                    <HeaderLogo />
                    <div style={{ width: '50px', margin: '0 auto', textAlign: 'center' }}>
                        <StyledSpinner />
                    </div>
                    <br />
                    <div style={{ textAlign: 'center' }}>Requesting account</div>
                </div>
            )
        }
        return (
            <StyledContainer>
                {/* <HeaderLogo /> */}
                <form className="form-container" onSubmit={this.signin}>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={firstName}
                        name="firstName"
                        placeholder="First Name"
                    >
                        <FontAwesomeIcon icon={faUser} />
                    </SimpleInput>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={lastName}
                        name="lastName"
                        placeholder="Last Name"
                    >
                        <FontAwesomeIcon icon={faUser} />
                    </SimpleInput>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={company}
                        name="company"
                        placeholder="Company-Name"
                    >
                        <FontAwesomeIcon icon={faBuilding} />
                    </SimpleInput>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={email}
                        name="email"
                        placeholder="Email"
                        type="email"
                    >
                        <FontAwesomeIcon icon={faEnvelope} />
                    </SimpleInput>
                    <DropdownSelect
                        list={ROLE_LIST}
                        selected={this.dropdownSelect}
                        placeholder="Select your role"
                    />
                    <TermsCheck toggleTerms={this.toggleTerms} checked={terms} />
                    <br />
                    {feedbackMessage ? (
                        <div className="feedback">
                            <FeedbackBarContentWrapper
                                message={feedbackMessage.text}
                                variant={feedbackMessage.type}
                                onClose={this.closeFeedbackMessage}
                            />
                            <br />
                        </div>
                    ) : (
                        <React.Fragment />
                    )}
                    <StyledButton
                        disabled={captchaScore < RECAPTCHA_THRESHOLD}
                        type="submit"
                        onClick={this.requestAccount}
                        customClass={`narrow medium center ${
                            captchaScore < RECAPTCHA_THRESHOLD ? 'disabled' : ''
                        }`}
                    >
                        Request Account
                    </StyledButton>
                    <div className="switch-views">
                        <Button onClick={backToLogin}>Back to Login</Button>
                    </div>
                </form>
                <div className="recaptcha-attribution">
                    <Attribution />
                </div>
                <SimpleDialog dialogOpen={dialogOpen} closeDialog={this.toggleDialog}>
                    <div>
                        <div className="dialog-icon-success">
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <p>Thank you for requesting an account!</p>
                        <p>A confirmation with more details will be sent to your e-mail address.</p>
                        <p>Read more about DAI</p>
                        <StyledButton
                            customClass="medium narrow center"
                            onClick={this.dialogAction}
                        >
                            About DAI
                        </StyledButton>
                        <br />
                    </div>
                </SimpleDialog>
            </StyledContainer>
        )
    }
}

RequestForm.defaultProps = {
    nav: () => {},
    backToLogin: () => {},
    captchaScore: 1,
}

RequestForm.propTypes = {
    nav: PropTypes.func,
    backToLogin: PropTypes.func,
    captchaScore: PropTypes.number,
}

export default RequestForm
