import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSelect = styled.div`
    input {
        padding: 1rem;
        margin: 1rem 0 1rem 0;
        display: inline-block;
        vertical-align: top;
        height: 50px;
        width: 80%;
        font-size: 1.1rem;
        border: none;
        border-bottom: 1px solid ${props => props.theme.darkblue};
        background-color: transparent;
        -webkit-appearance: none;
    }

    .add {
        display: inline-block;
        vertical-align: top;
        width: 20% !important;
        padding: 1.2rem 0 1rem 0;
        margin: 1rem 0 1rem 0;
        border-radius: 3px;
        background-color: ${props => props.theme.darkblue};
        color: ${props => props.theme.white};
        cursor: pointer;
        &:hover {
            background-color: ${props => props.theme.darkgrey};
        }
    }
`

class SelectOther extends Component {
    constructor(props) {
        super(props)
        this.state = {
            other: '',
        }
    }

    selected = e => {
        const { existing, selected } = this.props
        const { other } = this.state
        e.preventDefault()
        existing.push({ value: other, label: other.toLowerCase() })
        selected(existing)
        this.setState({
            other: '',
        })
    }

    handleInputChange = e => {
        e.preventDefault()
        const { name } = e.currentTarget
        const { value } = e.currentTarget
        this.setState({
            [name]: value,
        })
    }

    render() {
        const { other } = this.state
        return (
            <StyledSelect>
                <form onSubmit={this.selected}>
                    <input
                        type="text"
                        placeholder="Describe other"
                        value={other}
                        name="other"
                        onChange={this.handleInputChange}
                    />
                    <button type="submit" className="add" onClick={this.selected}>
                        Add
                    </button>
                </form>
            </StyledSelect>
        )
    }
}

SelectOther.defaultProps = {
    selected: () => {},
    existing: null,
}

SelectOther.propTypes = {
    selected: PropTypes.func,
    existing: PropTypes.array,
}

export default SelectOther
