/* eslint-disable */

const whichBrowser = () => {
    const browserOptions = {}
    // Opera 8.0+
    browserOptions.opera =
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(' OPR/') >= 0

    // Firefox 1.0+
    browserOptions.firefox = typeof InstallTrigger !== 'undefined'

    // Safari 3.0+ "[object HTMLElementConstructor]"
    browserOptions.safari =
        /constructor/i.test(window.HTMLElement) ||
        (function(p) {
            return p.toString() === '[object SafariRemoteNotification]'
        })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))

    // Internet Explorer 6-11
    browserOptions.ie = /*@cc_on!@*/ false || !!document.documentMode

    // Edge 20+
    browserOptions.edge = !browserOptions.ie && !!window.StyleMedia

    // Chrome 1+
    browserOptions.chrome = !!window.chrome && !!window.chrome.webstore

    // Blink engine detection
    // browserOptions.blink = (isChrome || isOpera) && !!window.CSS;

    let browser = null
    for (let p in browserOptions) {
        if (browserOptions.hasOwnProperty(p)) {
            if (browserOptions[p]) {
                browser = p
            }
        }
    }
    return browser
}

const getBrowser = () => {
    var ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return { name: 'IE', version: tem[1] || '' }
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) {
            return { name: 'Opera', version: tem[1] }
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1])
    }
    return {
        name: M[0],
        version: M[1],
    }
}

export { whichBrowser, getBrowser }
