import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Block from './Elements/Block'
import BlockCompleted from './Elements/BlockCompleted'
import PreResultsContainer from './PreResults/PreResultsContainer'
import { scrollTo } from '../../utils'

const StyledContainer = styled.div`
    position: relative;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;

    .grid-container {
        width: 100%;
        margin: 0 auto;
        max-width: 800px;
    }

    .block-padding {
        padding: 0 10px 0px 10px;
        margin-bottom: -15px;
    }

    @media only screen and (min-width: 768px) {
        overflow-x: initial;
    }

    @media only screen and (min-width: 1050px) {
        .grid-container {
            max-width: 900px;
        }
    }
`

class BlocksList extends Component {
    componentDidMount() {
        scrollTo(0, 0)
    }

    render() {
        const {
            profile,
            blocks,
            select,
            currentBlock,
            updateCurrentBlock,
            goToResults,
        } = this.props
        return (
            <StyledContainer>
                <div className="grid-container">
                    <Grid container spacing={0}>
                        <Grid key="preresults" item xs={12}>
                            <div style={{ padding: '0px 10px 0px 10px' }}>
                                <PreResultsContainer
                                    profile={profile}
                                    blocks={blocks}
                                    currentBlock={currentBlock}
                                    updateCurrentBlock={updateCurrentBlock}
                                    select={select}
                                    goToResults={goToResults}
                                />
                            </div>
                        </Grid>

                        {blocks.map(b => (
                            <Grid key={b.blockId} item xs={12} sm={3} md={3} lg={3}>
                                <div className="block-padding">
                                    {b.preresults ? (
                                        <BlockCompleted block={b} select={select} />
                                    ) : (
                                        <Block block={b} select={select} time listblock />
                                    )}
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    <div style={{ height: '100px' }} />
                </div>
            </StyledContainer>
        )
    }
}

BlocksList.defaultProps = {
    profile: {},
    blocks: [],
    select: () => {},
    currentBlock: {},
    updateCurrentBlock: () => {},
    goToResults: () => {},
}

BlocksList.propTypes = {
    profile: PropTypes.object,
    blocks: PropTypes.array,
    select: PropTypes.func,
    currentBlock: PropTypes.object,
    updateCurrentBlock: PropTypes.func,
    goToResults: PropTypes.func,
}

export default BlocksList
