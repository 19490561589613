import React from 'react'
import styled from 'styled-components'
import { StyledSpinner } from '../Styles'

const StyledContainer = styled.div`
    text-align: center;
    width: 100vw;
    height: 100vh;
    .loading-icon-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        color: ${props => props.theme.bcgGreen};
    }
`

const Loading = () => {
    return (
        <StyledContainer>
            <div className="loading-icon-wrapper">
                <StyledSpinner />
            </div>
        </StyledContainer>
    )
}

export default Loading
